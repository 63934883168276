import {getClientConfig} from 'ultra/configs/general';
import {get} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function getPrices(sport, days, clients, children, countries) {
  let search = `?`
  search += 'sport=' + JSON.stringify(sport);
  search += '&days=' + JSON.stringify(days);
  search += '&clients=' + JSON.stringify(clients);
  search += '&children=' + JSON.stringify(children);
  search += '&countries=' + JSON.stringify(countries);

  return get(`${api}/insurance/price` + search);
}
