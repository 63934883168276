import Insurance from "../../../../../../Insurance";

import BreadcrumbsWidget from '../../../../../Widgets/Breadcrumbs'
import TitleWidget from '../../../../../Widgets/Title';

import './index.scss';

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props

    return <div className="Insurance">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>
        <Insurance />
    </div>
}
