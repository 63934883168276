import {TimestempInPast} from 'ultra/helpers/date';

import Chip from '@mui/material/Chip';

const BOOK_STATUS = {
    reading: "На прочитані",
    // given: "Передано почитати",
    return: "Заявка на поверненя",
    // Заброньовані
    overdue: "Перевищено строки",
    deposit: "Внести завдаток",
    // take: "Віддати депозит",
}

const BOOK_STATUS_COLORS = {
    reading: {
        // background: "#fff",
        background: "#fff",
        borderColor: "#ccc",
        color: "#333",
    },
    // given: "Передано почитати",
    return: {
        // background: "#f4f8ff",
        background: "#0060a1",
        borderColor: "#0060a1",
        color: "#fff",
    },
    // Заброньовані
    overdue: {
        // background: "#ecfff5",
        background: "rgb(235 41 41)",
        borderColor: "rgb(235 41 41)",
        color: "#fff",
    },
    deposit: {
        // background: "#fff0f0",
        background: "rgb(235 41 41)",
        borderColor: "rgb(235 41 41)",
        color: "#fff",
    },
    // take: "Віддати депозит",
}

export function getBookStatuscode(book) {
    let result;
    if (book?._reading_from && TimestempInPast(book?._reading_from)){
        result = 'reading'
    }

    if (book?._reading_request && TimestempInPast(book?._reading_request)){
        result = 'deposit'
    }

    if (book?._reading_till && TimestempInPast(book?._reading_till)){
        result = 'overdue'
    }

    if (book?._reading_finished && TimestempInPast(book?._reading_finished)){
        result = 'return'
    }

    return result;
}

export function getBookStatus(values) {
    const code = getBookStatuscode(values);

    return BOOK_STATUS[code]
}


export function getBookStatusLabel(values) {
    const status = getBookStatus(values);
    const code = getBookStatuscode(values);

    if (!status) return <></>

    return <Chip variant="outlined" label={status} style={BOOK_STATUS_COLORS[code]} />
}
