import {isMobile} from "react-device-detect";

import React, {useState} from 'react';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientDefaultImageUrl} from 'ultra/configs/general';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import {useConfigStore} from '../../../../Stores/config';

import {NODE_ACTIONS} from '../../Reducers/node';
import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import './index.scss';

function City(props) {
    const {id, city, onClick} = props;

    return <div className={`City ${city.inProgress ? 'inProgress' : ''}`} onClick={onClick} style={{backgroundImage: `url(${getClientDefaultImageUrl(window, `config/cities/${id}.jpg`)})`}}>
        <div className='Wrap'>
            <span className='city'>{city.city}</span>
            <span className='country'>{city.flag} {city.country}</span>
        </div>
        {city.inProgress && <span className='mode'>* в розробці</span>}
    </div>
}

export default function SelectCity(props) {
    const {config} = props;

    const {nodeDispatch, userDispatch} = useGlobalCustomContext();
    const {configs} = useConfigStore();

    const [open, setOpen] = useState(false);

    const showPopup = () => {
        setOpen(true)
    }

    const hidePopup = () => {
        setOpen(false)
    }

    if (!config || !configs) return <></>

    return config?.city && <>
        <span className='cityName' onClick={showPopup}>
            <span className='flag'>{config.flag}</span>
            <span className='city'>{config.city}</span>
            <KeyboardArrowDownOutlinedIcon />
        </span>
        <AdaptiveDialog open={open} onClose={hidePopup} title="Обрати місто">
            <div className='SelectCityDialog'>
                <div className='citiesWrap'>
                <div className='cities'>
                    {Object.keys(configs.content.cities).filter(city => !configs.content.cities[city].inProgress).map(city => city !== DEFAULT_CITY && <City key={city} onClick={hidePopup} id={city} city={configs.content.cities[city]} />)} 
                    {Object.keys(configs.content.cities).filter(city => configs.content.cities[city].inProgress).map(city => city !== DEFAULT_CITY && <City key={city} onClick={hidePopup} id={city} city={configs.content.cities[city]} />)}
                    <div className='City Add' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: configs.links.partner})}>
                        <div className='Wrap'>
                            <span className='city'>
                                <AddCircleOutlineOutlinedIcon />
                                <span>Додати ваше місто</span>
                            </span>
                        </div>
                    </div>
                </div>
                </div>

                <div className='Links'>
                    <span className='pseudoLink' onClick={() => {
                            nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: configs.links.support});
                            userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
                        }}>
                        <SupportAgentOutlinedIcon />
                        <span>Підтримки</span>
                    </span>
                    <span className='pseudoLink' onClick={() => {
                            nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: configs.links.rules});
                            userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
                        }}>
                        <ArticleOutlinedIcon />
                        <span>Правила</span>
                    </span>
                    {/* <span className='pseudoLink' onClick={() => nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: configs.links.partner})}>Стати партнером</span> */}
                </div>

                {isMobile && <div className='Details'>
                    <a className='detail' target='_blank' href='https://u24.gov.ua/'>
                        {/* <img className='logo' src={getClientDefaultImageUrl(window, `config/layout/made-in-ukraine.png`)} /> */}
                        <span>Made in Ukraine</span>
                    </a>
                    {/* <span className='detail'>
                        @3.14lab
                    </span> */}
                </div>}

            </div>
        </AdaptiveDialog>
    </>
}
