import {hasFullAccess} from 'ultra/helpers/auth';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';
import NodeDetails from '../../../Components/NodeDetails'

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);
    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        if (!values.details) {
            return <div className="NotificationTitle">
                <>Знято з публікації </>
            </div>
        }
        if (values.details) {
            return <div className="NotificationTitle">
                <>Ви зняли з публікації </>
                <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
            </div>
        }
    } 

    // if (!values.details) {
    // }
    if (values.details) {
        return <div className="NotificationTitle">
            <UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} />
            <> зняв з публікації </>
            <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
        </div>
    }
}
