import {isMobile} from "react-device-detect";

import React, {useEffect, useState} from 'react';
// import { NavLink as RouterLink } from "react-router-dom";
import {useLoaderData, useNavigation, useParams} from "react-router-dom";

// import {normalizeRoute, restorePath} from "ultra/helpers/route";
// import {getClientUrl} from 'ultra/configs/general'
// import { hasFullAccess } from "ultra/helpers/auth";

// import Grid from '@mui/material/Grid';
import {DEFAULT_CITY} from 'ultra/const/general';
import Chip from '@mui/material/Chip';
import {Button} from "@mui/material";
import {getClientCity} from 'ultra/configs/general';

import { setPageTitle } from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getShopOrderHistory} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';

import PreloaderTable from '../../../../../Components/PreloaderTable';

// import UserHistoryLink from '../../../../Content/Widgets/UserHistoryLink'

import TableView from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import OrderInlineActions from '../../../../Content/Widgets/OrderInlineActions';
// import DateValue from '../../../../Content/Widgets/DateValue';
// import UserHistoryLinkByID from '../../../../Content/Widgets/UserHistoryLinkByID';

// import NodeHistoryInlineActions from '../../../Components/NodeHistoryInlineActions';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
// import {useToasterStore} from '../../../../../Stores/toster';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import './index.scss';

export default () => {
    const initOrder = useLoaderData();

    let {partner, shop, order} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    // const {showError} = useToasterStore();
    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore(); // showBackdropPreloader
    const {userState} = useGlobalCustomContext();

    const [history, setHistory] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _status: {
            type: 'text',
            placeholder: 'Статус'
        },
        // _oid: {
        //     type: 'text',
        //     placeholder: 'Номер заявки'
        // },
        // _init_by: {
        //     type: 'history-user-link',
        //     placeholder: 'Замовник',
        // },
        // data_text: {
        //     type: 'request',
        //     placeholder: 'Заявка'
        // },
        // total: {
        //     type: 'shop-total',
        //     placeholder: "До сплати"
        // },
        _city: {
            type: 'city',
            placeholder: 'Місто'
        }
        // data: {
        //     type: 'notification',   // history-details
        //     placeholder: 'Дані'
        // }
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_timestamp'], // , 'total' // , '_oid'
            hidden: ['_init_by'], // 'data_text' , '_city'
            noHeaders: true
        } : {
            visible: ['_timestamp',  '_init_by'], // '_oid', 'total', // 'code',  , , '_init_by' , '_city'
            hidden: [], // , 'data_text'
            // noHeaders: true,
            // unfolded: true,
        })

        // updateOrder(initOrder);

        setPermits({})

        setPageTitle('Історія замовленя')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateOrder(initOrder);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    function updateOrder(history) {
        setHistory({...history})

        hideBackdropPreloader()
    }

    // const selectClasses = (data) => {
    //     return data?._code
    //         ? 'tr_' + data?._code.replace(':', '_')
    //         : '';
    // }

    const content = {
        // title: `${history?.partner?.title}`
        title: '#aOa43bmBwcnnLprdqbHW'
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\partnership\\'] = {title: "Партнерська панель"}
    breadcrumbs['\\profile\\history\\shop\\elements\\bali-elements'] = {title: "Назва партнерки"}

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateOrder({});
        getShopOrderHistory(partner, shop, order, url.search)
            .promise
            .then(updateOrder)
    }

    return <div id="Content" className='Content'>
            <div className='HistoryShopOrderAdminPage'>
                {history && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs} />}

                {isMobile && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>}

                {(!fields) && <PreloaderTable/>}

                {fields && history && <>
                        <div>
                            <Chip variant="outlined" label="Статус" />
                        </div>
                        <div>
                            <Button variant="outlined">Екшен адміна 1</Button>
                            <Button variant="outlined">Екшен адміна 2</Button>
                        </div>

                        <p>Історія замовлення</p>
                        <TableView
                            showPreloader={!history?.list}
                            content={history}
                            fields={fields}
                            showPagination={true}
                            tableOptions={tableOptions}
                            actions={(id) => OrderInlineActions({
                                content: history?.list?.[id],
                                city: getClientCity(window) || DEFAULT_CITY
                            })}
                            // selectClasses={selectClasses}
                        />
                    </>
                }

                {/* {history?.page && <NodeInlineActions permits={history?.permits} node={history?.page} hideHistory={true} showCleanHistory unfolded={!isMobile} className="CurrentPageInlineActions" city={city} hasFullAccess={hasFullAccess(userState.user)} />} */}
            </div>
        </div>
}
