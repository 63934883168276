import {isEmptyObj} from 'ultra/helpers/utils';

export default function UserReducer(state, action) {

    switch (action.type) {
        case ACTIONS.LOGIN:
            return {
                ...state,
                loginPopupOpen: true
            };

        case ACTIONS.LOGIN_CLOSE:
            return {
                ...state,
                loginPopupOpen: false
            };

        case ACTIONS.REGISTRAION:
            return {
                ...state,
                registrationPopupOpen: true
            };

        case ACTIONS.REGISTRAION_CLOSE:
            return {
                ...state,
                registrationPopupOpen: false
            };

        case ACTIONS.PROFILE_EDIT:
            return {
                ...state,
                profileEditPopupOpen: true
            };

        case ACTIONS.PROFILE_EDIT_CLOSE:
            return {
                ...state,
                profileEditPopupOpen: false
            };

        case ACTIONS.USER_LOGIN_IN_PROGRESS: {
            return {
                ...state,
                userLoginInProgress: true,
            };
        }

        // case ACTIONS.USER_LOGIN_FINISHED: {
        //     return {
        //         ...state,
        //         userLoginInProgress: false,
        //     };
        // }

        case ACTIONS.USER_UPDATE_IN_PROGRESS: {
            return {
                ...state,
                userUpdateInProgress: true,
            };   
        }

        case ACTIONS.USER_UPDATE: return {
            ...state,
            user: action.data.user,
            userLoginInProgress: false,
            userUpdateInProgress: false,
            userRefreshStarted: false,
        };

        case ACTIONS.USER_REMOVE: return {
            ...state,
            user: {},
            userLoginInProgress: false,
            userUpdateInProgress: false,
            userRefreshStarted: false,
        };

        case ACTIONS.USER_REFRESH_START: return {
            ...state,
            userRefreshStarted: true,
        }

        case ACTIONS.USER_REFRESH_STOP: return {
            ...state,
            userRefreshStarted: false,
        }

        case ACTIONS.SET_USER_BY_ID:
            if (isEmptyObj(action.data)) {
                return state
            }

            const ids = {...state.ids, ...action.data}

            return {
                ...state,
                ids                
            };

        case USER_ACTIONS.ADMIN.BLOCK: return {
            ...state,
            blockPopupOpen: action.data,
        }

        case USER_ACTIONS.ADMIN.BLOCK_FINISHED: return {
            ...state,
            blockPopupOpen: false
        }

        default:
            return state
    }
}

const ACTIONS = {
    LOGIN: 'user:login:open',
    LOGIN_CLOSE: 'user:login:close',

    REGISTRAION: 'user:registration:open',
    REGISTRAION_CLOSE: 'user:registration:close',

    PROFILE_EDIT: 'user:profile:edit:open',
    PROFILE_EDIT_CLOSE: 'user:profile:edit:close',

    SET_USER_BY_ID: 'user:set:by:id',

    USER_LOGIN_IN_PROGRESS: 'user:login:inprogress',
    // USER_LOGIN_FINISHED: 'user:login:finished',

    USER_UPDATE: 'user:update',
    USER_UPDATE_IN_PROGRESS: 'user:update:inprogress',

    USER_REMOVE: 'user:remove',

    USER_REFRESH_START: 'user:refresh:start',
    USER_REFRESH_STOP: 'user:refresh:stop',

    ADMIN: {
        BLOCK: 'user:admin:block',
        BLOCK_FINISHED: 'user:admin:block:finished',
    },
}

export const USER_ACTIONS = ACTIONS

export const createUserInitialState = () => {
    return {
        loginPopupOpen: false,
        userUpdateInProgress: false,
        userLoginInProgress: false,
        userRefreshStarted: false,
        blockPopupOpen: false,
        registrationPopupOpen: false,
        profileEditPopupOpen: false,
        user: undefined,
        ids: {}
    }
}
