import React from 'react';
import {getClientCity} from 'ultra/configs/general';

import Fab from '@mui/material/Fab';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {DEFAULT_CITY} from 'ultra/const/general';

import {useGlobalCustomContext} from '../../../../../../Helpers/context';
import {NODE_ACTIONS} from '../../../../Reducers/node';

export default function RemoveNodeButton(props) {
    const {disabled, uri} = props;
    const {nodeDispatch} = useGlobalCustomContext();

    const handleClick = () => {
        if (disabled) return;
        const city = getClientCity(window) || DEFAULT_CITY;
        nodeDispatch({type: NODE_ACTIONS.DELETE_START, data: {city, uri}});
    }

    return <>
        <Fab
            className='removeContentIcon'
            color="neutral"
            onClick={handleClick}>
                <DeleteOutlineIcon />
        </Fab>
    </>
}
