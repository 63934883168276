import React, {useEffect, useState} from 'react';

import {isUndefined} from 'lodash'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

export default function BooleanField(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;
    
    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable))}, [disabled])

    const [value, setValue] = useState(false)
    useEffect(() => {
        setValue(!isUndefined(content[field.id]) ? Boolean(content[field.id]) : false)
      }, [content[field.id]])

    return (<>
        <FormControl>
            <FormLabel>{field.placeholder}:</FormLabel>
            <RadioGroup
                row
                defaultValue={false}
                value={value}
                onChange={e => onChange(e)}
            >
                <FormControlLabel disabled={isDisabled} value={true} control={<Radio />} label="Так" />
                <FormControlLabel disabled={isDisabled} value={false} control={<Radio />} label="Ні" />
            </RadioGroup>
            {showErrorText && <FormHelperText error={showErrorText}>{showErrorText}</FormHelperText>}
        </FormControl>
        {/*  required={field.required} /> */}
    </>)
}
