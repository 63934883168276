import { create } from 'zustand'
import {removeEmptyFieldsFromObj} from 'ultra/helpers/utils'

const snackbarDuration = 10000

const toaster = {
  type: 'success',
  snackbarMessage: null,
  snackbarDuration,
}

const formatConfig = (options) => {
  return {
    toaster: {
      ...removeEmptyFieldsFromObj(toaster),
      ...removeEmptyFieldsFromObj(options)
    }
  }
}

export const useToasterStore = create((set) => ({
  toaster,
  showSuccess: (options) => set(() => {
    return formatConfig(options);
  }),
  showError: (options) => set(() => {
    if (!options.snackbarMessage) {
      options.snackbarMessage = 'Виникла помилка, спробуйте ще раз'
    }

    return formatConfig({
      ...options,
      type: 'error'
    });
  }),
  showPromt: (options) => set(() => {
    return formatConfig({
      ...options,
      type: 'error'
    });
  }),
  closeToaster: () => set(() => {
    return {toaster}
  })
}))
