import Chip from '@mui/material/Chip';
import {useState} from "react";

// import {Button} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import AdaptiveDialog from '../../../../../../Components/Adaptive/Dialog';
import {makeUrlsClickable} from '../../../../../../Helpers/format';

import './index.scss';

export default function RequestDetails(props) {
    const {details, values} = props;

    const [open, setOpen] = useState(false);

    if (!details) return <></>

    return <>
        {/* <Chip variant="outlined" label="Статус" /> */}
        {/* <span size="small" className="ActionButton" onClick={() => setOpen(!open)}><InfoOutlinedIcon /></span> */}
        <InfoOutlinedIcon onClick={() => setOpen(!open)}/>

        <AdaptiveDialog open={open} onClose={() => setOpen(!open)} title="Деталі заявки">
            <pre dangerouslySetInnerHTML={{ __html: makeUrlsClickable(details) }} className="DetailsBlockWrap"></pre>
            {/* <div className="OrderId">
                #{values.details._oid}
            </div> */}
            {/* <div>
                <Button size="small" variant="outlined" onClick={() => {}}>Прийняти</Button>
                <Button size="small" variant="outlined" onClick={() => {}}>Відмінити</Button>
            </div> */}
        </AdaptiveDialog>
    </>
}
