var isArray = require("lodash").isArray;
var isGalleryObject = require('./gallery').isGalleryObject;
var isEmptyMessengerData = require('./utils').isEmptyMessengerData;
var _a = require("lodash"), isUndefined = _a.isUndefined, isNull = _a.isNull;
var _b = require('./utils'), transliterate = _b.transliterate, isFile = _b.isFile;
var hasRole = require('./auth').hasRole;
var _c = require('./date'), getTime = _c.getTime, isTimestamp = _c.isTimestamp, timestampToDate = _c.timestampToDate;
var getClientCityTimezone = require('../configs/general').getClientCityTimezone;
export function getRootParent(parent, allNodeData) {
    var rootNode;
    var parentNodes = [];
    if (!parent)
        return;
    parent.map(function (node) {
        if (!allNodeData[node].parent) {
            rootNode = node;
        }
        else {
            parentNodes.push(node);
        }
    });
    if (rootNode)
        return rootNode;
    parentNodes.map(function (node) {
        rootNode = getRootParent(allNodeData[node].parent, allNodeData);
    });
    return rootNode;
}
export function getURI(parent, fields, nodeConfig, configs, city) {
    if (!parent)
        return '\\';
    var uri = parent;
    if (parent[parent.length - 1] !== '\\') {
        uri += '\\';
    }
    if (nodeConfig.uriStructure) {
        nodeConfig.uriStructure.map(function (field, i) {
            if (i > 0) {
                uri += '-';
            }
            if (!fields[field])
                return;
            if (nodeConfig.fields[field].type === 'timestamp') {
                var timeZone = getClientCityTimezone(configs, city);
                uri += transliterate(getTime(timestampToDate(fields[field]), timeZone).format('YYYY-MM-DD-HH-mm-ss'));
            }
            else if (nodeConfig.fields[field].type === 'tags') {
                uri += transliterate(fields[field].join('-'));
            }
            else {
                uri += transliterate(fields[field]);
            }
        });
    }
    else {
        uri += transliterate(fields.title);
    }
    return uri;
}
export function getFieldStatus(field, roles) {
    var _a, _b, _c, _d, _e, _f;
    var edit = 'hidden';
    if (!field)
        return {
            edit: edit,
        };
    if (!((_a = field === null || field === void 0 ? void 0 : field.access) === null || _a === void 0 ? void 0 : _a.edit)) {
        edit = 'editable';
    }
    else if (hasRole((_b = field.access) === null || _b === void 0 ? void 0 : _b.edit, roles)) {
        edit = 'editable';
    }
    if (((_c = field === null || field === void 0 ? void 0 : field.options) === null || _c === void 0 ? void 0 : _c.autoUpdatable) || ((_d = field === null || field === void 0 ? void 0 : field.options) === null || _d === void 0 ? void 0 : _d.notUpdatable)) {
        if (edit === 'editable') {
            edit = 'disabled';
        }
    }
    if (((_e = field === null || field === void 0 ? void 0 : field.options) === null || _e === void 0 ? void 0 : _e.showDisabledIfHasNoAccess) && !hasRole((_f = field.access) === null || _f === void 0 ? void 0 : _f.edit, roles)) {
        edit = 'disabled';
    }
    return { edit: edit };
}
export function validateRegExpFields(nodeConfig, fields) {
    var regexpFields = [];
    {
        Object.keys(nodeConfig).map(function (field) {
            var _a, _b;
            if (!nodeConfig[field])
                return;
            if ((_b = (_a = nodeConfig[field]) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.regexp) {
                regexpFields.push(field);
            }
        });
    }
    var errors = {};
    regexpFields.map(function (fieldId) {
        var regex = new RegExp(nodeConfig[fieldId].options.regexp);
        if (fields[fieldId] && !regex.test(fields[fieldId])) {
            errors[fieldId] = "\u041F\u043E\u043B\u0435 \u043C\u0456\u0441\u0442\u0438\u0442\u044C \u0437\u0430\u0431\u043E\u0440\u043E\u043D\u0435\u043D\u0456 \u0441\u0438\u043C\u0432\u043E\u043B\u0438";
            return true;
        }
    });
    return errors;
}
export function validateRequiredFields(nodeConfig, fields, isEditMode) {
    var requiredFields = [];
    var ignore = ['image', 'gallery'];
    {
        Object.keys(nodeConfig).map(function (field) {
            if (!nodeConfig[field])
                return;
            if (nodeConfig[field].required && !ignore.includes(nodeConfig[field].type)) {
                requiredFields.push(field);
            }
        });
    }
    var errors = {};
    requiredFields.map(function (fieldId) {
        var _a;
        var field = nodeConfig[fieldId];
        if (isEditMode && field) {
            if (field.type === 'password')
                return false;
            if (field.type === 'link')
                return false;
        }
        if (field && field.type === 'json' && fields[fieldId]) {
            var value = JSON.stringify(fields[fieldId]);
            var def = JSON.stringify(nodeConfig[fieldId].default);
            if (value === def) {
                errors[fieldId] = "\u041F\u043E\u0442\u0440\u0456\u0431\u043D\u043E \u0437\u043C\u0456\u043D\u0438\u0442\u0438 \u043F\u0440\u0438\u043A\u043B\u0430\u0434 \u0437\u0430 \u0437\u0430\u043C\u043E\u0432\u0447\u0435\u043D\u043D\u044F\u043C";
                return true;
            }
            if (isArray(fields[fieldId]) && fields[fieldId].length === 0) {
                errors[fieldId] = "\u041C\u0430\u0441\u0438\u0432 \u043C\u0430\u0454 \u0431\u0443\u0442\u0438 \u0437\u0430\u043F\u043E\u0432\u043D\u0435\u043D\u0438\u0439";
                return true;
            }
            if (!isArray(fields[fieldId]) && typeof fields[fieldId] === 'object' && Object.keys(fields[fieldId]).length === 0) {
                errors[fieldId] = "\u041E\u0431\u0454\u043A\u0442 \u043C\u0430\u0454 \u0431\u0443\u0442\u0438 \u0437\u0430\u043F\u043E\u0432\u043D\u0435\u043D\u0438\u0439";
                return true;
            }
        }
        if (field && field.type === 'messenger') {
            if (isEmptyMessengerData(fields[fieldId])) {
                errors[fieldId] = "\u041F\u043E\u043B\u0435 \u043C\u0430\u0454 \u0431\u0443\u0442\u0438 \u0437\u0430\u043F\u043E\u0432\u043D\u0435\u043D\u0438\u043C";
                return true;
            }
        }
        if (field && field.type === 'location' && (!((_a = fields[fieldId]) === null || _a === void 0 ? void 0 : _a.link))) {
            errors[fieldId] = "\u041F\u043E\u043B\u0435 \u043C\u0430\u0454 \u0431\u0443\u0442\u0438 \u0437\u0430\u043F\u043E\u0432\u043D\u0435\u043D\u0438\u043C";
            return true;
        }
        if (field && field.type === 'checkbox' && !fields[fieldId]) {
            errors[fieldId] = "\u041F\u043E\u043B\u0435 \u043C\u0430\u0454 \u0431\u0443\u0442\u0438 \u0432\u0456\u0434\u043C\u0456\u0447\u0435\u043D\u0438\u043C";
            return true;
        }
        if (field && field.type === 'number' && !fields[fieldId]) {
            errors[fieldId] = "\u041F\u043E\u043B\u0435 \u043C\u0430\u0454 \u0431\u0443\u0442\u0438 \u0437\u0430\u043F\u043E\u0432\u043D\u0435\u043D\u0438\u043C";
            return true;
        }
        if (field && field.type === 'password'
            && fields[fieldId]
            && fields[fieldId] !== fields["".concat(fieldId, "-dublicate")]) {
            errors[fieldId] = 'Паролі не співпадають';
            return true;
        }
        if (field && field.type === 'password'
            && fields[fieldId] === fields["".concat(fieldId, "-dublicate")]
            && fields[fieldId]
            && fields[fieldId].length < 6
            && fields[fieldId].length > 0) {
            errors[fieldId] = 'Довжина пароля має бути більше 6 символів';
            return true;
        }
        if (isNull(fields[fieldId]) || isUndefined(fields[fieldId]) || (!isNull(fields[fieldId]) && fields[fieldId].length === 0)) {
            errors[fieldId] = 'Поле обовязкове для заповнення';
            return true;
        }
    });
    return errors;
}
export function validateRequiredFiles(nodeConfig, files, fields) {
    var requiredFields = [];
    var accept = ['image'];
    {
        Object.keys(nodeConfig).map(function (field) {
            if (!nodeConfig[field])
                return;
            if (nodeConfig[field].required && accept.includes(nodeConfig[field].type)) {
                requiredFields.push(field);
            }
        });
    }
    var errors = {};
    requiredFields.map(function (fieldId) {
        var field = nodeConfig[fieldId];
        if (field && field.type === 'image') {
            if (isFile(files[fieldId])) {
                if (files[fieldId].size === 0) {
                    errors[fieldId] = 'Поле обовязкове до заповнення';
                    return true;
                }
            }
        }
        if (fields) {
            if (isUndefined(files[fieldId]) && !fields[fieldId]) {
                errors[fieldId] = 'Поле обовязкове для заповнення';
                return true;
            }
        }
        else {
            if (isUndefined(files[fieldId])) {
                errors[fieldId] = 'Поле обовязкове для заповнення';
                return true;
            }
        }
    });
    return errors;
}
export function validateRequiredGalleriesOnClient(nodeConfig, content) {
    var errors = {};
    Object.keys(nodeConfig).map(function (fieldId) {
        var field = nodeConfig[fieldId];
        if (field && field.required && field.type === 'gallery') {
            if (!content[fieldId]
                || (isGalleryObject(content[fieldId]) && Object.keys(content[fieldId]).length === 0)
                || (isArray(content[fieldId]) && content[fieldId].length === 0)) {
                errors[fieldId] = 'Галерея обовязкова до заповнення';
                return true;
            }
            if (isGalleryObject(content[fieldId]) && Object.keys(content[fieldId]).length > 0) {
                var allFilesAreNull_1 = true;
                Object.keys(content[fieldId]).map(function (file) {
                    if (isFile(content[fieldId][file])) {
                        if (content[fieldId][file].size > 0)
                            allFilesAreNull_1 = false;
                    }
                    else {
                        if (content[fieldId][file].length > 0)
                            allFilesAreNull_1 = false;
                    }
                });
                if (allFilesAreNull_1) {
                    errors[fieldId] = 'Галерея обовязкова до заповнення';
                    return true;
                }
            }
        }
    });
    return errors;
}
export function validateRequiredGalleriesOnServer(nodeConfig, files, fields) {
    var errors = {};
    Object.keys(nodeConfig).map(function (fieldId) {
        var _a;
        var field = nodeConfig[fieldId];
        if (field && field.required && field.type === 'gallery') {
            if (isArray(fields[fieldId]) && fields[fieldId].length === 0) {
                errors[fieldId] = 'Галерея обовязкова до заповнення';
                return true;
            }
            if (!isArray(fields[fieldId])) {
                var hasFilesToLeave = ((_a = fields[fieldId]) === null || _a === void 0 ? void 0 : _a.leaveThoseFiles.length) > 0;
                var hasFilesToUpload = files[fieldId]
                    && ((isArray(files[fieldId]) && files[fieldId].length > 0)
                        || (!isArray(files[fieldId]) && isFile(files[fieldId]) && files[fieldId].size > 0));
                var hasFiles = hasFilesToUpload || hasFilesToLeave;
                if (!hasFiles) {
                    errors[fieldId] = 'Галерея обовязкова до заповнення';
                    return true;
                }
            }
        }
    });
    return errors;
}
export function checkNodeAddRequirements(requirements, data) {
    var result = [];
    if (!requirements)
        return result;
    requirements.map(function (rule) {
        switch (rule) {
            case 'user.messenger':
                if (!data.user.messenger) {
                    result.push('Додати дані месенджеру на сторінці вашого профілю');
                }
                break;
        }
    });
    return result;
}
