import {useConfigStore} from '../../../../../../Stores/config';

import {getNodeTypeTitle} from '../../../../../../Helpers/content';

export default function DocumentTypeModified(props) {
    const {details} = props;
    const {configs} = useConfigStore();

    if (!details) return <></>

    if (!details.type) return <></>

    return <> {getNodeTypeTitle(configs?.content?.nodes[details.type]?.title)} </>
}
