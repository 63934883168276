import Skeleton from '@mui/material/Skeleton';

import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

export default function PreloaderCalendar() {
    return <div className='PreloaderCalendar'>
        <Skeleton className='title' variant="rectangular" width={140} height={28} style={{marginTop: '16px', marginBottom: '8px'}} />

        <div className='header' style={{display: 'flex', margin: '10px 0 10px', justifyContent: 'center'}}>
            <Skeleton variant="circular" width={36} height={36} style={{margin: '0 2px'}} />
            <Skeleton variant="circular" width={36} height={36} style={{margin: '0 2px'}} />
            <Skeleton variant="circular" width={36} height={36} style={{margin: '0 2px'}} />
            <Skeleton variant="circular" width={36} height={36} style={{margin: '0 2px'}} />
            <Skeleton variant="circular" width={36} height={36} style={{margin: '0 2px'}} />
            <Skeleton variant="circular" width={36} height={36} style={{margin: '0 2px'}} />
            <Skeleton variant="circular" width={36} height={36} style={{margin: '0 2px'}} />
        </div>

        <DayCalendarSkeleton />
    </div>
}
