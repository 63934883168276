import React from 'react';
import {isMobile} from "react-device-detect";
import {useLocation} from "react-router-dom";

import {getClientCityName} from 'ultra/configs/general';

import {getTelegramMode} from './telegram';

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function setPageTitle(title) {
    if (!title) {
        title = ''
    } else {
        title += ' | ';
    }

    if (window.location.pathname !== '/') {
        const city = getClientCityName(window);
        if (city) {
            title += city;
            title += ' | ';
        }
    }

    title += 'ПРОСТІР';

    document.title = title;
}

export function setBodyClass(classes) {
    document.body.className = "";

    const isRootPage = window.location.pathname === '/'
    const isProfilePage = window.location.pathname.indexOf('/profile/profile') === 0

    const searchParams = new URLSearchParams(window.location.search);
    // // embeded
    // if (searchParams.get('embeded') === 'true') {
    //     sessionStorage.setItem("embeded", "true");
    // }
    // else if (searchParams.get('embeded') === 'false') {
    //     sessionStorage.removeItem("embeded");
    // }
    // if (sessionStorage.getItem('embeded')) document.body.classList.add('embeded');

    if (getTelegramMode()) document.body.classList.add('telegram');

    if (isMobile) document.body.classList.add('mobile');
    if (isRootPage) document.body.classList.add('main');  
    if (isProfilePage) document.body.classList.add('profile');  

    classes?.map(c => {
        document.body.classList.add(c);
    })
}
