import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import {useNavigate} from "react-router-dom";

import {getUrl, getClientCity, getClientConfig} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';

import './index.scss'

export default function Link(props) {
    const {field, isEditMode} = props;

    const navigate = useNavigate();

    const onLinkClick = () => {
      const city = getClientCity(window) || DEFAULT_CITY;
      const {domain} = getClientConfig(window);

      if (city !== field.city) {
        if (field.target !== '_blank') {
          window.location.href = getUrl(domain, field.city, field.uri)
        }
        else {
          window.open(getUrl(domain, field.city, field.uri))
        }
      }
      else {
        if (field.target !== '_blank') {
          navigate(field.uri)
        }
        else {
          window.open(getUrl(domain, city, field.uri))
        }
      }
    }

    if (isEditMode) return null;

    if (field.url) {
      return (<span className='LinkField'>
          <RouterLink to={field.url} target="_blank">
            {field.placeholder}
          </RouterLink>
          <br/><br/>
        </span>)
    }

    else if (field.city && field.uri) {
      return (<span className='LinkField'>
          <span className='pseudoLink' onClick={onLinkClick}>
            {field.placeholder}
          </span>
          <br/><br/>
        </span>)
    }

    else {
      return <></>
    }
}
