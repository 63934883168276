import {getClientConfig} from 'ultra/configs/general';

import {get, post} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function getUsers(search) {
    let link = `${api}/users`;
    if (search) link += search
    return get(link);
}

export function validateUser(id) {
    if (!id) return Promise.reject();

    let link = `${api}/users/validate/${id}`;
    return post(link);
}

export function unblockUser(id) {
    if (!id) return Promise.reject();

    let link = `${api}/users/unblock/${id}`;
    return post(link);
}
