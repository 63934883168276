import React from 'react';
import {useLocation} from 'react-router-dom'

import {signOut} from "firebase/auth"; // sendEmailVerification, getAuth, 

// import {log} from '../../../../Helpers/logs'

import {useFirebaseStore} from '../../../../Stores/firebase';
import {useToasterStore} from '../../../../Stores/toster';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

// import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import './index.scss';

// SECURITY

// import {ref, uploadBytes, deleteObject, getDownloadURL} from "firebase/storage";
// import {doc, getDoc, setDoc} from "firebase/firestore";

// function CheckSecurity () {
//   const {db, storage} = useFirebaseStore();

//   // CHECK PERMITS
//   // 1) check permits to add document
//   getDoc(doc(db, `config`, 'support'))
//     .then(() => {
//       console.error("⛔️ HAS PERMITS TO READ FROM CLIENT")
//     })
//     .catch(() => {
//       console.log("✅ HAS NO PERMITS TO READ FROM CLIENT")
//     })

//   setDoc(doc(db, `config`, 'new-file'), {data: 'some-data'})
//     .then(() => {
//       console.error("⛔️ HAS PERMITS TO WRITE FROM CLIENT")
//     })
//     .catch(() => {
//       console.log("✅ HAS NO PERMITS TO WRITE FROM CLIENT")
//     })

//   // 2) check permits to write image
//   // TODO: change storage ID in client/src/Stores/firebase.js
//   const storageRef = ref(storage, 'test.file')
//   const bytes = new Uint8Array([0x48]);
//   uploadBytes(storageRef, bytes)
//     .then(() => {
//       console.error("⛔️ HAS PERMITS TO UPLOAD FILES FROM CLIENT")
//     })
//     .catch(() => {
//       console.log("✅ HAS NO PERMITS TO UPLOAD FILES FROM CLIENT")
//     })

//   deleteObject(storageRef)
//     .then(() => {
//       console.error("⛔️ HAS PERMITS TO DELETE FILES FROM CLIENT")
//     })
//     .catch(() => {
//       console.log("✅ HAS NO PERMITS TO DELETE FILES FROM CLIENT")
//     })

//   const fileRef = ref(storage, 'gs://prostir-dev-images/shared/emojis.wiki/briefcase.png');
//   getDownloadURL(fileRef)
//     .then(() => {
//       console.error("⛔️ HAS PERMITS TO GET FILES FROM CLIENT")
//     })
//     .catch(() => {
//       console.log("✅ HAS NO PERMITS TO GET FILES FROM CLIENT")
//     })
  
//   return <></>
// }

function AuthWrap({ children }) {
  const {auth} = useFirebaseStore();
  
  auth.languageCode = 'ua';

  const {userState, userDispatch} = useGlobalCustomContext();
  
  const {showError} = useToasterStore();

  const ChildrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { auth, user: userState?.user });
    }
    return child;
  });
  
  // if (userState?.user?._blocked) {
  //   signOut(auth)
  //     .then(() => {
  //       showError({
  //         snackbarMessage: 'Ваш аккаунт заблоковано. Якщо у вас виникли питання, зверніться в службу підтримки',
  //         onCloseHandler: () => {
  //           userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
  //         }
  //       })
  //     }).catch((error) => {
  //         console.error(error);
  //     });

  //   return <></>
  // }

  return (
    <>
      {ChildrenWithProps}
    </>
  );
}

export default AuthWrap;
