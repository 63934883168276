import {useEffect, useState} from 'react';

import {getClientCity} from 'ultra/configs/general';

import {useConfigStore} from '../../../../Stores/config';

import {getFilter} from '../../../../Helpers/filters';

export default function FilterValue(props) {
    const {value, config} = props;

    const {configs} = useConfigStore();
    const {filters} = configs;

    const city = getClientCity(window);
    const [filter, setFilter] = useState();

    useEffect(() => {
        let currentFilter = {}
        if (config?.source) {
            currentFilter = getFilter(filters, city, config?.source);
        }
        else if (config?.values?.values) {
            currentFilter.values = config.values.values;
            currentFilter.label = config.label;
        }

        if (currentFilter && config?.placeholder) {
            currentFilter.label = config.placeholder
        }
        setFilter(currentFilter?.values[value]);
    }, [value])

    if (!config) return <></>

    return filter
}

