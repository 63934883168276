import {hasFullAccess} from 'ultra/helpers/auth';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';
import NodeDetails from '../../../Components/NodeDetails'
import UserUpdatePage from '../../../Components/UserUpdatePage';

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const fullAccess = hasFullAccess(userState?.user);
    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    const initByCurrentUser = isYoursAccount(values._init_by);

    if (initByCurrentUser) {
        return <span>
            {!values.details && <>Сторінка оновлена</>}
            {values?.details?.title && <>
                <>Ви оновили </>
                <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
            </>}
        </span>
    }
    return <UserUpdatePage values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
}
