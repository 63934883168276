var isUndefined = require("lodash").isUndefined;
export function getMessengerType(messenger) {
    if (!isUndefined(messenger === null || messenger === void 0 ? void 0 : messenger.telegram))
        return 'telegram';
    if (!isUndefined(messenger === null || messenger === void 0 ? void 0 : messenger.whatsup))
        return 'whatsup';
    return '';
}
export var getTelegramContact = function (original, addAtSign) {
    if (addAtSign === void 0) { addAtSign = true; }
    var result;
    var checking = String(original);
    if (!checking)
        return null;
    if (/^\d+$/.test(checking)) {
        result = original;
    }
    else if (checking[0] === '@') {
        result = checking.substring(1);
    }
    else if (checking.includes('t.me/')) {
        result = checking.split('t.me/')[1];
    }
    else {
        result = original;
    }
    return addAtSign ? '@' + result : result;
};
var getLink = function (value, domain, prefix) {
    if (prefix === void 0) { prefix = ''; }
    if (!value)
        return {};
    var website = 'https://' + domain + '/';
    if (value.includes(domain)) {
        var end = value.indexOf('?') > 0 ? value.indexOf('?') : value.length;
        return {
            href: value.substring(0, end),
            title: value.substring(value.indexOf(domain + '/') + (domain + '/').length, end),
        };
    }
    if (value[value.length - 1] === '/') {
        value = value.substring(0, value.length - 1);
    }
    if (value.indexOf(website) === 0) {
        return {
            href: value,
            title: "".concat(prefix).concat(value.substring(website.length))
        };
    }
    if (value.indexOf(website) === -1) {
        return {
            href: "".concat(website).concat(value),
            title: "".concat(prefix).concat(value)
        };
    }
    return {
        href: value,
        title: value,
    };
};
export function getMessengerAccount(messenger) {
    if (!messenger)
        return "";
    var acccount = '@';
    var type = getMessengerType(messenger);
    acccount += messenger[type].number;
    return acccount;
}
export function getMessengerLink(messenger) {
    var _a, _b;
    var link;
    switch (getMessengerType(messenger)) {
        case 'telegram':
            link = (_a = getTelegramLink(getTelegramContact(messenger.telegram.number, false))) === null || _a === void 0 ? void 0 : _a.href;
            break;
        case 'whatsup':
            link = (_b = getWhatsupLink(messenger.whatsup.number)) === null || _b === void 0 ? void 0 : _b.href;
            break;
    }
    return link;
}
export var getWhatsupLink = function (value) {
    return getLink(value, 'wa.me', '');
};
export var getTelegramLink = function (value) {
    return getLink(value, 't.me', '');
};
export var getFacebookLink = function (value) {
    return getLink(value, 'facebook.com');
};
export var getInstagramLink = function (value) {
    return getLink(value, 'instagram.com');
};
