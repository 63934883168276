import React, {useState, useEffect} from 'react';

import Button from '@mui/material/Button';

import {Grid} from '@mui/material';

import {useNavigate} from 'react-router-dom';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';

import FilterSelector from '../../FilterSelector';
import {clearFilters} from '../../../Helpers/filters';

import Range from '../Range';
import Sort from '../Sort';
import SortDirection from '../SortDirection';

import {getActiveFilterFromURL, updateActiveFilters, getFiltersFieldsConfig} from '../../../Helpers/filters';

function getActiveRanges(activeRange) {
    if (!activeRange) return []

    const rangesList = []
    activeRange.map(i => {
        if (rangesList.includes(i[0])) return;
        rangesList.push(i[0]);
    })

    const activeList = []
    rangesList.map(field => {
        const from = activeRange.find(r => r[0] === field && r[1] === '>=')
        const to = activeRange.find(r => r[0] === field && r[1] === '<=')

        if (!to || !from) return;

        if (to[2] && from[2]) {
            // TODO:
            // get type
            // const type = 
            // if type = number
            if (Number(from[2]) > Number(to[2])) return;
            // if type = date

            activeList.push(to)
            activeList.push(from)
        }
    })
    return activeList;
}

export default function Filters(props) {
    const {configs, children, showFilters, defaultOrder, setOpen, activeFiltersNumber} = props;

    const navigate = useNavigate();

    const [orderValue, setSort] = useState([]);
    const [filters, setFilters] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [changedFilters, setChangedFilters] = useState([]);
    const [changedRange, setChangedRanges] = useState([])
    const [changedOrder, setChangedOrder] = useState(defaultOrder);
    const [changedRangesSort, setChangedRangesSort] = useState(null)

    const [activeFilters, setActiveFilters] = useState([]);
    const [activeRange, setActiveRanges] = useState([])
    const [activeOrder, setActiveOrder] = useState(defaultOrder);
    const [activeRangesSort, setActiveRangesSort] = useState(null)

    const {showBackdropPreloader} = useBackdropPreloaderStore()
    
    useEffect(() => {
        const {order, ranges, rangesSort, filters} = getActiveFilterFromURL()

        // !!!!!! tour is not hear, if it has admin only permits
        const nodes = configs.content.nodes;

        // filters
        if (showFilters?.filterBy) {
            setFilters(showFilters.filterBy);
        }

        // range
        if (showFilters?.rangeBy) {
            const rangesConfig = []
            const rangesKeys = []

            Object.keys(showFilters.rangeBy).map(range => {
                children.map(nodeType => {
                    if (nodeType === 'alias' || nodeType === 'shop-alias') return;

                    // TODO: add check is type same

                    if (!rangesKeys.includes(range)) {
                        rangesKeys.push(range)
                    }
                })

                // is all childrens has option
                if (rangesKeys.includes(range)) {
                    // any child type can be used here children[0]
                    const field = nodes[children[0]]?.fields[range];

                    rangesConfig.push({
                        field,
                        options: showFilters.rangeBy[range],
                        id: range
                    })
                }
            })
            setRanges(rangesConfig);
        }

        // orderValue
        if (showFilters?.orderBy) {
            setSort(showFilters.orderBy)
        }

        if (order) {
            setActiveOrder(order);
        }

        if (ranges) {
            setActiveRanges(ranges);
        }

        if (rangesSort) {
            setActiveRangesSort(rangesSort)
        }

        if (filters) {
            setActiveFilters(filters);
        }
    }, [])

    const handleSubmit = () => {
        let url = new URL(window.location.href);

        const {urlShouldBeUpdated, activeFilters} = updateActiveFilters(changedFilters)

        // filters
        if (urlShouldBeUpdated) {
            const str = JSON.stringify(activeFilters.filter(f => f[0] && f[1]));
            url.searchParams.set('filters', str);
            sessionStorage.setItem("filters", str);
        }
        else {
            url.searchParams.delete('filters');
            sessionStorage.removeItem("filters");
        }

        url.searchParams.delete('page');
        url.searchParams.delete('perPage');

        // order
        if (url.searchParams.get('order') && JSON.stringify(changedOrder) === JSON.stringify(defaultOrder)) {
            urlShouldBeUpdated = true;
            url.searchParams.delete('order');
        }        

        if (JSON.stringify(changedOrder) !== JSON.stringify(defaultOrder)) {
            urlShouldBeUpdated = true;
            url.searchParams.set('order', JSON.stringify(activeOrder));
        }

        // ranges
        if (changedRange.length === 2) {
            if (!changedRange[0][2]) return;
            if (!changedRange[1][2]) return;

            urlShouldBeUpdated = true;
            url.searchParams.set('ranges', JSON.stringify(getActiveRanges(changedRange)));

            if (changedRangesSort && JSON.stringify(changedRangesSort)) {
                const activeRangesSortKey = Object.keys(changedRangesSort);

                if (changedRangesSort[activeRangesSortKey] === 'asc') {
                    url.searchParams.delete('rangesSort');
                } else {
                    url.searchParams.set('rangesSort', JSON.stringify(changedRangesSort));
                }
            }

            url.searchParams.delete('page');
            url.searchParams.delete('perPage');
        }

        setOpen(false);

        if (urlShouldBeUpdated) {
            showBackdropPreloader();
            navigate(url.pathname + url.search);
        }
    }

    const handleClear = () => {
        showBackdropPreloader();

        const url = clearFilters(showFilters.filterBy);
        navigate(url.pathname + url.search);

        setActiveRanges([]);
        setActiveRangesSort(null);

        // setActiveFilters(filtersToLeave);
        setActiveFilters(null);

        setActiveOrder(defaultOrder);

        setOpen(false);
    }

    // changed fields
    const handleFilterChange = (filters) => {
        setChangedFilters(filters);
        setActiveFilters(filters);
    }

    const handleRangesChange = (value) => {
        const newRanges = [...activeRange];

        let type
        if (value.type === 'from') type = '>='
        if (value.type === 'to') type = '<='

        const index = newRanges.findIndex(i => i[0] === value.field && i[1] === type);

        if (index === -1) {
            newRanges.push([
                value.field,
                type,
                value.value
            ]);
        }
        else {
            // ?????? 
            if (!value.value) {
                newRanges.splice(index, 1);
            } else {
                newRanges[index] = [
                    value.field,
                    type,
                    value.value
                ];
            }
        }

        setChangedRanges([...newRanges]);
    }

    const handleRangesSortChange = (range, value) => {
        const result = {...activeRangesSort};
        result[range] = value;

        setChangedRangesSort(result);
    }

    const handleSortChange = (e) => {
        const key = Object.keys(activeOrder)[0]
        const direction = activeOrder[key]

        setChangedOrder({
            [e.target.value]: direction
        })
    }

    const handleSortDirectionChange = (direction) => {
        const key = Object.keys(activeOrder)[0]

        setChangedOrder({
            [key]: direction
        })
    }

    return <div>
        <Grid container spacing={2}>
            {!showFilters.rangeBy && Object.keys(orderValue).length > 1 && <Sort
                activeOrder={activeOrder}
                orderValue={orderValue}
                handleSortChange={handleSortChange}
            />}

            {!showFilters.rangeBy && Object.keys(orderValue).length > 1 && 
                <Grid item xs={4} md={2}>
                    <SortDirection
                        activeOrder={activeOrder}
                        defaultOrder={defaultOrder}
                        handleSortDirectionChange={handleSortDirectionChange}
                    />
                </Grid>}

            {filters.map(filter => <Grid item xs={12} key={filter.id}>
                <FilterSelector
                    filter={filter}
                    activeFilters={activeFilters}
                    handleChange={handleFilterChange}
                />
            </Grid>
            )}

            {ranges?.map(range => <Range
                key={range.id}
                range={range}

                activeRange={activeRange}
                rangesSort={activeRangesSort}
                defaultOrder={defaultOrder}

                handleChange={handleRangesChange}
                handleSortChange={(value) => handleRangesSortChange(range.id, value)}
            />)}
            
            {activeFiltersNumber != 0 && <Grid item xs={6}>
                <Button variant='outlined' fullWidth onClick={handleClear}>Очистити</Button>
            </Grid>}

            <Grid item xs={activeFiltersNumber === 0 ? 12 : 6} onClick={handleSubmit}>
                <Button variant="contained" fullWidth>Обрати</Button>
            </Grid>
        </Grid>
    </div>
}
