import React from 'react';

import {isMobile} from "react-device-detect";

import {NavLink as RouterLink} from "react-router-dom";

import {ThemeProvider} from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';

import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import {useNavigationStore} from '../../../../Stores/navigation';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import BannerCarousel from '../../../../Components/BannerCarousel';
import Notify from '../../../../Components/Notify';
import PreloaderBackdrop from '../../../../Components/PreloaderBackdrop';

import NodePopups from '../../Components/NodePopups';
import UserPic from '../../Components/UserPic';
import UserNotify from '../../Components/UserNotify';

import Edit from '../../../Profile/Pages/Edit';

import Footer from '../../Widgets/Footer';

import theme from '../../../../mui';

import './index.scss'

const AppLayout = (props) => {
  const {configs} = props;

  const {contentLoading} = useNavigationStore();
  const {userState, userDispatch} = useGlobalCustomContext();

  const handleProfileEditClose = () => {
    userDispatch({type: USER_ACTIONS.PROFILE_EDIT_CLOSE});
  };

  return <ThemeProvider theme={theme}>
    <div className='contentWrap'>
        <Notify />
        <PreloaderBackdrop />

        <div className='pageTop'>
          <div className='subheader'>
            <div className='subheaderWrap'>
              <RouterLink className='logo' to="/">
                <img className='logoImg' src="/img/logo.png" />
                <span className='brand'>
                  ПРОСТІР
                </span>
                <span className='beta'>beta</span>
              </RouterLink>
            </div>

            <UserNotify configs={configs} />
            <UserPic configs={configs} />
          </div>
          
          {configs?.layout?.mainCarousell && <Collapse in={!contentLoading}>
            <BannerCarousel
              className="MainBannerCarousell"
              animation="slide"
              autoPlay={false}
              data={configs?.layout?.mainCarousell}
            />
          </Collapse>}

        </div>

        <div className='AppWrap'>
          {configs && props.children}
        </div>

        {!isMobile && <Footer />}

        {/* POPUPS */}
        <NodePopups />

        <AdaptiveDialog open={Boolean(userState?.profileEditPopupOpen)} onClose={handleProfileEditClose} title="Мій Простір">
          <Edit user={userState?.user} onClose={handleProfileEditClose} />
        </AdaptiveDialog>
    </div>
    </ThemeProvider>
}

export default AppLayout
