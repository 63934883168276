import {useConfigStore} from '../../../../../../../../Stores/config';
import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import LinksTileWithCategories from '../../../../../../Widgets/LinksTileWithCategories'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import FilterChip from '../../../../../../Widgets/FilterChip'

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props

    const {configs} = useConfigStore();
    const {contentLoading} = useNavigationStore()

    let hasCategories = false
    children.order.map(id => {
        if (hasCategories) return;

        if (children.list[id].category) {
            hasCategories = true;
        }
    })

    return <div className='LibraryTemplate'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />
        {/* showSkeleton={contentLoading}  */}

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <CKEditorContent content={content.description} />

        <LinksTileWithCategories
            xs={12} md={12}
            categoriesConfig={config.categories}
            permits={permits}
            
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}
            src="poster"
            label={(link) => <FilterChip value={link.region} config={configs.content.nodes['book'].fields.region} />}
            title={(link) => <div>
                {link.author && <div className='bookAuthors'>{link.author}</div>}
                {link.title && <div className='bookTitle'>{link.title}</div>}
            </div>}
        />
    </div>
}
