import {useState} from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';

import AdaptiveDialog from '../../../../../../../../../Components/Adaptive/Dialog';
import ReaderInfo from '../ReaderInfo';

export default function ReaderDetails(props) {
    const [open, setOpen] = useState();
    const {book, fullWidth} = props;

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return <>
        <Button startIcon={<InfoOutlinedIcon />} variant="outlined" fullWidth={fullWidth} onClick={handleOpen}>Деталі</Button>
        <AdaptiveDialog open={open} onClose={handleClose} title={`Деталі`}>
            <ReaderInfo content={book}/>
            {/* TODO: add for admin cancel button */}
        </AdaptiveDialog>
    </>
}
