import React, {useState, useEffect} from 'react';

import {isMobile} from "react-device-detect";

import Button from '@mui/material/Button';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import {DEFAULT_CHILDREN} from "ultra/const/general";

export default function SortDirection(props) {
    const {activeOrder, defaultOrder, handleSortDirectionChange} = props;

    const [currenstSort, setCurrentSort] = useState(defaultOrder);

    useEffect(() => {
        if (activeOrder) {
            setCurrentSort(activeOrder[Object.keys(activeOrder)[0]])
        }
    }, [activeOrder])

    if (!activeOrder) return <></>

    return <div className='sortDirection'>
        {currenstSort === 'asc'
            && <Button variant='outlined' fullWidth onClick={() => {
                setCurrentSort('desc')
                handleSortDirectionChange('desc')
            }}>
                від меншого до більшого
                <ArrowUpwardIcon />
            </Button>}
        
        {currenstSort === 'desc'
            && <Button variant='outlined' fullWidth onClick={() => {
                setCurrentSort('asc')
                handleSortDirectionChange('asc')
            }}>
                від більшого до меншого
                <ArrowDownwardIcon />
            </Button>}
    </div>
}