import './index.scss';
import CurrencyFormated from '../../../../Components/CurrencyFormated'

export default function PriceWidget(props) {
    const {value} = props;

    if (value?.price && value?.currency) {
        return <CurrencyFormated value={value.price} currency={value.currency} />
    } else {
        return <></>
    }
}
