import {useEffect, useState} from "react";
import ContentImage from '../../../../../Content/Widgets/ContentImage'

export default function UploadedFile(props) {
    const {city, file} = props;
    
    const [image, setImage] = useState()

    useEffect(() => {
        if (file) {
            const key = Object.keys(file)[0];
            setImage(file[key])
        }
    }, [file])
    
    if (!file) return <></>

    if (!image) return <></>

    return <ContentImage city={city} className="ContentImage" image={image}/>
}
