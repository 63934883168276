import React from 'react';

import {getClientUrl} from "ultra/configs/general";

import {useConfigStore} from '../../../../Stores/config';

import './index.scss';

export default function UserSingeltonesList(props) {
    const {list} = props;
    const {configs} = useConfigStore();

    if (!list) return <></>

    return Object.keys(list).map(city =>
        <div className='UserSingeltonesList' key={`_user_singeltone_${city}`}>
            <span className='cityName'>
                <i>{configs.content.cities[city].city || "Головна"}: </i>

                <span className='cityValues'>
                    {Object.keys(list[city]).map(type =>
                        <span key={`_user_singeltone_${city}_${type}`}>
                            {configs.content.nodes[type].title}:
                            &nbsp;
                            <a href={getClientUrl(city, list[city][type]._uri)}>{list[city][type].title}</a>
                        </span>
                    )}
                </span>
            </span>
        </div>
    )
}
