export const ukLocale = {
    fieldYearPlaceholder: params => 'Р'.repeat(params.digitAmount),
    fieldMonthPlaceholder: params => params.contentType === 'letter' ? 'ММММ' : 'ММ',
    fieldDayPlaceholder: () => 'ДД',
    // fieldWeekDayPlaceholder: params => params.contentType === 'letter' ? 'EEEE' : 'EE',
    fieldWeekDayPlaceholder: params => params.contentType === 'letter' ? 'ДДДД' : 'ДД',
    fieldHoursPlaceholder: () => 'гг',
    fieldMinutesPlaceholder: () => 'мм',
    fieldSecondsPlaceholder: () => 'сс',
    fieldMeridiemPlaceholder: () => 'aa',
    okButtonLabel: 'Обрати',
    cancelButtonLabel: 'Відміна',
};
