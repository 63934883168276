import React, {useState, useEffect} from 'react';

import Grid from '@mui/material/Grid';

import {getUserRoles} from 'ultra/helpers/auth';
import {isAdmin} from 'ultra/helpers/auth';
import {getStoreFileName} from 'ultra/helpers/storage';
import {isUndefined, isObject, isArray} from 'lodash';
import {isFile, isEmptyObj, isValidDate} from 'ultra/helpers/utils'; // isEmptyMessengerData
import {
  validateRequiredFields,
  validateRegExpFields,
  validateRequiredFiles,
  validateRequiredGalleriesOnClient,
  getFieldStatus
} from 'ultra/helpers/content';

import Password from './Password';
import Checkbox from './Checkbox';
import Filter from './Filter';
import UploadImage from './UploadImage';
import UploadGallery from './UploadGallery';
import Text from './Text';
import Date from './Date';
// import DateString from './DateString';
import Timestamp from './Timestamp';
import Messenger from './Messenger';
import Subtitle from './Subtitle';
import Email from './Email';
import Number from './Number';
import Price from './Price';
import Tags from './Tags';
// import TinyMCE from './TinyMCE';
import Textarea from './Textarea';
import JSONEditor from './JSONEditor';
import CKEditor from './CKEditor';
// import Select from './Select';
import Link from './Link';
// import Reference from './-Reference';
import Hidden from './Hidden';
import Path from './Path';
import Node from './Node';
import User from './User';
import BooleanField from './Boolean';
import Location from './Location';
import URL from './URL';

import {useToasterStore} from '../../Stores/toster';
import {useGlobalCustomContext} from '../../Helpers/context'

import {getTelegramUser} from '../../Helpers/telegram';

import './index.scss'

const Controls = {
  // dateString: DateString,
  // reference: Reference,
  subtitle: Subtitle,
  image: UploadImage,
  timestamp: Timestamp,
  date: Date,
  messenger: Messenger,
  gallery: UploadGallery,
  password: Password,
  filter: Filter,
  text: Text,
  email: Email,
  number: Number,
  icon: Text,
  price: Price,
  tags: Tags,
  textarea: Textarea,
  json: JSONEditor,
  wysiwyg: CKEditor,
  link: Link,
  hidden: Hidden,
  path: Path,
  node: Node,
  section: Node,
  user: User,
  location: Location,
  url: URL,
  checkbox: Checkbox,
  boolean: BooleanField,
}

function getErrors(fields, newForm, isEditMode) {
  return {
    ...validateRequiredFields(fields, newForm, isEditMode),
    ...validateRegExpFields(fields, newForm),
    ...validateRequiredFiles(fields, newForm),
    ...validateRequiredGalleriesOnClient(fields, newForm)
  }
}

function RenderControl(props) {
  const {field, fields, isEditMode, error, content, disabled, roles, formActions, onChange} = props;

  const {showError} = useToasterStore();
  // const [showErrorText, setShowErrorText] = useState('')

  const getDataFromControlChangeEvent = (field, e, currentValue) => {
    if (field.type === 'checkbox') {
      return e.target.checked;
    }
    else if (field.type === 'messenger') {
      // if (isEmptyMessengerData(e.target.value)) {
      if (!e.target.value) {
        return {}
      } else {
        return e.target.value
      }
    }
    else if (field.type === 'image') {
      // if (Object.is(e.target.files, null)) {
      //   // null - means image should be removed
      //   return null
      // } else {
      return e.target.files ? e.target.files[0] : undefined;
      // }
    }
    else if (field.type === 'gallery') {
      // TODO: check and move to one place all logic for gallery
      function formatValue(value) {
        if (!value) return null

        // if array, create object
        if (isArray(value)) {
            const result = {}
            value.map(i => {
                const name = getStoreFileName(i)
                result[name] = i
            })
            return result
        }

        // if object, return value
        return value
      }

      const result = formatValue(currentValue) || {}

      const files = e.target.files ? Array.from(e.target.files) : [];
      files.forEach(file => {
        if (file.size === 0) {
          result[file.name] = file 
          return
        }

        if (!Boolean(result[file.name])) {
          result[file.name] = file
          return
        }

        if (isFile(result[file.name]) && result[file.name].size === 0) {
          result[file.name] = file 
          return
        }

        alert(`${file.name} вже існує, оберіть інший`)
      })

      return result
    }
    else if (field.type === 'json') {
      return e.src;
    }
    else if (field.type === 'boolean') {
      if (typeof e.target.value === 'string') {
        return e.target.value === 'true';
      } else {
        return e.target.value
      }
    }
    else {
      return e.target.value;
    }
  }

  const onControlChangeHandler = (e, id = null) => {
    // id - used for password type controller, which need to update few fields | TODO: refactor
    const newForm = {...content};

    // change value
    const fieldId = id || field.id;
    newForm[fieldId] = getDataFromControlChangeEvent(field, e, newForm[id || field.id]);

    // setup null if no data
    // on backend it will be removed from node document
    if (
      isUndefined(newForm[fieldId])
      || newForm[fieldId] === ''
      || (!isFile(newForm[fieldId]) && !isValidDate(newForm[fieldId]) && isObject(newForm[fieldId]) && isEmptyObj(newForm[fieldId]))
      || (isArray(newForm[fieldId] && newForm[fieldId].length === 0))) {
        newForm[fieldId] = null;
    }

    onChange({
      content: newForm,
      errors: getErrors(fields, newForm, isEditMode)
    })
  }

  const isDisabledForNotAllowedUsers = getFieldStatus(field, roles)['edit'] === 'disabled'; // isEditMode
  const controlDisabled = isDisabledForNotAllowedUsers || disabled;

  const onFieldClickHandler = (text) => {
    showError({snackbarMessage: text})
  }

  if (field.type === 'hidden') return <></>

  if (typeof Controls[field.type] !== 'undefined') {
    return <Grid key={field.id} item xs={12}>
      <div className='controlWrapper'>
        {controlDisabled && field?.options?.disabledFieldHint && <div className='controlWrapperDisabled' onClick={() => onFieldClickHandler(field?.options?.disabledFieldHint)}></div>}
        {React.createElement(Controls[field.type], {
          key: field.id,
          disabled: controlDisabled, // isEditControlDisabledForNotAllowedUsers
          content,
          field,
          isEditMode,
          formActions,
          showErrorText: error,
          onChange: onControlChangeHandler,
        })}
      </div>
    </Grid>;
  }

  return React.createElement(() => <Grid key={field.id} item xs={12}>
      <div className='noComponent'>Тип поля {field.type} не має відповідного компоненту</div>
    </Grid>
  )
}

export default function Form(props) {
  const {errors, content, fields, formActions, onInit, isEditMode, order} = props

  const {userState} = useGlobalCustomContext()

  const roles = getUserRoles(userState?.user, getTelegramUser(), content)

  const [showSystem, setShowSystem] = useState(false)

  // USE ORDER
  const controls = []
  const system = []
  
  useEffect(() => {
    if (onInit) {
      onInit({
        content,
        errors: getErrors(fields, content, isEditMode)
      })
    }
  }, [])

  if (order) {
    // first render ordered
    order.map(fieldId => {
      if (getFieldStatus(fields[fieldId], roles)['edit'] === 'hidden') return;

      if (fields[fieldId]) {
        if (fields[fieldId]?.options?.system) {
          system.push({...fields[fieldId], id: fieldId})
        } else {
          controls.push({...fields[fieldId], id: fieldId})
        }
      }
    })

    // then all other
    Object.keys(fields).map(fieldId => {
      if (getFieldStatus(fields[fieldId], roles)['edit'] === 'hidden') return;

      if (!order.includes(fieldId)) {
        if (fields[fieldId]?.options?.system) {
          system.push({...fields[fieldId], id: fieldId})
        } else {
          controls.push({...fields[fieldId], id: fieldId})
        }
      }
    })
  }
  // disordered render
  else {
    Object.keys(fields).map(fieldId => {
      if (getFieldStatus(fields[fieldId], roles)['edit'] === 'hidden') return;

      if (fields[fieldId]?.options?.system) {
        system.push({...fields[fieldId], id: fieldId})
      } else {
        controls.push({...fields[fieldId], id: fieldId})
      }
    })
  }

  return <>
    {controls && controls.length > 0 && controls.map(field => 
      <RenderControl key={field.id} {...props} error={errors[field.id]} formActions={formActions} field={field} roles={roles} />
    )}
    {isAdmin(userState?.user) && system && system.length > 0 && <>
        <Grid item xs={12}>
          <div className={`systemFields ${showSystem ? 'systemFieldsOpen' : ''}`} onClick={() => setShowSystem(!showSystem)}> {showSystem ? '-' : '+'} <span className='pseudoLink'>Системні параметри</span></div>
        </Grid>

        {showSystem && system.map(field =>
            <RenderControl key={field.id} {...props} error={errors[field.id]} formActions={formActions} field={field} roles={roles} />
        )}
      </>
    }
  </>
}
