import {isMobile} from "react-device-detect";

import ContentImage from '../ContentImage'
import Carousel from '../../../../Components/Carousel';

// https://swiperjs.com/demos#thumbs-gallery-loop

export default function Gallery(props) {
    const {content, className} = props;

    if (!content) return <></>

    return <div className={className}>
        {content && <Carousel className="CarouselPhotos" navigation={!isMobile} slidesPerView={isMobile ? 1 : 2}>
            {content.map(i => <ContentImage key={i} image={i} className="image" />)}
        </Carousel>}
    </div>
}
