import React, {useEffect, useState} from 'react'; // useRef

import {isMobile} from "react-device-detect";

import {useLoaderData, useNavigation} from "react-router-dom";

import {hasFullAccess} from 'ultra/helpers/auth';

import { Badge, Button } from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import {getConversation} from '../../Helpers/conversations';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import {useConfigStore} from '../../../../Stores/config';

import PreloaderTable from '../../../../Components/PreloaderTable';

import FullHeightContentBlock from '../../../Content/Widgets/FullHeightContentBlock';
import TableView from '../../../Content/Widgets/Table';
import TitleWidget from '../../../Content/Widgets/Title';

import Textarea from '../../../../Components/Form/Textarea';

import ConversationAvatar from '../../../Content/Widgets/ConversationAvatar';

import {setPageTitle} from '../../../../Helpers/router';
import {getAuthUserID, isYoursAccount} from '../../../../Helpers/user';
import {getConversationTitle} from '../../../../Helpers/conversation';

import './index.scss';

const TEXTAREA_HEIGHT = 130;

export default () => {
    const data = useLoaderData();

    const {configs} = useConfigStore();
    
    const navigation = useNavigation();

    const {userState} = useGlobalCustomContext(); // nodeState, nodeDispatch

    const [notifications, setNodes] = useState();
    const [tableOptions, setTableOptions] = useState();
    // const [config, setConfig] = useState();
    const [permits, setPermits] = useState();
    const [topic, setTopic] = useState();
    const [isPrivateConversation, setPrivateConversation] = useState();
    const [isDialog, setDialog] = useState();

    const baseFields = {
        message: {
            type: 'message',
            placeholder: 'Повідомлення',
            extraData: data.users,
            conversation: data.conversation,
        },
    }

    const [fields] = useState(baseFields);

    const MobileConfig = {
        visible: ['message'],
        hidden: [],
        infinityScroll: true,
        infinityScrollReversed: true,
        noHeaders: true
    }
    const DesktopConfig = {
        visible: ['message'],
        hidden: [],
        infinityScroll: true,
        infinityScrollReversed: true,
        noHeaders: true
    }

    useEffect(() => {
        setTableOptions(isMobile ? MobileConfig : DesktopConfig)
        setPermits({})
    }, [])

    // useEffect(() => {
    //     if (nodeState.updatePageContentStart) {
    //         nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
    //         updatePage();
    //     }
    // }, [nodeState.updatePageContentStart])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateNotifications(data);
        }
    }, [navigation])

    useEffect(() => {
        if (hasFullAccess(userState.state)) {
            MobileConfig.hidden.push('access')
            DesktopConfig.hidden.push('access')
        }

        updateNotifications(data)

        setTableOptions(isMobile ? MobileConfig : DesktopConfig)

        // const config = {
        //     templateOptions: {}
        // }

        // if (configs) {
        //     setConfig({...config})
        // }
    }, [userState?.user, configs])

    function updateNotifications(notifications) {
        if (userState?.user && !topic) {
            const isPrivateConversation = notifications.conversation.conversation && notifications.conversation.private;
            const isDialog = notifications.conversation.conversation && notifications.conversation.dialog;

            setPrivateConversation(isPrivateConversation)
            setDialog(isDialog)

            const title = getConversationTitle(configs, notifications.conversation, userState, notifications.users)

            setPageTitle(title)
            setTopic(<>
                <ConversationAvatar conversation={notifications.conversation} users={notifications.users} />
                <span>{title}</span>
            </>)
        }

        // notifications.order = notifications.order.reverse()
        setNodes(notifications)
    }

    // const updatePage = () => {
    //     const url = new URL(window.location.href);

    //     updateNotifications({});
    //     getConversation(url.search)
    //         .promise
    //         .then(updateNotifications)
    // }

    const selectClasses = (data) => {
        let s;

        if (userState?.user) {
            if (data && !data?._seen_by) {
                s = 'tr_unseen';
            } else if (data && data?._seen_by && !data._seen_by.includes(getAuthUserID(userState))) {
                s = 'tr_unseen';
            }

            if (isYoursAccount(userState, data?._init_by)) {
                s += ' tr_sender'
            } else {
                s += ' tr_recepient'
            }
        }

        return `${s ? s : ''}`
    }

    const breadcrumbs = {
        // '\\': {
        //     title: getCityConfig(configs, city).city || 'Головна'
        // },
        '\\profile\\messages': {
            title: 'Сповіщення'
        }
    }

    return <div id="Content" className='Content'>
            <div className={`ConversationPage ${isPrivateConversation ? 'ConversationDialogPage' : ''}`}>
                {/* actions={<div><ShoppingBagOutlinedIcon /></div>} */}
                <TitleWidget permits={permits} content={{title: topic}} breadcrumbs={breadcrumbs} />
                {/* config={config} */}
                {/* {topic && <BreadcrumbsWidget links={breadcrumbs} hideRoot />} */}

                {/* {isMobile && config?.showFilters?.filterByInline && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>} */}

                {(!fields) && <PreloaderTable/>}
                    
                {notifications?.order.length === 0 && <div>
                    У вас немає сповіщень
                </div>}

                {fields && notifications && <FullHeightContentBlock bottomPadding={isDialog ? TEXTAREA_HEIGHT : 0} className={isDialog ? 'hasPadding' : ''}>
                        <TableView
                            showPreloader={!notifications?.list}
                            content={notifications}

                            fields={fields}
                            tableOptions={tableOptions}

                            showPagination={true}

                            actions={false}

                            selectClasses={selectClasses}

                            scrollContainer={() => document.getElementsByClassName('FullHeightContentBlock')}
                            scrollGetData={(search) => getConversation(data.conversation._cid, search)}
                        />
                    </FullHeightContentBlock>
                }
                {isDialog && <div className='ConversationMessenger'>
                    <div className='textName'>
                        <Textarea content={{message: ''}} field={{id: 'message', placeholder: 'Ваше повідомлення'}} />
                    </div>
                    <div className='buttons'>
                        <Button>
                            <SendIcon />
                        </Button>

                        <Button>
                            <AttachFileIcon />
                        </Button>
                    </div>
                </div>}
            </div>
        </div>
}
