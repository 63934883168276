import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export default function PreloaderForm() {
    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" width={'100%'} height={56} style={{marginBottom: '.5rem'}} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" width={'100%'} height={92} style={{marginBottom: '.5rem'}} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" width={'100%'} height={56} style={{marginBottom: '.5rem'}} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" width={'100%'} height={56} style={{marginBottom: '.5rem'}} />
            </Grid>
        </Grid>
    </div>
}

export function PreloaderShortForm() {
    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" width={'100%'} height={56} style={{marginBottom: '.5rem'}} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" width={'100%'} height={56} style={{marginBottom: '.5rem'}} />
            </Grid>
        </Grid>
    </div>
}
