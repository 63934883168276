import {useEffect, useState} from 'react';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {attachUserData} from 'ultra/helpers/auth';

import FormContainer from '../../../../Components/Form/FormContainer';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import './index.css';

export default function SubmitForm(props) {
  const [formConfig, setFormConfig] = useState(null);

  const {userState} = useGlobalCustomContext();

  const {data, selected, insuranceConfig, afterSubmit} = props;
  const {clients} = data;

  const attachDefaultValues = (config) => {
    const defaultValues = {}

    Object.keys(config.fields).map(field => {
      if (config.fields[field].default) {
        const defVal = attachUserData(config.fields[field].default, userState?.user)

        if (defVal) {
          defaultValues[field] = defVal
        }
      }
    })

    config.defaultValues = defaultValues

    return config;
  }

  // TODO: add formatValuesByType and trimTextFields
  const formatRequest = (form) => {
    const contacts = {}
    const clients = {}

    Object.keys(form).map(key => {
      if (key.includes('client_')) {
        const parts = key.split('_')

        clients[parts[1]] = clients[parts[1]] || {}
        clients[parts[1]][parts[2]] = form[key]
      } else {
        contacts[key] = form[key]
      }
    })

    return {
      order: data,
      contacts,
      clients,
      selected
    }
  }
  useEffect(() => {
    let formConfig = {}
    formConfig.fields = {}
    formConfig.fieldsOrder = []
    formConfig.form = insuranceConfig.form.form;
  
    const fields = {}
    const order = []
    for (let i = 1; i <= clients; i++) {
      if (clients > 1) {
        fields[`client_${i}_subtitle`] = {
          type: 'subtitle',
          placeholder: `Клієнт #${i}`
        }
        order.push(`client_${i}_subtitle`)
      }

      Object.keys(insuranceConfig.client.fields).map(field => {
        fields[`client_${i}_${field}`] = insuranceConfig.client.fields[field]
      })
      insuranceConfig.client.fieldsOrder.map(field => {
        order.push(`client_${i}_${field}`)
      })
    }

    formConfig.fields = {...insuranceConfig.form.fields}
    Object.keys(fields).map(field => {
      formConfig.fields[field] = fields[field]
    })
    formConfig.fields.accept = {
      type: 'checkbox',
      placeholder: 'Погоджуюсь на обробку даних',
      required: true,
    }

    formConfig.fieldsOrder = [...insuranceConfig.form.fieldsOrder]
    order.map(field => {
      formConfig.fieldsOrder.push(field)
    })
    formConfig.fieldsOrder.push('accept')

    const city = getClientCity(window) || DEFAULT_CITY;
    if (!formConfig.form.endpoint.includes(city)) {
      formConfig.form.endpoint += '/' + city;
    }

    setFormConfig(formConfig)
  }, [data])

  if (!formConfig) return <></>

  return <FormContainer
            config={attachDefaultValues(formConfig)}
            prepareData={formatRequest}
            afterSubmit={afterSubmit}
      />
}
