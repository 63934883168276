import React, {useEffect, useState} from 'react'

import ContentImage from "../ContentImage";

import './index.scss';

export default function NodeImage(props) {
    const {values, city, className} = props; // fields

    const [src, setSrc] = useState()
    
    useEffect(() => {
        // !!! TODO: not use children options here
        // if (fields) {
        //     if (fields.thumbnail) {
        //         setSrc('thumbnail')
        //     } else if (fields.poster) {
        //         setSrc('poster')
        //     } else if (fields.banner) {
        //         setSrc('banner')
        //     }
        // }
        // else {
            if (values.thumbnail) {
                setSrc('thumbnail')
            } else if (values.poster) {
                setSrc('poster')
            } else if (values.banner) {
                setSrc('banner')
            } else {
                // set not image
                setSrc('thumbnail')
            }
        // }
    }, [])

    return <ContentImage src={src} city={city} image={values[src]} className={className} />
}
