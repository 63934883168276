import React, {useEffect, useState, useRef} from 'react';
import {isMobile} from "react-device-detect";

import Preloader from '../../../../Components/Preloader';

import './index.scss';

export default function FullHeightContentBlock(props) {
    const {children, bottomPadding, className} = props;
    const [height, setHeight] = useState();
    const [showPreloader, setShowPreloader] = useState(true);

    const blockRef = useRef(null);
    const contentRef = useRef(null);

    // ACCEPT FOR REVESED
    function scrollTo() {
        // move to separate method or run when option present
        blockRef?.current?.scrollTo(0, contentRef.current?.scrollHeight);
    }

    function updateHeight() {
        let height = window.innerHeight
        const topOffset = blockRef.current.offsetTop || 0;
        const contentOffset = document.getElementsByClassName('Content')[0]?.offsetTop || 0;
        const footerHeight = document.getElementsByClassName('Footer')[0]?.clientHeight || 0;
        const paddings = isMobile ? 80 : 96;
    
        height -= topOffset;
        height -= contentOffset;
        height -= footerHeight;
        height -= paddings;

        height -= bottomPadding;
    
        if (height < 200) height = 200


        setHeight(height);
    }
    
    const onResize = () => {
        updateHeight()
    }

    useEffect(() => {
        updateHeight()

        setTimeout(() => {
            setShowPreloader(false);
            if (scrollTo) scrollTo();
        }, 1000)
    
        window.visualViewport.addEventListener('resize', onResize);
        return () => {
            window.visualViewport.removeEventListener('resize', onResize);
        };
      }, [])


    return <div className={`FullHeightContentBlockWrap ${className}`}>
        <div className={showPreloader ? 'FullHeightContentBlock hidden' : 'FullHeightContentBlock'} style={{height: `${height}px`}} ref={blockRef}>
            {showPreloader && <Preloader />}

            <div className={showPreloader ? 'FullHeightContentBlockContent hidden' : 'FullHeightContentBlockContent'} ref={contentRef}>            
                {children}
            </div>
        </div>
    </div>
}
