import { NavLink as RouterLink } from "react-router-dom";

function getFormHistory(partner, form) {
    return `/profile/history/form/${partner}/${form}`
}

export default function FormHistoryLink(props) {
    const {details, title} = props;

    if (!details) return <></>

    return <RouterLink to={getFormHistory(details.partner, details.form)}>{title || 'Відсутня назва'}</RouterLink>
}
