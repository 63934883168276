import {hasFullAccess} from 'ultra/helpers/auth';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        return <span>Ви оновили пароль</span>
    }
    else {
        return <div className="NotificationTitle">
            <><UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} /> </>
            <span>оновив пароль</span>
        </div>
    }
}
