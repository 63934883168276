import dayjs from 'dayjs';

import {useEffect, useState} from "react";
import {timestampToDate} from 'ultra/helpers/date';
import GoogleIcon from '@mui/icons-material/Google';

import Button from '@mui/material/Button';

export default function AddToCalendar(props) {
    const {title, date, duration, location} = props;
    
    // const [startDate, setStartDate] = useState()
    // const [endDate, setEndDate] = useState()
    const [link, setLink] = useState()

    useEffect(() => {
        const start = dayjs(timestampToDate(date));
        const end = duration ? start.add(duration, 'hour') : start.add(1, 'hour');

        function getTimeFormated(date) {
            return date.format('YYYYMMDDTHHmmss')
        }

        let link = `https://www.google.com/calendar/render?action=TEMPLATE`;
        if (title) link += `&text=${title}`
        if (start && end) link += `&dates=${getTimeFormated(start)}/${getTimeFormated(end)}`
        if (location?.link) link += `&location=${location.link}`

        let details = ''
        if (location?.title) {
            details += `${location.title}%0A`;
        }
        if (location?.link) {
            details += `${location.link}`;
        }

        link += `&details=${details}`

        setLink(link);
    }, [date])

    // TODO: FIX LINK
    return <></>

    return <>
        <Button
            fullWidth
            startIcon={<GoogleIcon />}
            variant="outlined"
            target="_blank"
            size="small"
            href={link}>
                Додати в Google Calendar
        </Button>
        {/* &amp;sprop=&amp;sprop=name: */}
        {/* <a href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:http://carlsednaoui.github.io/add-to-calendar-buttons/generator/generator.html%0ADTSTART:20190725T082900Z%0ADTEND:20190823T193000Z%0ASUMMARY:${title}%0ADESCRIPTION:${details}0ALOCATION:${location}%0AEND:VEVENT%0AEND:VCALENDAR`}>iCal Calendar</a> */}
        {/* <a href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:http://carlsednaoui.github.io/add-to-calendar-buttons/generator/generator.html%0ADTSTART:20190725T082900Z%0ADTEND:20190823T193000Z%0ASUMMARY:${title}%0ADESCRIPTION:${details}0ALOCATION:${location}%0AEND:VEVENT%0AEND:VCALENDAR`}>Outlook Calendar</a> */}
    </>
}

// https://stackblitz.com/edit/react-add-to-calendar?file=index.js

// https://add-to-calendar-button.com/use-with-react
// https://medium.com/@dge808/how-to-create-an-add-to-the-calendar-button-for-your-website-8f89f71986af

// https://www.npmjs.com/package/react-add-to-calendar

// https://github.com/CultureHQ/add-to-calendar
// https://reactjsexample.com/a-small-package-for-adding-an-event-to-a-calendar/
// https://blog.telzee.io/calendar-events-reactjs-gapi/
// https://www.addevent.com/c/documentation/add-to-calendar-button