import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";

import {DEFAULT_CITY} from 'ultra/const/general';
import {isEmptyObj} from 'ultra/helpers/utils';
import {restorePath} from "ultra/helpers/route";
import {getClientCity} from 'ultra/configs/general';
import {isLibrary, hasFullAccess} from 'ultra/helpers/auth';

import Collapse from '@mui/material/Collapse';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useToasterStore} from '../../../../../../../../Stores/toster';
import {useConfigStore} from '../../../../../../../../Stores/config';
import {useBackdropPreloaderStore} from '../../../../../../../../Stores/backdropPreloader';

import PreloaderText from '../../../../../../../../Components/PreloaderText';
import Preloader from '../../../../../../../../Components/Preloader';
import AdaptiveDialog from '../../../../../../../../Components/Adaptive/Dialog';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import FilterChip from '../../../../../../Widgets/FilterChip'

import {useGlobalCustomContext} from '../../../../../../../../Helpers/context';
import {isBelongsToLibrary} from '../../../../../../../../Helpers/library';

import {USER_ACTIONS} from '../../../../../../../Profile/Reducers/user';
import {NODE_ACTIONS} from '../../../../../../Reducers/node';

import {reserveBook, returnBook} from '../../../../../../../../Helpers/library'

import ShowActions from './ShowActions';
import ShowLinks from './ShowLinks';
import BookDetails from './BookDetails';

import RulesStep from './BookingSteps/RulesStep';
import SanctionsStep from './BookingSteps/SanctionsStep';
import PersonDetailsStep from './BookingSteps/PersonDetailsStep';
import DepositStep from './BookingSteps/DepositStep';
import DepositReturnStep from './BookingSteps/DepositReturnStep';
import DepositReturnFinalStep from './BookingSteps/DepositReturnFinalStep';
import FinalStep from './BookingSteps/FinalStep';

import './index.scss';

function scrollToTop() {
    isMobile ?
            window.document.getElementsByClassName('SwipeableDrawerContentWrapper')[0].scrollTo(0,0)
            :
            window.document.getElementsByClassName('MuiDialogContent-root')[0].scrollTo(0,0)
}

function Genres(props) {
    const {content, configs} = props;

    if (!content.genre) return <></>

    return <>
        {content.genre && <div className={`labelWrap genre`}>
            <span className="labelValue">
                {content.genre.map(genre => <FilterChip key={genre} value={genre} config={configs.content.nodes['book'].fields.genre} />)}
            </span>
        </div>}
    </>
}

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props

    const navigate = useNavigate();

    const {configs} = useConfigStore();
    const {contentLoading} = useNavigationStore()
    const {showError} = useToasterStore();

    const [openGetBook, setOpenGetBook] = useState(false)
    const [openReturnBook, setOpenReturnBook] = useState(false)

    const [city] = useState(getClientCity(window) || DEFAULT_CITY)

    const [requestInProgress, setRequestInProgress] = useState()

    const [readerManualInformation, setReaderManualInformation] = useState()

    const [personalDataStep, setPersonalDataStep] = useState()
    const [showRulesStep, setShowRulesStep] = useState()
    const [showSanctionsStep, setShowSanctionsStep] = useState()
    const [depositStep, setDepositStep] = useState()
    const [finalStep, setFinalStep] = useState()
    const [finalReturnStep, setFinalReturnStep] = useState()
    const [returnStep, setReturnStep] = useState()
    const [belongsToLibrary, setBelongsToLibrary] = useState()

    const {showPromt, closeToaster} = useToasterStore();
    const {hideBackdropPreloader, showBackdropPreloader} = useBackdropPreloaderStore()
    const {userState, userDispatch, nodeDispatch} = useGlobalCustomContext();


    function supportClickHandle() {   
        nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: configs.links.support})
    }

    function updateContent() {
        userDispatch({type: USER_ACTIONS.USER_REFRESH_START})
        navigate(restorePath(content._uri))
    }

    function handleGetBookOpen() {
        if (!userState?.user || isEmptyObj(userState?.user)) {
            showPromt({
                snackbarMessage: 'Щоб взяти книгу, вам необхідно увійти в систему',
                onApproveHandler: () => {
                    closeToaster();
                    hideBackdropPreloader();

                    userDispatch({type: USER_ACTIONS.LOGIN})
                },
                onCloseHandler: () => {
                    closeToaster();
                    hideBackdropPreloader();
                }
            })
            return;
        }

        setOpenGetBook(true)
        setShowRulesStep(true)
    }

    function acceptBaseRules() {
        setShowRulesStep(false)
        scrollToTop()

        if (belongsToLibrary) {
            setPersonalDataStep(true)
        } else {
            setPersonalDataStep(false)
            setShowSanctionsStep(true)
        }
    }

    function submitPersonDetails(data) {
        setPersonalDataStep(false)
        scrollToTop()

        setReaderManualInformation(data)

        setShowSanctionsStep(true)
    }

    function acceptFinalRules() {
        setShowSanctionsStep(false)
        scrollToTop()

        if (content?.deposit?.price && belongsToLibrary) {
            setDepositStep(true)
        }
        else {
            reserveBookHandler(readerManualInformation, {})
        }
    }

    function sendDeposit(data) {
        setDepositStep(false)
        scrollToTop()

        reserveBookHandler(readerManualInformation, data)
    }
        
    // book actions
    function reserveBookHandler(readerInfo, deposit) {
        setShowRulesStep(false)
        setDepositStep(false)

        scrollToTop()

        setRequestInProgress(true)

        reserveBook(city, content._uri, readerInfo, deposit)
            .then(async () => {
                userDispatch({type: USER_ACTIONS.USER_REFRESH_START})

                setRequestInProgress(false)
                setFinalStep(true)
            })
            .catch((e) => {
                showError({snackbarMessage: e.message})

                setRequestInProgress(false)
                setFinalStep(false)
                setShowRulesStep(true)
            })
    }

    function returnBookHandler() {
        setReturnStep(false)

        setRequestInProgress(true)

        returnBook(city, content._uri)
            .then(() => {
                userDispatch({type: USER_ACTIONS.USER_REFRESH_START})

                setFinalReturnStep(true)
                setRequestInProgress(false)
            })
            .catch((e) => {
                showError({snackbarMessage: e.message})

                setReturnStep(true)
                setFinalReturnStep(false)
                setRequestInProgress(false)
            })
    }

    async function handleReturnBookOpen() {
        setFinalReturnStep(false);

        if (content?.deposit?.price) {
            setOpenReturnBook(true)

            setReturnStep(true)
        }
        else {
            showBackdropPreloader();

            try {
                await returnBook(city, content._uri)
                hideBackdropPreloader();

                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
            }
            catch (e) {
                hideBackdropPreloader();
                // nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                // userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
            }
        }
    }

    // windows close
    function handleGetBookClose() {
        setOpenGetBook(false)

        setShowRulesStep(false)
        setShowSanctionsStep(false)
        setPersonalDataStep(false)
        setDepositStep(false)
        setFinalStep(false)

        if (finalStep) {
            updateContent()
        }
    }

    function handleReturnBookClose() {
        setOpenReturnBook(false)

        setReturnStep(false)
        setFinalStep(false)

        if (finalReturnStep) {
            updateContent()
        }
    }

    useEffect(() => {
        isBelongsToLibrary(city, content._uri)
            .then(res => {
                setBelongsToLibrary(res === 'true')
            })
    }, [])

    return <div className='Book'>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {contentLoading && <PreloaderText />}

        {!contentLoading && <>
            <div className='posterWrap'>
                <ContentImage src="poster" image={content.poster} className="poster" />
            </div>

            <div className='details'>
                {!isMobile && <Genres configs={configs} content={content}/>}

                {!contentLoading && <>
                    {content.author && <div className={`labelWrap author`}>
                        <span className="label">Автор:</span>
                        <span className="labelValue">
                            {content.author.join(', ')}
                        </span>
                    </div>}

                    <div className={`labelWrap region`}>
                        <span className="label">Район:</span>
                        <span className="labelValue">
                            <FilterChip
                                className="textOnly"
                                value={content.region}
                                config={configs.content.nodes['book'].fields.region}
                            />
                        </span>
                    </div>

                    {content?.condition && <div className={`labelWrap condition`}>
                        <span className="label">Стан:</span>
                        <span className="labelValue">
                            {content.condition.join(', ')}
                        </span>
                    </div>}

                    {content?.goodreads && <div className={`labelWrap goodreads`}>
                        <span className="label">GoodReads:</span>
                        <span className="labelValue">{content?.goodreads}<span className="max">/5</span></span>
                    </div>}

                </>}

                {!isMobile && <ShowActions content={content} belongsToLibrary={belongsToLibrary} onGetBook={handleGetBookOpen} onReturnBook={handleReturnBookOpen} />}
            </div>

            {isMobile && <ShowActions content={content} belongsToLibrary={belongsToLibrary} onGetBook={handleGetBookOpen} onReturnBook={handleReturnBookOpen} />}

            {/* GET BOOK */}
            <AdaptiveDialog open={openGetBook} onClose={handleGetBookClose} title={`Взяти книгу`}>
                <div className="BookDialog">
                    <Collapse in={requestInProgress}>
                        <div className="progressBlock">
                            <Preloader />
                        </div>
                    </Collapse>
   
                    <Collapse in={!requestInProgress && showRulesStep}>
                        <RulesStep
                            content={content}
                            belongsToLibrary={belongsToLibrary}
                            submit={acceptBaseRules}
                        />
                    </Collapse>

                    <Collapse in={personalDataStep}>
                        <PersonDetailsStep
                            submit={submitPersonDetails}
                        />
                    </Collapse>

                    <Collapse in={!requestInProgress && showSanctionsStep}>
                        <SanctionsStep
                            content={content}
                            submit={acceptFinalRules}
                        />
                    </Collapse>

                    <Collapse in={!requestInProgress && depositStep}>
                        <DepositStep
                            belongsToLibrary={belongsToLibrary}
                            content={content}
                            submit={sendDeposit}
                        />
                    </Collapse>

                    <Collapse in={!requestInProgress && finalStep}>
                        <FinalStep
                            belongsToLibrary={belongsToLibrary}
                            content={content}
                            handleGetBookClose={handleGetBookClose}
                        />
                    </Collapse>
                </div>
            </AdaptiveDialog>

            {/* RETURN BOOK */}
            <AdaptiveDialog open={openReturnBook} onClose={handleReturnBookClose} title={`Повернути книгу`}>
                {/* "${content.title}" */}
                <div className="BookDialog">
                    <Collapse in={requestInProgress}>
                        <div className="progressBlock">
                            <Preloader />
                        </div>
                    </Collapse>

                    <Collapse in={!requestInProgress && returnStep}>
                        <DepositReturnStep
                            belongsToLibrary={belongsToLibrary}
                            content={content}
                            submit={returnBookHandler}
                        />
                    </Collapse>

                    <Collapse in={!requestInProgress && finalReturnStep}>
                        <DepositReturnFinalStep
                            belongsToLibrary={belongsToLibrary}
                            content={content}
                            submit={handleReturnBookClose}
                        />
                    </Collapse>
                </div>
            </AdaptiveDialog>

            {isMobile && <Genres configs={configs} content={content}/>}

            {content?.notes && <div className="infoBlock notes alert">
                <InfoOutlinedIcon />
                <span>
                    Примітка власника:
                    &nbsp;
                    {content.notes}
                </span>
            </div>}

            {content.description && <div className='description'><CKEditorContent content={content.description} /></div>}

            <BookDetails content={content}/>

            <ShowLinks content={content} belongsToLibrary={belongsToLibrary}/>

            {!isLibrary(userState?.user) && !hasFullAccess(userState?.user) && <div className="infoBlock">
                <HelpOutlineOutlinedIcon />
                <span>
                    Виникли проблеми або запитання під час користування бібліотекою? Зверніться в <span className="pseudoLink" onClick={supportClickHandle}>службу підтримки</span>
                </span>
            </div>}
        </>}
    </div>
}
