import URLValue from "../URLValue";

import {useConfigStore} from '../../../../Stores/config'

import './index.scss'

export default function URLField(props) {
    const {field, type, content} = props;

    const {configs} = useConfigStore();

    const url = content[field];

    if (!url.link) return <></>

    return <div className='labelWrap'>
        <span className="label">
            {configs.content.nodes[type].fields[field].placeholder}:
        </span>
        <span className="labelValue">
            <URLValue value={url} />
        </span>
    </div>
}
