import { NavLink as RouterLink } from "react-router-dom";

function getShopistory(partner, shop) {
    return `/profile/history/shop/${partner}/${shop}`
}

export default function ShopHistoryLink(props) {
    const {details, title} = props;

    if (!details) return <></>

    return <RouterLink to={getShopistory(details.partner, details.shop)}>{title || 'Відсутня назва'}</RouterLink>
}
