import {Grid} from '@mui/material';
import FilterField from '../../../../../Components/Form/Filter';

export default function Sort(props) {
    const {orderValue, activeOrder, handleSortChange} = props;
    const value = Object.keys(activeOrder)[0];

    return <Grid item xs={8} md={10}>
        <FilterField
            content={{
                'select': value
            }}
            field={{
                id: 'select',
                placeholder: 'Упорядочити',
                values: {
                    values: orderValue
                },
                options: {
                    hasNoEmptyValue: true
                }
            }}
            onChange={handleSortChange}
        />
    </Grid>
}
