import {useEffect, useState} from "react";

import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ClearIcon from '@mui/icons-material/Clear';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';

import {DEFAULT_CHILDREN} from "ultra/const/general";
import {restorePath} from 'ultra/helpers/route';

import FilterButtonWidget from '../FilterButton';
import FiltersLine from '../FiltersLine';

import CalendarButtonWidget from '../CalendarButton';

import {clearFilters} from '../../Helpers/filters';
import {getNumberOfSelectedFilters, getNumberOfSelectedRangeFilters} from '../../Helpers/filters';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import './index.scss'

export default function TitleWidget(props) {
    const {permits, actions, hide, content, config, breadcrumbs, hideFilters, ignoreRangeInFilters} = props

    const navigate = useNavigate();
    const [filterBy, setFilterBy] = useState();
    const [filterByInline, setfilterByInline] = useState();
    const [activeFiltersNumber, setActiveFiltersNumber] = useState(0)
    const [defaultOrder, setDefaultOrder] = useState();

    const {showBackdropPreloader} = useBackdropPreloaderStore()

    useEffect(() => {
        const filterBy = content?.showFilters?.filterBy || config?.showFilters?.filterBy;
        const filterByInline = content?.showFilters?.filterByInline || config?.showFilters?.filterByInline;

        setFilterBy(filterBy);

        setfilterByInline(filterByInline);

        const defaultOrder = config?.defaultOrder || content?.defaultOrder || DEFAULT_CHILDREN.ORDER
        setDefaultOrder(defaultOrder);

        let number = getNumberOfSelectedFilters(content?.showFilters || config?.showFilters, defaultOrder, !isMobile);
        if (ignoreRangeInFilters) number -= getNumberOfSelectedRangeFilters(content?.showFilters || config?.showFilters);

        setActiveFiltersNumber(number);
    }, [config, content])

    const onGoBack = (breadcrumbs) => {
        const url = Object.keys(breadcrumbs).sort();
        const prev = url[url.length - 1];
    
        navigate(restorePath(prev))
    }

    const clearFiltersHandler = () => {
        showBackdropPreloader();
        const url = clearFilters((filterBy || []).concat(filterByInline || []));
        navigate(url.pathname + url.search);
    }

    if (hide) return <></>

    return <div className='NodeMainTitle'>
        <div className={'pageTopTitle' + (!hideFilters ? ' hasFilters' : '') + (content?.title?.length <= 14 ? ' shortTitle' : '')}>
            <div className="pageTopTitleText">
                {breadcrumbs && <ChevronLeftIcon className="goBack" onClick={() => onGoBack(breadcrumbs)} />}
                <h1 className="pageTitle">{content.title}</h1>
            </div>

            {actions}

            {!hideFilters && <div className="filterButtons">
                {!isMobile && filterByInline?.length && 
                    <FiltersLine
                        size="small"
                        filters={filterByInline}
                    />
                }

                {!isMobile && filterBy?.length && 
                    <FiltersLine
                        size="small"
                        filters={filterBy}
                    />
                }

                {!isMobile && (filterBy?.length || filterByInline?.length) &&
                    <Button disabled={!activeFiltersNumber} size="small" variant='outlined' onClick={clearFiltersHandler}>
                        <Badge badgeContent={activeFiltersNumber} color="error">
                            <ClearIcon />
                        </Badge>
                    </Button>
                }

                {isMobile && (config?.showFilters || content?.showFilters)
                    && <FilterButtonWidget
                        children={permits?.children?.read}
                        defaultOrder={defaultOrder}
                        showFilters={config?.showFilters || content?.showFilters}
                    />}

                {isMobile && content._type === 'events' && <CalendarButtonWidget />}
            </div>}
        </div>       
    </div>
}
