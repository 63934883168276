import {NavLink as RouterLink} from "react-router-dom";

export default function URLValue(props) {
    const {value, className} = props;

    // it works with internal navigation
    if (!value?.link) return <></>

    if (value.link.includes('http')) {
        return <a className={`link ${className}`} href={value.link} target="_blank">
            {value.title || value.link}
        </a>
    }

    return <RouterLink className={className} to={value.link}>
        {value.title || value.link}
    </RouterLink>
}

