import Skeleton from '@mui/material/Skeleton';

export default function PreloaderTitleAndBreadcrumbs() {
    return <div style={{marginBottom: '-4.5rem'}}>
        <div className='NodeMainTitle'>
            <div className='pageTopTitle'>
                <div className='pageTopTitleText'>
                    <Skeleton variant="rectangular" width={32} height={32} sx={{marginRight: '.6rem', marginTop: '.2rem', borderRadius: '10px'}} />
                    <Skeleton variant="rectangular" width={'20%'} height={32} sx={{marginTop: '.2rem', marginBottom: '.4rem'}}/>
                </div>
            </div>
        </div>
        <div className='Breadcrumbs'>
            <Skeleton variant="rectangular" width={'30%'} height={22} sx={{marginLeft: '1rem', marginTop: '.4rem'}} />
        </div>
    </div>
}
