import {getClientConfig} from 'ultra/configs/general';
import {patch, get} from './http';

const {api} = getClientConfig(window)

export function isBelongsToLibrary(city, path) {
    let link = `${api}/library/${city}/utils/islibrary${path}`;
    return get(link).promise;
}

export function reserveBook(city, path, readerInfo, deposit) {
    let link = `${api}/library/${city}/reserve${path}`;
    const body = {};
    // TODO: remove readerInfo after prostir release
    if (readerInfo) body.readerInfo = readerInfo;
    if (deposit) body.deposit = deposit;
    return patch(link, body);
}

export function ownerApproveReserveBook(city, path) {
    let link = `${api}/library/${city}/approve${path}`;
    const body = {};
    return patch(link, body);
}

export function reserveCancel(city, path) {
    let link = `${api}/library/${city}/cancel${path}`;
    const body = {};
    return patch(link, body);
}

export function returnBook(city, path) {
    let link = `${api}/library/${city}/returnbook${path}`;
    const body = {};
    return patch(link, body);
}

export function ownerNotApproveReserveBook(city, path) {
    let link = `${api}/library/${city}/cancel${path}`;
    const body = {};
    return patch(link, body);
}

export function ownerApproveReturnBook(city, path) {
    let link = `${api}/library/${city}/approvereturnbook${path}`;
    const body = {};
    return patch(link, body);
}

export function transferBook(city, path) {
    let link = `${api}/library/${city}/transfer${path}`;
    const body = {};
    return patch(link, body);
}

export function getReaderInfo(city, path) {
    let link = `${api}/library/${city}/utils/reader${path}`;
    return get(link);
}

export function queueBook(city, path) {
    let link = `${api}/library/${city}/queue${path}`;
    const body = {};
    return patch(link, body);
}
