import {isMobile} from "react-device-detect";
import React, { useState } from 'react';
import {useLocation} from 'react-router-dom'

import {revertPath} from 'ultra/helpers/route';

import {useConfigStore} from '../../Stores/config';
import Banner from '../Banner';

import Carousel from '../Carousel';

import './index.scss';

export default function BannerCarousel(props) {
    const location = useLocation();
    const {configs} = useConfigStore();
    // const [activeSlide, setActiveSlide] = useState(0);

    const {data} = props;

    if (!data) return <></>

    const {hide, show, banners} = data;
    if (hide && hide.length > 0 && hide.includes(revertPath(location.pathname))) return <></>
    if (show && show.length > 0 && !show.includes(revertPath(location.pathname))) return <></>

    let className = props.className ? 'BannerCarousel ' + props.className : 'BannerCarousel'
    // className += ' active' + activeSlide

    return <div className={className}>
        <Carousel effect={!isMobile ? 'fade' : ''}>
            {banners.map(id => <Banner key={id} data={configs.advertising[id]} />)}
        </Carousel>
    </div>
}