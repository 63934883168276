import WorkerName from '../WorkerName'
import DocumentType from '../DocumentType'
import DocumentHistoryLink from '../../../../../Content/Widgets/DocumentHistoryLink'
import UserHistoryLink from '../../../../../Content/Widgets/UserHistoryLink'

import {useGlobalCustomContext} from '../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../..//Helpers/user';

import DocumentTypeModified from '../DocumentTypeModified'

export default function WorkerDetails(props) {
    const {values, fullAccess} = props;
    const {userState} = useGlobalCustomContext();
    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    if (values.patch) {
        return <>
            <WorkerName details={values.patch}/>
            {values?.details?.title && <> для <DocumentType details={values.details} /> <DocumentHistoryLink onlyText={!fullAccess} details={values.details} city={values._city} /></>}
            {values?.details && !values?.details?._uri && <> для сторінки що зараз не існує</>}
            {values?.details && !isDocumentOwner && fullAccess && <> <> для </> <UserHistoryLink id={values.details.owner} name={values.details.owner_name} /> </>}
        </>
    }
    else {
        return <>
            {values?.details?.title && <><DocumentTypeModified details={values.details} /> <DocumentHistoryLink onlyText={!fullAccess} details={values.details} city={values._city} /></>}
            {values?.details && !values?.details?._uri && <> сторінка, що зараз не існує</>}
            {values?.details && !isDocumentOwner && fullAccess && <> <> для </> <UserHistoryLink id={values.details.owner} name={values.details.owner_name} /> </>}
        </>
    }
}
