import {hasFullAccess} from 'ultra/helpers/auth';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        return <div className="NotificationTitle">Відправлено запит на підтвердження вашого email</div>
    }

    return <div className="NotificationTitle">
        <span>Відправлено запит на підтвердження email </span>
        <UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} />
    </div>
}
