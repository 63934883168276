import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';

import {useLoaderData, useNavigation, useLocation} from "react-router-dom";

import {isMobile} from "react-device-detect";

import Button from '@mui/material/Button';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getCityConfig} from 'ultra/configs/general';
import {isAdmin} from 'ultra/helpers/auth';
import {hasFullAccess} from "ultra/helpers/auth";

import {attachNodeImage} from '../../Helpers/user'; // attachUsersForFields
import {useGlobalCustomContext} from '../../../../Helpers/context';
import {getContent, removeContentUnusedFiles} from '../../Helpers/content';

import {useConfigStore} from '../../../../Stores/config';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';
import {useToasterStore} from '../../../../Stores/toster';

import PreloaderTable from '../../../../Components/PreloaderTable';

import UserNodesList from '../../Components/UserNodesList'
import UserSingeltonesList from '../../Components/UserSingeltonesList'

import TableView from '../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../Content/Widgets/Title';
// import FiltersLine from '../../../Content/Widgets/FiltersLine';
import NodeInlineActions from '../../../Content/Widgets/NodeInlineActions';

import {setPageTitle} from '../../../../Helpers/router';

import {NODE_ACTIONS} from '../../../../Modules/Content/Reducers/node';

import './index.scss';

const TITLE = 'Контент';

function getUserListOfNodesFilters(nodes, config) {
    const city = getClientCity(window) || DEFAULT_CITY

    const result = {};

    if (nodes?.all?.[city]) {
        // Object.keys(nodes?.all).map(city => {
            Object.keys(nodes.all[city]).map(i => {
                result[i] = config[i].title;
            })
        // })
    }

    if (nodes?.singleton?.[city]) {
        // Object.keys(nodes?.singleton).map(city => {
            Object.keys(nodes.singleton[city]).map(i => {
                result[i] = config[i].title;
            })
        // })
    }

    return result;
}

export default () => {
    const rawNodes = useLoaderData();

    const navigation = useNavigation();
    const {configs} = useConfigStore();
    const location = useLocation();

    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {userState} = useGlobalCustomContext();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {showError} = useToasterStore();

    const [city] = useState(getClientCity(window) || DEFAULT_CITY);
    const [nodes, setNodes] = useState();
    const [listOfNodeFilters, setListOfNodeFilters] = useState({});
    const [tableOptions, setTableOptions] = useState();
    const [fields, setFields] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    function getListOfNodes(nodes) {
        const result = []

        Object.keys(nodes).map(node => {
            result.push(node)
        })

        return result;
    }

    useEffect(() => {
        const nodes = getListOfNodes(configs.content.nodes);
        setPermits({
            children: {
                delete: nodes,
                update: nodes,
                create: nodes,
                read: nodes,
                validate: nodes,
            }
        })
    }, [])

    useEffect(() => {
        setListOfNodeFilters(getListOfNodesFilters(configs.content.nodes))

        const fields = attachNodeImage(configs.content.nodes['node'].fields);

        setFields(fields);

        const MobileConfig = {
            visible: ['_node_image', 'title'],
            hidden: ['_type', '_created'],
            noHeaders: true
        }

        const DesktopConfig = {
            visible: ['_node_image', 'title', '_type'],
            hidden: []
        }

        if (hasFullAccess(userState.user)) {
            MobileConfig.hidden.push('_created_by')

            DesktopConfig.visible.push('_created_by')
        }

        DesktopConfig.visible.push('_created')

        setTableOptions(isMobile ? MobileConfig : DesktopConfig)

        updateNodes(rawNodes);
    }, [config])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});

            updateNodes({});

            getContent(city, location.search)
                .promise
                .then(updateNodes)
        }
    }, [nodeState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateNodes(rawNodes);
        }

        setPageTitle(TITLE)
    }, [navigation])

    useEffect(() => {
        const fullAccess = hasFullAccess(userState?.user); //  || isLibrary(userState?.user)

        const userListOfNodesFilters = getUserListOfNodesFilters(userState?.user?.nodes, configs.content.nodes)

        let list = []
        if (userState?.user) {
            if (userState.user.nodes.all) {
                list = Object.keys(userState.user.nodes.all)
            }
            Object.keys(userState.user.nodes.singleton).map(i => {
                if (!list.includes(i)) list.push(i)
            })
        }

        const config = {
            showFilters: {
                filterBy: [
                    {
                        id: 'city',
                        field: {
                            type: "city",
                            list: list
                        }
                    },
                    {
                        id: 'nodes',
                        field: {
                            default: !fullAccess ? 'published' : '',
                            type: "complex",
                            placeholder: !fullAccess ? "Статус" : "Фільтри",
                            options: {
                                hasNoEmptyValue: !fullAccess,
                            },
                            values: {
                                values: !fullAccess ? 
                                    {
                                        published: "Опубліковано",
                                        not_published: "На перевірці",
                                    }
                                    :
                                    {
                                        published: "Опубліковано",
                                        not_published: "Не опубліковано",
                                        not_mine: "Створено іншими користувачами",
                                        my: "Створено мною",
                                    }
                            }
                        }
                    }
                ]
            }
        }

        config.showFilters.filterBy.push(
            {
                id: '_type',
                field: {
                    type: "filter",
                    placeholder: "Тип сторінки",
                    values: {
                        values: fullAccess ?
                            listOfNodeFilters
                            :
                            userListOfNodesFilters
                    }
                }
            }
        )

        setConfig({...config})
    }, [userState?.user, configs])

    function getListOfNodesFilters(nodes) {
        const result = {}

        Object.keys(nodes).map(node => {
            result[node] = nodes[node].title            
        })

        return result;
    }

    function updateNodes(nodes) {
        setNodes(nodes);
    }

    function onRemoveFilesHandle() {
        showBackdropPreloader()
        removeContentUnusedFiles(city)
            .then(() => {
                hideBackdropPreloader();
            })
            .catch((e) => {
                showError({snackbarMessage: e.message});
                hideBackdropPreloader();
            })
    }

    const selectClasses = false

    const content = {title: TITLE}

    const breadcrumbs = {
        '\\': {
            title: getCityConfig(configs, city).city || 'Головна'
        }
    }

    return <div id="Content" className='Content'>
        <div className='AdminContentPage'>
            <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />
            <BreadcrumbsWidget links={breadcrumbs} />

            {(!fields) && <PreloaderTable />}

            {fields && nodes && <TableView
                showPreloader={!nodes?.list}
                content={nodes}
                fields={fields}
                showPagination={true}
                tableOptions={tableOptions}
                actions={(id) => NodeInlineActions({
                    permits: nodes.permits[id],
                    node: nodes.list[id],
                    unfolded: isMobile ? true : false,
                    city,
                    hasFullAccess: hasFullAccess(userState.user)
                })}
                selectClasses={selectClasses}
            />}

            {userState?.user && <Grid container spacing={1}>
                {userState?.user?.nodes?.singleton && Object.keys(userState.user.nodes.singleton).length > 0 && <Grid item xs={12}>
                    <div className='labelWrap labelWrapRow'>
                        <span className='label'>Singleton:</span>
                        <span className='labelValue'>
                            <UserSingeltonesList list={userState.user.nodes.singleton}/>
                        </span>
                    </div>
                </Grid>}
                <Grid item xs={12}>
                    <div className='labelWrap labelWrapRow'>
                        <span className='label'>{TITLE}:</span>
                        <span className='labelValue'>
                            <UserNodesList list={userState?.user?.nodes?.all}/>
                        </span>
                    </div>
                </Grid>
            </Grid>}

            {isAdmin(userState.user)
                &&
                <div className='ContentAdminPanel'>
                    <Button size='small' className='pseudoLink' variant="outlined" startIcon={<DeleteForeverOutlinedIcon />} onClick={onRemoveFilesHandle}>Видалити файли що не використовуються</Button>
                </div>
            }
        </div>
    </div>
}
