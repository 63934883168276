import DocumentHistoryLink from '../../../../../Content/Widgets/DocumentHistoryLink'

import UploadedFileType from '../UploadedFileType';
import DocumentType from '../DocumentType';

export default function FileDetails(props) {
    const {values, fullAccess} = props;

    return <>
        <UploadedFileType file={values.patch}/>
        {values?.details?.title && <> для <DocumentType details={values.details} /> <DocumentHistoryLink onlyText={!fullAccess} details={values.details} city={values._city} /></>}
        {values?.details && !values?.details?._uri && <> для сторінки що зараз не існує</>}
    </>
}
