import React, {useEffect, useState} from 'react';

import {MESSENGER_COLORS} from 'ultra/const/messengers';
import {isEmptyObj} from 'ultra/helpers/utils';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import FormControl from '@mui/material/FormControl';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import './index.scss';

const MESSENGERS = {
    'telegram': TelegramIcon,
    'whatsup': WhatsAppIcon,
}

// ADD:
// 'Viber'
// 'Signal'

export default function Messenger(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    function getMessenger() {
        return (content[field.id] && Object.keys(content[field.id])[0]) || 'telegram'
    }

    const [messengerTypeValue, setMessengerTypeValue] = useState(getMessenger())
    const [numberValue, setNumberValue] = useState('')
    const [labelValue, setLabelValue] = useState('')

    const [showSign, setShowSign] = useState(false)

    function setShowSignBlock(value) {
        if (!value) {
            setLabelValue('');
        }

        setShowSign(value)
    }

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [className, setClassName] = useState('MessengerField inputEmulation'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''));

    useEffect(() => {
        const messenger = getMessenger();

        setMessengerTypeValue(messenger)
        setNumberValue(content[field.id]?.[messenger]?.number)
        if (content[field.id]?.[messenger]?.label) {
            setLabelValue(content[field.id]?.[messenger]?.label)
        }
    }, [content[field.id]])

    useEffect(() => {
        setClassName('MessengerField inputEmulation'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    const onMessengerChange = (e) => {
        const messenger = e.target.value.toLowerCase();
        setMessengerTypeValue(messenger);

        const value = {[messenger]: {number: ''}}
        if (showSign) { value[messenger].label = '' }

        const prevMessenger = getMessenger();
        if (content[field.id]?.[prevMessenger] && !isEmptyObj(content[field.id][prevMessenger])) {
            value[messenger] = content[field.id]?.[prevMessenger];
        }

        onChange({target: {value}})
    }

    const onNumberChange = (e) => {
        setNumberValue(e.target.value)

        const messenger = getMessenger();
        const value = {[messenger]: {number: e.target.value}}
        onChange({target: {value}})
    }

    const onLabelChange = (e) => {
        setLabelValue(e.target.value)

        const messenger = getMessenger();
        const value = {[messenger]: {label: e.target.value}}
        onChange({target: {value}})
    }

    return (<>
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12} className='ContactLabel'>
                    {field.placeholder} {field.required ? '*' : ''}
                </Grid>
                <Grid item xs={12} className='ContactControlWrap'>
                    <FormControl disabled={isDisabled} fullWidth className='ContactMessenger'>
                        <Select
                            value={messengerTypeValue}
                            onChange={onMessengerChange}>
                                {Object.keys(MESSENGERS).map(value => <MenuItem key={value} value={value}>
                                    <div className='messengerOption'>
                                        {React.createElement(MESSENGERS[value], {sx: {color: MESSENGER_COLORS[value]}})}
                                    </div>
                                </MenuItem>)}
                        </Select>
                    </FormControl>

                    <TextField
                        className='ContactNumber'
                        fullWidth
                        disabled={isDisabled}
                        value={numberValue}
                        label="Акаунт або номер"
                        variant="outlined"
                        onChange={onNumberChange}
                    />
                </Grid>
                <Grid item xs={12} className='noMargin'>
                    <div className='fieldExample'>
                        Приклад: +380991234567 або @telegram_account
                    </div>

                    {field?.options?.displayLabelField && numberValue && <div className='addSign'>
                        {showSign ? '-' : '+'} <span className='pseudoLink' onClick={() => {setShowSignBlock(!showSign)}}>{showSign ? 'Приховати' : 'Додати'} підпис</span>
                    </div>}
                </Grid>

                {showSign && <Grid item xs={12}>
                    {field?.options?.displayLabelField && <TextField
                        className='ContactLabel'
                        fullWidth
                        disabled={isDisabled}
                        value={labelValue}
                        label="Підпис"
                        variant="outlined"
                        onChange={onLabelChange}
                    />}
                    {field?.options?.displayLabelField && <div className='fieldExample'>
                        Приклад: Менеджер
                    </div>}
                </Grid>}
            </Grid>
            {field?.notes && <div className='fieldNotes'>
                <p>{field?.notes}</p>
            </div>}
        </div>
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </>)
}
