import React from 'react';

import Fab from '@mui/material/Fab';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {getClientCity} from 'ultra/configs/general'
import {checkNodeAddRequirements} from 'ultra/helpers/content';
import {DEFAULT_CITY} from 'ultra/const/general';

import {useGlobalCustomContext} from '../../../../../../Helpers/context';

import {NODE_ACTIONS} from '../../../../Reducers/node';

import {useToasterStore} from '../../../../../../Stores/toster';
import {useConfigStore} from '../../../../../../Stores/config';

export default function UpdateNodeButton(props) {    
    const {type, disabled, uri, closeAction} = props

    const {nodeDispatch} = useGlobalCustomContext();
    const {showError} = useToasterStore();
    const {userState} = useGlobalCustomContext();
    const {configs} = useConfigStore();

    const handleClick = () => {
        if (disabled) return;
        
        const addRequirements = checkNodeAddRequirements(configs?.content?.nodes[type].requirements, {user: userState?.user})
        if (addRequirements?.length > 0) {
            
            showError({snackbarMessage: <div>
                <p>Для редагування необхідно:</p>
                <ul>
                    {addRequirements.map(r => <li>{r}</li>)}
                </ul>
            </div>})
            
            return;
        }

        closeAction();

        const city = getClientCity(window) || DEFAULT_CITY;
        nodeDispatch({type: NODE_ACTIONS.EDIT, data: {city, uri}});
    };

    return <>
        <Fab
            className='updateContentIcon'
            color="neutral"
            onClick={handleClick}>
                <EditOutlinedIcon />
        </Fab>
    </>
}
