import Skeleton from '@mui/material/Skeleton';

export default function PreloaderText() {
    return <div className='PreloaderText'>
        <p>
            <Skeleton variant="rectangular" width={'80%'} height={14} />
        </p>
        <p>
            <Skeleton variant="rectangular" width={'40%'} height={14} />
        </p>
        <p>
            <Skeleton variant="rectangular" width={'92%'} height={14} />
        </p>
        <p>
            <Skeleton variant="rectangular" width={'70%'} height={14} />
        </p>
        <p>
            <Skeleton variant="rectangular" width={'86%'} height={14} />
        </p>
        <p>
            <Skeleton variant="rectangular" width={'60%'} height={14} />
        </p>
    </div>
}
