import {hasFullAccess} from 'ultra/helpers/auth';
import {isTgID} from 'ultra/helpers/telegram';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';
import BookDetails from '../../../Components/BookDetails'
import MessageLink from '../../../../MessageLink';

export default function Template(props) {
    const {values, short} = props; // code

    const {userState} = useGlobalCustomContext();

    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        return <>
            <div className="NotificationTitle">
                <>Ви передали </>
                <BookDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
            </div>

            {!short && values?.details?.owner_messenger && <div className="NotificationActions">
                <MessageLink
                    variant="button"
                    size="small"
                    label="Написати в бібліотеку"
                    messenger={values.details.owner_messenger}
                    id={values?._init_by}
                />
            </div>}
        </>
    }
    
    return <>
        <div className="NotificationTitle">
            {isTgID(values?._init_by) && <>Телеграм аккаунт </>}
            {!isTgID(values?._init_by) && <>Аккаунт </>}
            <UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} />
            <> передав до бібліотеки </>
            <BookDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
        </div>

        {!short && values?._init_by_messenger && <div className="NotificationActions">
            <MessageLink
                variant="button"
                size="small"
                label="Написати"
                messenger={values._init_by_messenger}
                id={values?._init_by}
            />
        </div>}
    </>
}
