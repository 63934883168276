import {useEffect, useState} from 'react';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {timestampToDate, getFormatedTime} from 'ultra/helpers/date'

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import Links from '../../../../../../Widgets/Links'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'

import './index.scss'

export default function Template(props) {
    const {content, config, permits, children, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()
    
    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    return <div className='WikiNote'>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <CKEditorContent
            showSkeleton={contentLoading}
            content={content.description}
        />

        {children && <Links
            showSkeleton={contentLoading}
            links={children}

            order={config.childrenOrder || content.childrenOrder}
        />}

        {content.description && <div className='lastUpdated'>Сторінку оновлено: {getFormatedTime(timestampToDate(content._updated), timeZone)}</div>}
    </div>
}
