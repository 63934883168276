import {useState} from 'react';

import {isEmptyObj} from 'ultra/helpers/utils';

import Button from '@mui/material/Button';

import {attachUserData} from 'ultra/helpers/auth';
import {getClientCity} from 'ultra/configs/general';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {isYoursAccount} from '../../../../Helpers/user';

import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';
import FormContainer from '../../../../Components/Form/FormContainer'

import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import './index.scss'

export default function NetworkingRequest(props) {
    const {id} = props;

    const {userState} = useGlobalCustomContext();

    const {userDispatch} = useGlobalCustomContext();

    const {showPromt, closeToaster} = useToasterStore();
    const {hideBackdropPreloader} = useBackdropPreloaderStore();

    const [formConfig, setFormConfig] = useState();
    const [openRequestDialog, setOpenRequestDialog] = useState(false); 

    function onRequestDialogClose() {
        setOpenRequestDialog(false);
    }

    const attachDefaultValues = () => {
        const city = getClientCity(window);

        const config = {
            fields: {
                messenger: {
                    default: 'template:user.messenger',
                    type: 'messenger',
                    placeholder: 'Як з вами звязатись?',
                    required: true,
                },
                details: {
                    type: 'textarea',
                    placeholder: 'Опишіть детальніше, що вас цікавить',
                    required: true,
                }
            },
            form: {
                endpoint: `/networking/request/${city}/${id}`,
                submitText: "Відправити"
            }
        }

        const defaultValues = {}
    
        Object.keys(config.fields).map(field => {
          if (config.fields[field].default) {
            const defVal = attachUserData(config.fields[field].default, userState?.user)
    
            if (defVal) {
              defaultValues[field] = defVal
            }
          }
        })

        config.defaultValues = defaultValues
    
        setFormConfig(config);
      }

    async function onRequest(e) {
        e.stopPropagation()

        if (!userState?.user || isEmptyObj(userState?.user)) {
            showPromt({
                snackbarMessage: 'Щоб мати можливість відправити заявку вам потрібно авторизуватись',
                onApproveHandler: () => {
                    closeToaster();
                    hideBackdropPreloader();

                    userDispatch({type: USER_ACTIONS.LOGIN})
                },
                onCloseHandler: () => {
                    closeToaster();
                    hideBackdropPreloader();
                }
            })
        } else {
            attachDefaultValues();
            setOpenRequestDialog(true);
        }
    }

    return <>
        {isYoursAccount(userState, id) && <span>
            <Button
                variant="contained"
                fullWidth
                className="UserMessageLink"
                disabled
            >
                Це ваша анкета
            </Button>
        </span>}
        {!isYoursAccount(userState, id) && <Button
            variant="contained"
            fullWidth
            className="UserMessageLink"
            onClick={onRequest}
        >
            Відправити заявку
        </Button>}
        <AdaptiveDialog open={openRequestDialog} onClose={onRequestDialogClose} title="Відправити запит">
            <div className='RequestDialog'>
                <div className='RequestDialogDetails'>
                    <p>Ваша заявка буде відправлена автору анкети. Будь ласка, вкажіть необхідну інформацію та очікуйте на відповідь за вказаними контактами</p>
                </div>

                <FormContainer
                    // content={formContent}
                    config={formConfig}
                    afterSubmit={() => {
                        // nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                        hideBackdropPreloader()
                        onRequestDialogClose()
                    }}
                />
            </div>
        </AdaptiveDialog>
    </>
}
