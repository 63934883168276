import './index.scss';

export default function TitleWidget(props) {
    const {label, children} = props;

    return <span className="zoomTextWrapper pseudoLink">
        {label}
        {children}
    </span>
}
