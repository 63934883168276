import {useConfigStore} from '../../../../../../Stores/config';

export default function DocumentType(props) {
    const {details} = props;
    const {configs} = useConfigStore();

    if (!details) return <></>

    if (!details.type) return <></>

    return <> {configs?.content?.nodes[details.type]?.title.toLowerCase()} </>
}
