import {getClientConfig} from 'ultra/configs/general';
 
import {get, post} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function getHistory(search) {
    let link = `${api}/history/list`;
    if (search) link += search
    return get(link);
}

export function removeAllHistory() {
    let link = `${api}/history/flush`;
    return post(link);
}

export function getDocumentHistory(city, did, search) {
    let link = `${api}/history/node/${city}/${did}`;
    if (search) link += search
    return get(link);
}

export function getFormHistory(partner, form, search) {
    let link = `${api}/history/form/${partner}/${form}`;
    if (search) link += search
    return get(link);
}

export function getFormOrderHistory(partner, form, order, search) {
    let link = `${api}/history/form/${partner}/${form}/${order}`;
    if (search) link += search
    return get(link);
}

export function getShopHistory(partner, shop, search) {
    let link = `${api}/history/shop/${partner}/${shop}`;
    if (search) link += search
    return get(link);
}

export function getShopOrderHistory(partner, shop, order, search) {
    let link = `${api}/history/shop/${partner}/${shop}/${order}`;
    if (search) link += search
    return get(link);
}

export function restoreDocument(city, did, params) {
    return post(`${api}/content/${city}/utils/restore/${did}`, params);
}

export function flushUsersHistory() {
    let link = `${api}/history/user/flush`;
    return post(link);
}

export function cleanUsersHistory(id) {
    let link = `${api}/history/user/clean/${id}`;
    return post(link);
}

export function flushNodesHistory() {
    let link = `${api}/history/content/flush`;
    return post(link);
}

export function cleanNodeHistory(city, did) {
    let link = `${api}/history/content/clean/${city}/${did}`;;
    return post(link);    
}
