import {useState, useEffect} from 'react';
// import {NOTIFY_INSURANCE} from "ultra/const/log";

import RequestDetails from '../../../Components/RequestDetails';
// import Chip from '@mui/material/Chip';
// import RequestTitle from '../../../Components/RequestTitle';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';

export default function Template(props) {
    const {code, values, short} = props;

    const [title, setState] = useState()

    const {userState} = useGlobalCustomContext();

    function getNames(clients) {
        return Object.keys(clients).map(i => clients[i].name).join(', ');
    }

    useEffect(() => {
        // {code === NOTIFY_INSURANCE.REQUEST && <>Відправлено заявку на страхування {values.details.partner}</>}
        // {code === NOTIFY_INSURANCE.SUPPORT && <>Відправлено заявку на підтримку</>}

        const getDetails = () => {
            // if (!values.details.data) return 'Деталі відстуні'
            if (values.details.data)
                return <>Страхування на ${values.details.data.order.days} днів (${values.details.data.order.from} - ${values.details.data.order.to}) для ${getNames(values.details.data.clients)}</>;
            else
                return <>Заявка на <a href={`/profile/history/form/${values.details.partner}/${values.details.form}`}>страхування</a></>
        }

        setState(isYoursAccount(userState, values._init_by) ? 'Ви зробили нове замовлення' : getDetails())
    }, [])

    return <>
        <div className="NotificationTitle">
            <div>{title}</div>
            <div className='NotificationSubitle'>#{values.details._oid}</div>
        </div>
        {!short && <div className="NotificationActions">
            {/* <div className="RequestTitle"></div> */}
            {/* <div>{values.details.result}</div> */}
            {/* <Chip variant="outlined" label="Статус" /> */}
            <RequestDetails values={values} details={values.details.data_text}/>
        </div>}
    </>
}
