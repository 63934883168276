import {hasFullAccess} from 'ultra/helpers/auth';

import {restorePath} from 'ultra/helpers/route'
import {NavLink as RouterLink} from "react-router-dom";

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';

import NetworkingDetails from '../../../Components/NetworkingDetails';
import UserHistoryLink from '../../../../../../Content/Widgets/UserHistoryLink'

import MessageLink from '../../../../MessageLink';

export default function Template(props) {
    const {values, short} = props;

    const {userState} = useGlobalCustomContext();

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if(isYoursAccount(userState, values?.details?.id)) {
        return <>
            <div className="NotificationTitle">
                Ви отримали запит
            </div>
            {!short && <>
                <NetworkingDetails details={values?.details} />
                {!short && values?.details?.messenger && <div className="NotificationActions">
                    <MessageLink
                        variant="button"
                        size="small"
                        label="Відповісти"
                        messenger = {values.details.messenger}
                        id={values?.details?.id}
                    />
                </div>}
            </>}
        </>
    }

    if (initByCurrentUser) {
        return <div className="NotificationTitle">
            <span className="labelWrap labeWrapInline">
                {/* {short && <span className="label">Ви:</span>} */}
                <span className="labelValue">Ви відправили запит </span>
            </span>
            {!short && <div>
                <RouterLink to={restorePath(values.details.networking._uri)}>{values.details.networking.title}</RouterLink>
            </div>}
        </div>
    }

    return <div className="NotificationTitle">
        <UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} />
        <> відправив запит на нетворкінг анкету </>
        <RouterLink to={restorePath(values.details.networking._uri)}>{values.details.networking.title}</RouterLink>
    </div>
}
