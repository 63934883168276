import {useEffect, useState} from 'react';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {timestampToDate, getFormatedTime} from 'ultra/helpers/date'

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import URLValue from '../../../../../../Widgets/URLValue'
import Gallery from '../../../../../../Widgets/Gallery';

import './index.scss'

export default function Template(props) {
    const {content, config, permits, breadcrumbs} = props
    const {contentLoading} = useNavigationStore()

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    return <div className='NewsPage'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <div className='NewsPageCreated'>{getFormatedTime(timestampToDate(content._created, timeZone))}</div>

        <CKEditorContent
            showSkeleton={contentLoading}
            content={content.description}
        />

        <Gallery content={content.gallery} />

        {content.source && <div className='labelWrap labelWrapRow'>
            <span className="label">Джерело:</span>
            <span className="labelValue">
                <URLValue value={content.source} />
            </span>
        </div>}
    </div>
}
