import {useNavigation} from "react-router-dom";
import React, {useState, useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import FilterSelector from '../FilterSelector';

import {getActiveFilterFromURL, updateActiveFilters} from '../../Helpers/filters';

import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import './index.scss'

export default function FiltersLine(props) {
    const {filters} = props;

    const navigate = useNavigate();
    const {state} = useNavigation();

    const [activeFilters, setActiveFilters] = useState([]);

    useEffect(() => {
        const activeFilterFromUrl = getActiveFilterFromURL()
        setActiveFilters(activeFilterFromUrl.filters);
    }, [state])

    const {showBackdropPreloader} = useBackdropPreloaderStore()
    // const {sessionFilters, setSessionFilters, removeSessionFilters} = useFiltersStore()

    const handleFilterChange = (filters) => {
        let url = new URL(window.location.href);

        const {urlShouldBeUpdated, activeFilters} = updateActiveFilters(filters)

        if (activeFilters.length > 0) {
            let filtersToStore = JSON.stringify(activeFilters);
            url.searchParams.set('filters', filtersToStore);
            sessionStorage.setItem("filters", filtersToStore);
        }
        else {
            url.searchParams.delete('filters');
            sessionStorage.removeItem("filters");
        }

        url.searchParams.delete('page');
        url.searchParams.delete('perPage');

        // url.searchParams.delete('ranges');
        // url.searchParams.delete('rangesSort');

        if (urlShouldBeUpdated) {
            showBackdropPreloader();
            navigate(url.pathname + url.search);
        }
    }

    if (!filters) return <></>

    return <div className='FiltersLine'>
        {filters.map(filter =>
            <FilterSelector
                key={filter.id}

                {...props}

                filter={filter}
                activeFilters={activeFilters}
                handleChange={handleFilterChange}
            />
        )}
    </div>
}
