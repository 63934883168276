import React, {useEffect, useState} from 'react';

import {getClientCity, getCityConfig} from 'ultra/configs/general';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';

import {useConfigStore} from '../../../Stores/config';

import Number from '../Number';

import './index.scss';

export default function Price(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const {configs} = useConfigStore();

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    const [cityConfig] = useState(getCityConfig(configs, getClientCity(window)));

    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    useEffect(() => {
        setClassName('Price inputEmulation'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    const [className, setClassName] = useState('Price inputEmulation'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''));

    const onCurrencyChange = (e) => {
        const value = content[field.id] || {};
        value.currency = e.target.value;
        onChange({target: {value}})
    }

    const onValueChange = (e) => {
        const value = content[field.id] || {};
        value.price = e.target.value;
        onChange({target: {value}})
    }

    return <div className={className}>
        <div className='inputLabel'>
            {field.placeholder} {field.required ? '*' : ''}
        </div>

        <Grid container spacing={1}>
            <Grid item xs={8} md={9} className=''>
                <Number
                    disabled={isDisabled}
                    field={{...field, id: 'price', placeholder: undefined}}
                    content={{price: content[field.id]?.price || undefined}}
                    onChange={onValueChange}/>
            </Grid>
            <Grid item xs={4} md={3} className=''>
                <FormControl disabled={isDisabled} fullWidth className=''>
                    <Select
                        disabled={isDisabled}
                        value={content[field.id]?.currency || ''}
                        onChange={onCurrencyChange}>
                            {cityConfig?.currency && <MenuItem key={cityConfig?.currency} value={cityConfig?.currency}>
                                {cityConfig?.currency.toUpperCase()}
                            </MenuItem>}
                            {cityConfig.additionalCurrencies && cityConfig.additionalCurrencies.map(value => <MenuItem key={value} value={value}>
                                {value.toUpperCase()}
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        {field?.notes && <div className='fieldNotes'>
            <p>{field?.notes}</p>
        </div>}
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </div>
}
