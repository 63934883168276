import NodeTextDetails from '../NodeTextDetails';
import {useGlobalCustomContext} from '../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../Helpers/user';

export default function BookDetails(props) {
    const {node, values, fullAccess} = props;
    const {userState} = useGlobalCustomContext();
    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    return <>
        {!values?.details?.title && <> книгу </>}

        <NodeTextDetails node={node} values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />

        {/* {values?.details && !isDocumentOwner && fullAccess && <> <> у </> <UserHistoryLink id={values.details.owner} name={values.details.owner_name} /> </>} */}
    </>
}