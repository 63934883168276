import {Link as RouterLink} from "react-router-dom";
import { Breadcrumbs } from "@mui/material";

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getCityConfig} from 'ultra/configs/general';
import {revertPath, restorePath} from "ultra/helpers/route";

import {useConfigStore} from '../../../../Stores/config'

import './index.scss'

export default function BreadcrumbsWidget(props) {
    const {hide, links, showCurrent, hideRoot} = props
    const {configs} = useConfigStore();

    if (hide) return <></>

    if (showCurrent) {
        links[revertPath(window.location.pathname)] = showCurrent;
    }

    const path = Object.keys(links).sort()
    const city = getClientCity(window) || DEFAULT_CITY;
    const cityConfig = getCityConfig(configs, city);

    return <Breadcrumbs className="Breadcrumbs">
            {!hideRoot && cityConfig && <RouterLink key="/" to="/">{cityConfig.city || 'Головна'}</RouterLink>}

            {path
                .filter(link => Boolean(links[link]) && link !== '\\')
                .map(link =>
                    <RouterLink key={link} to={restorePath(link)}>
                        {links[link].title}
                    </RouterLink>
            )}
        </Breadcrumbs>
}
