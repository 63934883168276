import {getClientConfig} from 'ultra/configs/general';

import {get, post} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function getContent(city, search) {
    let link = `${api}/content/${city}/utils/list`;
    if (search) link += search
    return get(link);
}

export function removeContentUnusedFiles(city, did) {
    let link = `${api}/content/${city}/utils/remove-files`;

    if (did) {
        return post(link, {did});
    }
    else {
        return post(link);
    }
}

