import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import {NavLink as RouterLink} from "react-router-dom";

import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {totalPrice} from "ultra/helpers/shop";
import {attachUserData} from 'ultra/helpers/auth';

import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Fab from '@mui/material/Fab';

import Collapse from '@mui/material/Collapse';

import {useShoppingStore} from '../../../../../../Stores/shopping';
import {useConfigStore} from '../../../../../../Stores/config';

import {getContentShopsConfig} from '../../../../Helpers/shops';
import {useGlobalCustomContext} from '../../../../../../Helpers/context';

import Preloader from '../../../../../../Components/Preloader';
import FormContainer from '../../../../../../Components/Form/FormContainer';
import CurrencyFormated from '../../../../../../Components/CurrencyFormated';
import AdaptiveDialog from '../../../../../../Components/Adaptive/Dialog';

import ShopProductPrice from '../../../ShopProductPrice';
import DefaultImage from '../../../DefaultImage';
import ContentImage from '../../../ContentImage';

import './index.scss'
import PartnerImage from '../../../PartnerImage';

function ShortOrderDescription(props) {
    const {orders} = props;
    if (!orders || orders.length === 0) return null

    return <div className='ShortOrderDescription'>
        <div>Ваше замовлення:</div>
        {orders.map(order => <div key={order.details.id}>
            - <RouterLink to={restorePath(normalizeRoute(order.details._uri))}>{order.details.title}</RouterLink> - {order.amount}
        </div>)}
    </div>
}

export default function ShoppingCardButton(props) {
    const {disabled} = props;
    const {cart} = useShoppingStore()
    const {configs, setConfig} = useConfigStore();
    const [isLoading, setIsLoading] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [shops, setShop] = useState([])

    useEffect(() => {
        let updatedShops = []

        // get stores
        cart.map(item => {
            if (updatedShops.find(i => i.details.id === item.shop.id)) return;

            updatedShops.push({details: {...item.shop}})
        })

        // get items
        cart.map(item => {
            const shopIndex = updatedShops.findIndex(i => i.details.id === item.shop.id)
            updatedShops[shopIndex].items = updatedShops[shopIndex].items || []

            const itemIndex = updatedShops[shopIndex].items.findIndex(i => i.details.id === item.product.id)
            if (itemIndex > -1) {
                updatedShops[shopIndex].items[itemIndex].amount += 1
            } else {
                updatedShops[shopIndex].items.push({details: {...item.product}, amount: 1})
            }
        })

        // sort
        const orderByTitle = (a, b) => a.details?.title?.localeCompare(b.details.title);
        updatedShops = updatedShops.sort(orderByTitle)
        updatedShops.map(i => {
            i.items = [...i.items.sort(orderByTitle)]
        })

        setShop(updatedShops)
    }, [cart])

    useEffect(() => {
        // get shops config, if it's not present
        if (!configs.shop && cart.length > 0) {
            setIsLoading(true)
            getContentShopsConfig()
                .promise
                .then(shopConfig => {
                    const newConfig = {...configs};
                    newConfig.shop = shopConfig;
                    setConfig(newConfig);
                    setIsLoading(false);
                })
        }
    }, [open])

    const handleClose = () => {
        setOpen(false);
        // setOpenOrderFrom(null);
    };

    const handleOpen = (e) => {
        if (disabled) return;

        setOpen(true);
    };

    return <>
        <AdaptiveDialog open={open} onClose={handleClose} title={<><ShoppingCartIcon className='icon' /> Корзина</>}>
            <Cart
                onClose={handleClose}
                isLoading={isLoading}
                configs={configs}
                shops={shops}
            />
        </AdaptiveDialog>

        <Badge className='shoppingCartIconBadge' badgeContent={cart.length} color="error">
            <Fab
                className='shoppingCartIcon'
                color="contrast"
                onClick={handleOpen}>
                    <AddShoppingCartIcon />
            </Fab>
        </Badge>
    </>
}


function Cart(props) {
    const {onClose, isLoading, configs, shops} = props;

    const {cart, addProduct, removeProduct, removeShop, clearCard} = useShoppingStore()

    const [openOrderForm, setOpenOrderFrom] = React.useState(null);
    const [openOrderShop, setOpenOrderShop] = React.useState();

    const {userState} = useGlobalCustomContext();

    const navigate = useNavigate();
    const moreThenOneShop = shops.length > 1

    function onEditHandler() {
        setOpenOrderFrom(null)
    }

    function handleClear() {
        clearCard();
    }

    const handleOrder = (storeId) => {
        setOpenOrderFrom(storeId)
    }

    const handleAdd = (productId, storeId) => {
        const item = cart.find(i => i.product.id === productId && i.shop.id === storeId)
        addProduct(item.product, item.shop);
    }

    const handleRemove = (productId, storeId) => {
        const item = cart.find(i => i.product.id === productId && i.shop.id === storeId)
        removeProduct(item.product)
    }

    const shopTitleClickHendler = (storeId) => {
        setOpenOrderFrom(null)

        if (storeId === openOrderShop) {
            setOpenOrderShop(null)
        } else {
            setOpenOrderShop(storeId)
            setOpenOrderFrom(null)
        }
    }

    const navigateToShopPage = (shop) => {
        // const shop = {...shops.find(i => i.details.id === storeId)};
        // custom nodes (like tours), not shop-product, can have custom link to navigate - did
        if (shop.link) {
            const link = `/?goToDid=${shop.city}:${shop.link}`;
            navigate(link);
            onClose();
            // setOpen(false);
        }
    }

    const navigateToProductPage = (uri) => {
        navigate(restorePath(uri));
        onClose();
        // setOpen(false);
    }

    const afterOrder = () => new Promise(async resolve => {
        if (cart.length === 0) {
            onClose();
            // setOpen(false);
        }

        removeShop(openOrderForm);
        setOpenOrderFrom(null);
        resolve();
    })

    function getTotalPrice(storeId) {
        const shop = {...shops.find(i => i.details.id === storeId)}
        return totalPrice(shop?.items);
    }

    function getItemsForShop(storeId) {
        const shop = {...shops.find(i => i.details.id === storeId)}
        return shop.items
    }

    function getItemsForOrderField(storeId) {
        const data = {order: getItemsForShop(storeId)};
        return data
    }

    function getFormForShopConfig(storeId) {
        const shop = shops.find(i => i.details.id === storeId)
        let result = {
            form:  {
                endpoint: `/form/${shop.details.city}/shop/${shop.details.partner}/${shop.details.id}`,
                snackbarDuration: 10000
            }
        }

        // add partner and shop
        // result.form.endpoint += shop.details._uri;
        result.form.submitText = shop.details?.orderForm?.form?.submitText || configs.shop.form.submitText;
        result.form.successMessage = shop.details?.orderForm?.form?.successMessage || configs.shop.form.successMessage;

        result.fields = shop.details?.orderForm?.fields || configs.shop.fields
        result.fieldsOrder = shop.details?.orderForm?.fieldsOrder || configs.shop.fieldsOrder

        // add order hidden field
        // TODO: use same code used on server side
        result.fields.order = {
            options: {
                hidden: true,
            },
            placeholder: 'Замовлення',
            required: true,
            type: 'json',
        }

        const defaultValues = {}
        Object.keys(result.fields).map(field => {
          if (result.fields[field].default) {
            const defVal = attachUserData(result.fields[field].default, userState?.user)
    
            if (defVal) {
              defaultValues[field] = defVal
            }
          }
        })
        result.defaultValues = defaultValues;

        if (shop?.details?.orderForm?.defaultValues) {
            result.defaultValues = {...result.defaultValues, ...shop.details.orderForm.defaultValues}
        }
        else if (configs.shop.defaultValues) {
            result.defaultValues = {...result.defaultValues, ...configs.shop.defaultValues}
        }

        return result
    }

    const isShopNotCollapsed = (storeId) => {
        if (openOrderForm) {
            return false
        }

        if (!moreThenOneShop) {
            return true
        }

        return openOrderShop === storeId && openOrderForm !== storeId;
    }

    return <div className="Cart">
                {isLoading && <Preloader />}
                {!isLoading && shops.map((store) =>
                    <div className='shopItem' key={store.details.id}>
                        <div className={isShopNotCollapsed(store.details.id) ? 'shopTitle active' : 'shopTitle'}>
                            <div className='titleWrap'>
                                <span className='title'>
                                    <PartnerImage
                                        id={store.details.partner}
                                        shop={store.details.id}
                                        className={`Logo Logo32 ${store.details.link ? 'Clickable' : ''}`}
                                        onClick={() => navigateToShopPage(store.details)}
                                    />
                                    <span className='name' onClick={() => shopTitleClickHendler(store.details.id)}>{store.details.title}</span>
                                </span>
                                {moreThenOneShop && <span onClick={() => shopTitleClickHendler(store.details.id)}>
                                    <KeyboardArrowUpIcon className='icon up' />
                                    <KeyboardArrowDownIcon className='icon down' />
                                </span>}
                            </div>
                        </div>

                        <Collapse in={isShopNotCollapsed(store.details.id)}>
                            <div className='productsList'>
                                {store?.items?.map(product =>
                                <div className='product' key={product.details.id}>
                                    <ContentImage
                                        onClick={() => navigateToProductPage(product.details._uri)}
                                        src="thumbnail"
                                        city={product.details.city}
                                        image={product.details.thumbnail}
                                        className='picture'/>

                                    <div className='title'>
                                        <span onClick={() => navigateToProductPage(product.details._uri)}>
                                            {product.details.title}
                                        </span>
                                    </div>

                                    <div className='price'>
                                        <ShopProductPrice product={product.details} currency={store.details.currency} />
                                    </div>

                                    <div className='quantity'>
                                        <span className='label'>
                                            {store?.details?.order?.itemLabel || 'Кількість'}:
                                        </span>

                                        <RemoveCircleOutlineIcon className='btn' onClick={() => handleRemove(product.details.id, store.details.id)}/>
                                        {product.amount}
                                        <AddCircleOutlineIcon className='btn' onClick={() => handleAdd(product.details.id, store.details.id)}/>
                                    </div>

                                </div>
                                )}
                            </div>

                            <div className='finalPrice'>
                                <span className='price'>
                                    До сплати:
                                    <br/>
                                    <span className="priceValue">
                                        <CurrencyFormated value={getTotalPrice(store.details.id)} currency={store.details.currency} />
                                    </span>
                                </span>

                                <Button
                                    variant="contained"
                                    className='button'
                                    disabled={store.details.id === openOrderForm}
                                    onClick={() => handleOrder(store.details.id)}>
                                        Оформити
                                </Button>
                            </div>
                        </Collapse>

                        <Collapse in={openOrderForm === store.details.id}>
                            {openOrderForm && <div className='FormContainerWrap'>
                                <ShortOrderDescription orders={getItemsForShop(store.details.id)} />
                                <FormContainer
                                    content={getItemsForOrderField(store.details.id)}
                                    config={getFormForShopConfig(store.details.id)}
                                    afterSubmit={afterOrder}/>
                                <div className='editOrder'>
                                    <Button fullWidth
                                        variant="outlined"
                                        onClick={onEditHandler}>
                                            Змінити замовлення
                                    </Button>
                                </div>
                            </div>}
                        </Collapse>
                    </div>
                )}
        <div className='Submit'>
            <Button onClick={handleClear} variant="outlined" fullWidth>Очистити</Button>
        </div>
    </div>
}
