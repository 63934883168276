import React, {useEffect, useState} from 'react';

import {isObject, isArray} from 'lodash';
import {isEmptyObj} from 'ultra/helpers/utils';

import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'

import './index.scss'

export default function JSONEditor(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [hideExample, setHideExample] = useState(true)
    const [showExample, setShowExample] = useState(false)
    useEffect(() => {
      if (field.example) setShowExample(true);
      if (field.example && isArray(value) && value.length > 0) setShowExample(false)
      if (field.example && isObject(value) && !isEmptyObj(value)) setShowExample(false)
    }, [])

    const [value, setValue] = useState(field?.options?.isArray ? [] : {})
    useEffect(() => {
        if (field?.options?.isArray && (!content[field.id] || isEmptyObj(content[field.id]))) {
          setValue([]);
        } else {
          setValue(content[field.id] || {});
        }
    }, [content[field.id]])

    const [isHidden] = useState(field?.options?.hidden)
    if (isHidden) return

    return (<div style={{paddingTop: 0}}>
        <span className='fieldLabel'>
          {field.placeholder} {field.required && <span>*</span>}
        </span>
        <div className={`JSONEditor ${showErrorText ? 'error' : ''} ${isDisabled ? 'disabled' : ''}`}> 
          <div>
              <JsonView
                src={value}
                theme='a11y'
                editable={!isDisabled}
                collapsed={false}
                enableClipboard={false}
                collapseStringsAfterLength={6}
                onEdit={onChange}
                onDelete={onChange}
              />
              {field.example && <div className='additionalFields' onClick={() => {setHideExample(!hideExample)}}>+ <span className='pseudoLink'>Приклад заповнення</span></div>}
          </div>
          {showExample && !hideExample && <div>
              <p className='instruction'>* щоб додати &#123;&#125; або [], потрібно ввести &#40;&#123;&#125;&#41; або ([])</p>
              <div className="exampleJSON">
                <JsonView
                  src={field.example}
                  editable={false}
                  collapsed={false}
                  enableClipboard={false}
                  collapseStringsAfterLength={6}
                />
              </div>
          </div>}

          {showErrorText && <div className='fieldError'>
            {showErrorText}
          </div>}
          <div className='fieldNotes'>
            {field.notes && <p>{field.notes}</p>}
          </div>
        </div>
    </div>)
}
