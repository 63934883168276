import React, {useEffect, useState} from 'react';

import { isBoolean, isString } from 'lodash';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';

import FormHelperText from '@mui/material/FormHelperText';

import './index.scss';

export default function CheckboxInput(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;
    const placeholder = field.required ? `${field.placeholder} *` : field.placeholder;

    const [className, setClassName] = useState()

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [value, setValue] = useState(false)
    useEffect(() => {
      if (isString(content[field.id])) {
        setValue(content[field.id] === 'true' || false);
      }

      if (isBoolean(content[field.id])) {
        setValue(content[field.id]);
      }
    }, [content[field.id]])

    useEffect(() => {
      setClassName(showErrorText ? 'hasError' : '')
    }, [showErrorText])

    return <div className='CheckboxField'>
          <FormGroup className={className}>
            <FormControlLabel
              control={
                <Checkbox disabled={isDisabled} checked={value} onChange={e => {
                  // INFO: it's here, because second paramtere returns value, but onChange expect ID
                  onChange(e)
                }} />
              }
              label={placeholder}
            />
          </FormGroup>
          {showErrorText && <FormHelperText className='FormHelperTextError' error={Boolean(showErrorText)}>{showErrorText}</FormHelperText>}
      </div>
}
