import {Badge} from '@mui/material';
import {useConfigStore} from '../../../../Stores/config';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import Avatar from '../../../Content/Widgets/Avatar';
import TopicAvatar from '../../../Content/Widgets/TopicAvatar';
import PartnerImage from '../../../Content/Widgets/PartnerImage';

import {isYoursAccount} from '../../../../Helpers/user';

import './index.scss';

export default function ConversationAvatar(props) {
    const {conversation, users} = props;

    const {userState} = useGlobalCustomContext();

    const {configs} = useConfigStore();

    if (conversation.notification) {
        const topic = configs.notifications.topics[conversation.topic];

        let img = <></>
        if (topic.partner?.id && topic.partner?.shop) {
            img = <PartnerImage id={topic.partner.id} shop={topic.partner.shop} className="TopicAvatar"/>
        }
        else if (topic.partner?.id && topic.partner?.form) {
            img = <PartnerImage id={topic.partner.id} form={topic.partner.form} className="TopicAvatar"/>
        }
        else if (topic.icon) {
            img = <TopicAvatar icon={topic.icon} />
        }

        return <Badge
                color='primary'
                className='NotificationBadge'
                badgeContent={conversation._not_readed}
            >{img}</Badge>
    }

    else if (conversation.conversation) {
        const images = conversation.participants.filter(i => !isYoursAccount(userState, i)).map(u => users[u].photoURL)

        return <Badge
                color='primary'
                badgeContent={conversation._not_readed}
                className='ConversationBadge'
            >
                <Avatar image={images[0]} className={`${conversation.dialog ? '' : 'TopicAvatar'}`}/>
            </Badge>
    }
}
