import React from 'react';

import {useConfigStore} from '../../../../Stores/config';

export default function UserLibraryList(props) {
    const {list} = props;
    const {configs} = useConfigStore();

    if (!list) return <></>

    return Object.keys(list).map(city =>
        <>
            {configs.content.cities[city].city && <span><i>{configs.content.cities[city].city}:</i> </span>}
            <span>
                {list?.[city]?.owner > 0 && <span>
                    Ваші книги:
                    &nbsp;
                    {list?.[city]?.owner}
                </span>}

                {list?.[city]?.reading > 0 && <span>
                    На прочитані:
                    &nbsp;
                    {list?.[city]?.reading}
                </span>}
            </span>
        </>
    )
}
