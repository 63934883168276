import React from 'react';

import {getClientDefaultImageUrl} from 'ultra/configs/general';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {NODE_ACTIONS} from '../../Reducers/node';
import {USER_ACTIONS} from '../../../Profile/Reducers/user';
import {useConfigStore} from '../../../../Stores/config';

import './index.scss';

export default function Footer() {
    const {nodeDispatch, userDispatch} = useGlobalCustomContext();
    const {configs} = useConfigStore();

    if (!configs) return <></>

    return <div className='Footer'>
        <span className='pseudoLink' onClick={() => {
            nodeDispatch({type: NODE_ACTIONS.POPUP_PAGE, data: configs.links.rules});
            userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
        }}>Правила</span>
        <span className='sep'>|</span>
        <span className='pseudoLink' onClick={() => {
            nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: configs.links.partner});
            userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
        }}>Стати партнером</span>
        <span className='sep'>|</span>
        <span className='pseudoLink' onClick={() => {
            nodeDispatch({type: NODE_ACTIONS.POPUP_FORM, data: configs.links.support});
            userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
        }}>Служба підтримки</span>

        <a className='madeInUkraine' target='_blank' href='https://u24.gov.ua/'>
            Made in Ukraine
            {/* <img className='logo' src={getClientDefaultImageUrl(window, `config/layout/made-in-ukraine.png`)} /> */}
        </a>
    </div>
}
