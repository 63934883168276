import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import LinksTile from '../../../../../../Widgets/LinksTile'
import LinksTileWithCategories from '../../../../../../Widgets/LinksTileWithCategories'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import FilterChip from '../../../../../../Widgets/FilterChip'

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()
    const {configs} = useConfigStore();

    let hasCategories = false
    children.order.map(id => {
        if (hasCategories) return;

        if (children.list[id].category) {
            hasCategories = true;
        }
    })

    return <div className='Affiche'>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <CKEditorContent content={content.description} />

        <TopPagePicture cutHeightForDesktop showSkeleton={contentLoading} image={content.banner} />

        {(!hasCategories && !config.categories) && <LinksTile
            xs={6} md={3}
            imgLoading={config?.templateOptions?.imgLoading}
            
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}

            label={(item) => <FilterChip value={item.region} config={configs.content.nodes[item._type].fields.region} />}
        src="poster"/>}

        {(hasCategories || config.categories) && <LinksTileWithCategories
            xs={6} md={3}
            categoriesConfig={config.categories}
            permits={permits}
            
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}
            src="poster"

            label={(item) => <FilterChip value={item.region} config={configs.content.nodes[item._type].fields.region} />}
        />}
    </div>
}
