import NodeTextDetails from '../NodeTextDetails';
import {useGlobalCustomContext} from '../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../Helpers/user';

export default function NodeDetails(props) {
    const {values, fullAccess} = props;
    const {userState} = useGlobalCustomContext();
    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    return <>
        {!values?.details?.title && <> сторінку </>}

        <NodeTextDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />

        {/* {values?.details && !isDocumentOwner && fullAccess && <> <> для </> <UserHistoryLink id={values.details.owner} name={values.details.owner_name} /> </>} */}
    </>
}
