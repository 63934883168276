var isString = require('lodash').isString;
export var isTgID = function (id) {
    if (!id)
        return false;
    if (isString(id)) {
        return id.indexOf("tg:") === 0;
    }
    return false;
};
export var formatTgID = function (id) {
    return "tg:" + id;
};
export var getTgID = function (id) {
    if (isTgID(id)) {
        return id.split("tg:")[1];
    }
    return null;
};
export var getUserProfilePhotos = function (fetch, bot_token, user_id, offset, limit) {
    var params = { "user_id": user_id };
    if (offset)
        params.offset = offset;
    if (limit)
        params.limit = limit;
    return new Promise(function (resolve, reject) {
        fetch("https://api.telegram.org/bot" + bot_token + "/getUserProfilePhotos", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "cache-control": "no-cache"
            },
            body: JSON.stringify(params)
        })
            .then(function (res) { return res.json(); })
            .then(function (res) {
            res.ok ? resolve(res) : reject(res);
        });
    });
};
export var getFile = function (fetch, bot_token, file_id) {
    var params = { "file_id": file_id };
    return new Promise(function (resolve, reject) {
        fetch("https://api.telegram.org/bot" + bot_token + "/getFile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "cache-control": "no-cache"
            },
            body: JSON.stringify(params)
        })
            .then(function (res) { return res.json(); })
            .then(function (res) {
            res.ok ? resolve(res) : reject(res);
        });
    });
};
export var sendMessage = function (fetch, bot_token, tg_chat_id, message) {
    return new Promise(function (resolve, reject) {
        fetch("https://api.telegram.org/bot" + bot_token + "/sendMessage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "cache-control": "no-cache"
            },
            body: JSON.stringify({
                "disable_web_page_preview": true,
                "chat_id": tg_chat_id,
                "text": message,
                "parse_mode": 'HTML',
            })
        })
            .then(function (res) { return res.json(); })
            .then(function (res) {
            res.ok ? resolve() : reject(res);
        });
    });
};
export var sendPhoto = function (fetch, FormData, bot_token, tg_chat_id, file) {
    var body = new FormData();
    body.append('photo', file);
    body.append('chat_id', tg_chat_id);
    return new Promise(function (resolve, reject) {
        fetch("https://api.telegram.org/bot".concat(bot_token, "/sendPhoto"), { method: "POST", body: body })
            .then(function (res) { return res.json(); })
            .then(function (res) {
            res.ok ? resolve() : reject(res);
        });
    });
};
