import DefaultImage from '../DefaultImage';

import './index.scss';

export default function ShopCategoryLabel(props) {
    const {categories, category} = props;

    if (!category) return <></>
    if (!categories) return <></>

    const value = categories.values[category];
    if (!value) return <></>

    const img = categories?.images?.[category];

    return <div className="ShopCategoryLabel">
        {img && <DefaultImage image={img} className="img" />}
        {value}
    </div>
}
