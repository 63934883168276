export default function BookDetails(props) {
    const {content} = props;

    return <div className="bookDetails">
        {content.original && <div className={`labelWrap labelWrapRow original`}>
            <span className="label">Оригінальна назва:</span>
            <span className="labelValue">
                {content.original}
            </span>
        </div>}

        {content?.publisher && <div className={`labelWrap labelWrapRow publisher`}>
            <span className="label">Видавництво:</span>
            <span className="labelValue">{content?.publisher}</span>
        </div>}

        {content?.format && <div className={`labelWrap format`}>
            <span className="label">Формат:</span>
            <span className="labelValue">{content?.format}</span>
        </div>}

        {content?.year && <div className={`labelWrap year`}>
            <span className="label">Рік видання:</span>
            <span className="labelValue">{content?.year}</span>
        </div>}

        {content?.pages && <div className={`labelWrap pages`}>
            <span className="label">Сторінок:</span>
            <span className="labelValue">{content?.pages}</span>
        </div>}
    </div>
}
