import React, {useEffect, useState} from 'react';

import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';

import {HISTORY_NODE} from "ultra/const/log";

import {useGlobalCustomContext} from '../../../../Helpers/context';

import InlineActions from '../../../Content/Widgets/InlineActions';

import {NODE_ACTIONS} from '../../../Content/Reducers/node';

import './index.scss';

export default function Render(options) {
    const actions = getActions(options);
    if (actions.length === 0) return null;
    return <NodeHistoryInlineActions {...options} actions={actions} />;
}

function getActions(options) {
    const {content, isRemoved} = options;

    const actions = [];

    if (content) {
        let hasRestoreNodeAction

        if (content._code === HISTORY_NODE.REMOVE && isRemoved) {
            hasRestoreNodeAction = true
        }

        if (content._code === HISTORY_NODE.WORKER_REMOVE && !isRemoved) {
            hasRestoreNodeAction = true
        }

        if (hasRestoreNodeAction) {
            actions.push('check')
        }
    }
    return actions;
}

function NodeHistoryInlineActions(props) {
    const {actions, content, unfolded, direction, className} = props;
    const {nodeDispatch} = useGlobalCustomContext();

    const [list, setList] = useState([])

    useEffect(() => {
        const list = actions.map(id => {
            let result;
            switch(id) {
                case 'check':
                    result = {
                        id: 'check',
                        icon: <SettingsBackupRestoreOutlinedIcon className='Icon' />,
                        onClick: () => {
                            nodeDispatch({type: NODE_ACTIONS.RESTORE, data: {
                                date: content._timestamp,
                                action: content._code,
                                user: content._init_by,
                            }})
                        }
                    }
                    break;
            }
            return result;
        });
        setList(list);
    }, [])

    return <InlineActions actions={list} className={className} direction={direction} unfolded={unfolded} />
}
