import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export default function Email(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    // const [value, setValue] = useState('')
    // useEffect(() => {
    //     setValue(content[field.id] || '')
    // }, [content[field.id]])

    return (<>
        <TextField
            error={Boolean(showErrorText)}
            helperText={showErrorText}
            fullWidth
            value={content[field.id] || ''}
            required={field.required}
            label={field.placeholder}
            variant='outlined'
            type='email'
            onChange={onChange}
            disabled={isDisabled}
            inputProps={{maxLength: field?.options?.maxLength}}
        />
        <div className='fieldNotes'>
            {field?.options?.maxLength && <p>* максимальна кількість символів - {field?.options?.maxLength}</p>}
        </div>
    </>)
}
