import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {cleanNodeHistory} from '../../../Profile/Helpers/history';
import {NODE_ACTIONS} from '../../Reducers/node';
import {useToasterStore} from '../../../../Stores/toster';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import InlineActions from '../InlineActions';

import './index.scss';

export default function Render(options) {
    const actions = getActions(options);
    if (actions.length === 0) return null;
    return <NodeInlineActions {...options} actions={actions} />;
}

function getActions(options) {
    const {permits, node, hideHistory, showCleanHistory, hasFullAccess} = options;

    const hasEditAction = permits?.update;
    const hasValidateAction = permits?.validate;
    const hasDeleteAction = permits?.delete;

    const actions = [];

    if (hasEditAction) {
        actions.push('edit')
    }

    if (hasValidateAction && node._published) {
        actions.push('unvalidate')
    }

    if (hasValidateAction && !node._published) {
        actions.push('validate')
    }

    if (hasDeleteAction) {
        actions.push('delete')
    }

    if (!hideHistory && hasFullAccess && node?._did) {
        actions.push('history')
    }

    if (showCleanHistory && hasFullAccess && node._did) {
        actions.push('clean')
    }

    return actions;
}

function NodeInlineActions(props) {
    const {actions, permits, node, hideHistory, className, unfolded, direction, city} = props;

    const navigate = useNavigate();
    const {nodeDispatch} = useGlobalCustomContext();
    const {showPromt, closeToaster} = useToasterStore();
    const {hideBackdropPreloader} = useBackdropPreloaderStore()

    const [list, setList] = useState([])

    useEffect(() => {
        const list = actions.map(id => {
            let result;
            switch(id) {
                case 'edit':
                    result = {
                        id: 'edit',
                        icon: <EditOutlinedIcon />,
                        onClick: () => {
                            nodeDispatch({type: NODE_ACTIONS.EDIT, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'unvalidate':
                    result = {
                        id: 'unvalidate',
                        icon: <UnpublishedOutlinedIcon className='Icon errorIcon' />,
                        onClick: () => {
                            nodeDispatch({type: NODE_ACTIONS.UNVALIDATE, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'validate':
                    result = {
                        id: 'validate',
                        icon: <CheckCircleOutlinedIcon className='Icon successIcon' />,
                        onClick: () => {
                            nodeDispatch({type: NODE_ACTIONS.VALIDATE, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'delete':
                    result = {
                        id: 'delete',
                        icon: <DeleteOutlineIcon className='Icon errorIcon' />,
                        onClick: () => {
                            nodeDispatch({type: NODE_ACTIONS.DELETE_START, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'history':
                    result = {
                        id: 'history',
                        icon: <HistoryOutlinedIcon className='Icon' />,
                        onClick: () => {
                            navigate(`/profile/history/node/${city}/${node._did}`)
                        }
                    }
                    break;

                case 'clean':
                    result = {
                        id: 'clean',
                        icon: <CleaningServicesIcon className='Icon errorIcon' />,
                        onClick: () => {
                            showPromt({
                                snackbarMessage: 'Ви бажаєте очистити історію і видалити всі файли з корзини?',
                                snackbarDuration: 10000,
                                onApproveHandler: () => {
                                    closeToaster();
            
                                    cleanNodeHistory(city, node._did)
                                        .then(() => {
                                            hideBackdropPreloader();
                                            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                                        })
                                        .catch(() => {
                                            hideBackdropPreloader();
                                        })
                                },
                            })
                        }
                    }
                    break;
            }
            return result;
        });
        setList(list);
    }, [node, permits, hideHistory])

    if (!node) return <></>

    return <InlineActions actions={list} className={className} unfolded={unfolded} direction={direction} />
}
