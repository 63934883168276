import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export default function Path(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [regexpHighlight, setRegexpHighlight] = useState(false)

    // const [value, setValue] = useState('')
    // useEffect(() => {
    //     setValue(content[field.id] || '')
    // }, [content[field.id]])

    const onKeyDownHandler = (e) => {
        if (!field?.options?.regexp) return false;

        const regex = new RegExp(field.options.regexp);
        const isBackspace = e.keyCode === 8;
        const isArrow = e.keyCode >= 37 && e.keyCode <=40 ;
        
        if (!isArrow && !isBackspace && !regex.test(e.key)) {
            e.preventDefault();
            setRegexpHighlight(true);
        }
        else {
            setRegexpHighlight(false);
        }
    }

    return (<>
        <TextField
            error={Boolean(showErrorText)}
            helperText={showErrorText}
            fullWidth
            value={content[field.id] || ''}
            required={field.required}
            label={field.placeholder}
            variant="outlined"
            onKeyDown={onKeyDownHandler}
            onChange={onChange}
            disabled={isDisabled}
            inputProps={{maxLength: field?.options?.maxLength}}
        />
        <div className={`fieldNotes ${regexpHighlight ? 'fieldNotesRegexpError' : 'fieldNotes'}`}>
            {field?.options?.maxLength && <p>* максимальна кількість символів - {field?.options?.maxLength}</p>}
        </div>
    </>)
}
