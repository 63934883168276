import Grid from '@mui/material/Grid';
import {getShopDetails} from 'ultra/helpers/shop';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import Links from '../../../../../../Widgets/Links'
import ShopingCart from '../../../../../../Widgets/ShopingCart'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import ShopProductPrice from '../../../../../../Widgets/ShopProductPrice'
import FilterChip from '../../../../../../Widgets/FilterChip'

import './index.scss'

export default function Template(props) {
    const {content, permits, config, children, breadcrumbs} = props

    const {configs} = useConfigStore();
    const {contentLoading} = useNavigationStore()

    const {shop} = getShopDetails(content, breadcrumbs)

    return <div className="Product">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <div className='photoWrap'>
                    <ContentImage src="thumbnail" image={content.thumbnail} className="photo" />
                    {content.status && <div className="photoLabel">
                        <FilterChip value={content.status} config={configs.content.nodes['shop-product'].fields.status} />
                    </div>}
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <ShopProductPrice product={content} currency={shop?.partner?.config?.currency} />

                <ShopingCart
                    item={content}
                    shop={shop}
                    fullWidth
                />

                {!content.available && <div className='notAvailable'>Тимчасово недоступний</div>}

                <CKEditorContent content={content.description} />
            </Grid>
        </Grid>

        {/* <Links
            links={children}
            order={config.childrenOrder || content.childrenOrder}
        /> */}
    </div>
}
