import {getShopDetails} from 'ultra/helpers/shop';

import PreloaderTopPagePicture from '../../../../../../../../Components/PreloaderTopPagePicture';
import PreloaderText from '../../../../../../../../Components/PreloaderText';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'

import './index.scss'

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    if (contentLoading) return <>
        <PreloaderTopPagePicture />
        <PreloaderText />
    </>

    return <div className="Activity">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {content.ctitical && <p><i>{content.ctitical}</i></p>}
        {content.analogue && <p className='labelWrap'>
            <span className="label">Індонезійський аналог:</span>
            <span className="labelValue">{content.analogue}</span>
        </p>}

        {content.active_substances && <p className='labelWrap'>
            <span className="label">Діючі речовини:</span>
            <span className="labelValue">
                {content.active_substances}
            </span>
        </p>}
        {content.international && <p className='labelWrap'>
            <span className="label">Міжнародна назва:</span>
            <span className="labelValue">
                {content.international}
            </span>
        </p>}
    </div>
}
