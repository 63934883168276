export var countries = [
    "Афганістан", "Албанія", "Алжир", "Андорра", "Ангола", "Ангілья", "Антарктида", "Аргентина", "Вірменія",
    "Аруба", "Австралія", "Австрія", "Азербайджан", "Багамські острови", "Бахрейн", "Бангладеш", "Барбадос",
    "Білорусь", "Бельгія", "Беліз", "Бенін", "Бермудські острови", "Бутан", "Болівія", "Боснія і Герцеговина",
    "Ботсвана", "Бразилія", "Бруней-Даруссалам", "Болгарія", "Буркіна-Фасо", "Бурунді", "Камбоджа", "Камерун",
    "Канада", "Кабо-Верде", "Кайманові острови", "Центральноафриканська Республіка", "Чад", "Чилі", "Китай",
    "Острів Різдва", "Колумбія", "Коморські острови", "Конго", "Острови Кука", "Коста-Ріка", "Кот-д'Івуар",
    "Хорватія", "Куба", "Кюрасао", "Кіпр", "Чеська Республіка", "Данія", "Джибуті", "Домініка",
    "Домініканська республіка", "Еквадор", "Єгипет", "Сальвадор", "Екваторіальна Гвінея",
    "Еритрея", "Естонія", "Ефіопія", "Фарерські острови", "Фіджі", "Фінляндія", "Франція", "Габон", "Гамбія",
    "Грузія", "Німеччина", "Гана", "Гібралтар", "Греція", "Гренландія", "Гренада", "Гваделупа", "Гуам",
    "Гватемала", "Гернсі", "Гвінея", "Гвінея-Бісау", "Гайана", "Гаїті", "Ватикан", "Гондурас", "Гонконг",
    "Угорщина", "Ісландія", "Індія", "Індонезія", "Іран, Ісламська Республіка", "Ірак", "Ірландія",
    "Молдова", "Ізраїль", "Італія", "Ямайка", "Японія", "Джерсі", "Йорданія", "Казахстан", "Кенія",
    "Кірібаті", "Південна Корея", "Косово", "Кувейт", "Киргизстан", "Лаос", "Латвія", "Ліван", "Лесото",
    "Ліберія", "Лівія", "Ліхтенштейн", "Литва", "Люксембург", "Макао", "Північна Македонія", "Мадагаскар",
    "Малаві", "Малайзія", "Мальдіви", "Малі", "Мальта", "Маршаллові острови", "Мавританія", "Маврикій",
    "Мексика", "Монако", "Монголія", "Чорногорія", "Монтсеррат", "Марокко", "Мозамбік", "М'янма", "Намібія",
    "Науру", "Непал", "Нідерланди", "Нова Каледонія", "Нова Зеландія", "Нікарагуа", "Нігер", "Нігерія",
    "Норвегія", "Оман", "Пакистан", "Палау", "Панама", "Папуа-Нова Гвінея", "Парагвай", "Перу", "Філіппіни",
    "Польща", "Португалія", "Пуерто-Рико", "Катар", "Румунія", "російська федерація", "Руанда", "Самоа",
    "Сан-Марино", "Саудівська Аравія", "Сенегал", "Сербія", "Сьєрра-Леоне", "Сінгапур", "Словаччина",
    "Словенія", "Соломонові острови", "Сомалі", "Південна Африка", "Південний Судан", "Іспанія", "Шрі Ланка",
    "Судан", "Суринам", "Шпіцберген та Ян Маєн", "Свазіленд", "Швеція", "Швейцарія", "Сирійська Арабська Республіка",
    "Таджикистан", "Танзанія, Об'єднана Республіка", "Тайланд", "Східний Тимор", "Токелау", "Тонга",
    "Тринідад і Тобаго", "Туніс", "Туреччина", "Туркменістан", "Тувалу", "Уганда", "Україна",
    "Об'єднані Арабські Емірати", "Об'єднане Королівство", "США", "Уругвай", "Узбекистан", "Вануату",
    "Венесуела", "В'єтнам", "Віргінські острови, Британські", "Західна Сахара", "Ємен", "Замбія", "Зімбабве"
];
export var europe = [
    "Албанія", "Андорра", "Австрія", "Бельгія", "Боснія і Герцеговина", "Болгарія", "Хорватія", "Кіпр", "Чеська Республіка",
    "Данія", "Естонія", "Фарерські острови", "Фінляндія", "Франція", "Німеччина", "Гібралтар", "Греція",
    "Ватикан", "Угорщина", "Ісландія", "Ірландія", "Молдова", "Італія", "Косово", "Латвія", "Ліхтенштейн", "Литва",
    "Люксембург", "Північна Македонія", "Мальта", "Монако", "Чорногорія", "Нідерланди", "Норвегія",
    "Польща", "Португалія", "Румунія", "Сербія", "Словаччина", "Словенія", "Іспанія", "Швеція", "Швейцарія",
    "Україна", "Об'єднане Королівство", "Віргінські острови, Британські"
];
export var sovok = [
    "Вірменія", "Азербайджан", "Білорусь", "Грузія", "Казахстан", "Киргизстан", "Молдова", "Таджикистан",
    "Туркменістан", "Узбекистан"
];
