import React, {useEffect, useState} from 'react';
import { NavLink as RouterLink } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {getClientDefaultImageUrl} from 'ultra/configs/general';
import {getClientUrl} from 'ultra/configs/general';

import './index.scss';

export default function Banner(props) {
    const {data} = props;

    // const [link, setLink] = useState();
    const [target, setTarget] = useState();

    const {external, photo, city, uri, url, button, align, title, label, background, backgroundLeft, backgroundRight} = data;

    useEffect(() => {
        // if (uri) {
        //     setLink(getClientUrl(city, restorePath(normalizeRoute(uri))))
        // }
        // else if (url) {
        //     setLink(url)
        // }

        setTarget(external ? '_blank' : '_self')
    }, [])

    if (!data) return <></>;

    const renderImage = () => {
        return <div className='BannerImgWrap'>
            {(url && external) && <span className='external'>
                <ArrowOutwardIcon />
            </span>}
            {label && <div className='BannerLabel'>{label}</div>}
            <span className={align === 'right' ? 'image right' : 'image'} style={{
                backgroundImage: `url(${getClientDefaultImageUrl(window, photo)})`,
                backgroundColor: background,
            }}></span>
            {backgroundLeft && <span className='backgroundLeft' style={{backgroundColor: backgroundLeft}}></span>}
            {backgroundRight && <span className='backgroundRight' style={{backgroundColor: backgroundRight}}></span>}
        </div>
    }

    if (button && title) return <div className='Banner BannerWithButton'>
        {renderImage()}
        
        {url ?
            <a href={url} className='BannerButton' target={target}>{title}</a>
            : <RouterLink  className='BannerButton' to={restorePath(uri)} target={target}>{title}</RouterLink>
        }
    </div>

    if (!button && title) return <div className='Banner'>
        {url ?
            <a href={url} target={target}>
                {renderImage()}
                <span className='BannerText'>{title}</span>
            </a>
            :
            <RouterLink to={restorePath(uri)} target={target}>
                {renderImage()}
                <span className='BannerText'>{title}</span>
            </RouterLink>
        }
    </div>

    const className = props.className ? 'Banner ' + props.className : 'Banner'

    return <div className={className}>
        {url ?
            <a href={url} target={target}>
                {renderImage()}
            </a>
            :
            <RouterLink to={restorePath(uri)}>
                {renderImage()}
            </RouterLink>
        }
    </div>
}