import {useRef} from 'react';
import mediumZoom from 'medium-zoom';

import {getClientDefaultImageUrl} from 'ultra/configs/general';

export default function DefaultImage(props) {
    const {image, src, className, onClick, width, height, zoom, imgLoading} = props;
    const zoomRef = useRef(null)

    // https://medium-zoom.francoischalifour.com/
    // https://github.com/francoischalifour/medium-zoom/tree/master/examples/react
    const attachZoom = node => {
        if (zoom) {
            if (zoomRef.current === null) {
                zoomRef.current = mediumZoom({margin: 30})
            }

            const zone = zoomRef.current

            if (node) {
                zone.attach(node)
            } else {
                zone.detach()
            }
        }
    }

    return <img
        width={width}
        height={height}
        loading={imgLoading || 'lazy'}
        className={className}
        onClick={onClick}
        ref={attachZoom}
        src={getClientDefaultImageUrl(window, (!image && src) ? `config/images/default/${src}.png` : image)}
    />
}
