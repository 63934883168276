import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";

import {hasFullAccess} from 'ultra/helpers/auth';
import {getClientCity} from 'ultra/configs/general'
import {DEFAULT_CITY} from 'ultra/const/general';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
// import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

// import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';

import {useGlobalCustomContext} from '../../../../Helpers/context';
// import {cleanNodeHistory} from '../../../Profile/Helpers/history';
import {NODE_ACTIONS} from '../../Reducers/node';
// import {useToasterStore} from '../../../../Stores/toster';
// import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import InlineActions from '../InlineActions';

import './index.scss';


export default function Render(options) {
    const actions = getActions(options);
    if (actions.length === 0) return null;
    return <OrderInlineActions {...options} actions={actions} />;
}

function getActions(options) {
    const {content} = options;

    const actions = [];

    // if (hasEditAction) {
        actions.push('edit')
    // }

    // if (hasValidateAction && node._published) {
        actions.push('unvalidate')
    // }

    // if (hasValidateAction && !node._published) {
        actions.push('validate')
    // }

    // if (hasDeleteAction) {
        actions.push('delete')
    // }

    // if (!hideHistory && hasFullAccess(userState.user) && node?._did) {
        actions.push('history')
    // }

    // if (showCleanHistory && hasFullAccess(userState.user) && node._did) {
        actions.push('clean')
    // }


    return actions;
}

function OrderInlineActions(props) {
    const {actions, content, node, hideHistory, className, unfolded, direction, city} = props;

    const navigate = useNavigate();
    const {nodeDispatch, userState} = useGlobalCustomContext();

    const [list, setList] = useState([])
    
    useEffect(() => {
        const list = actions.map(id => {
            let result;
            switch(id) {
                case 'edit':
                    result = {
                        id: 'edit',
                        icon: <>В роботу</>, // <EditOutlinedIcon />
                        onClick: () => {
                            nodeDispatch({type: NODE_ACTIONS.EDIT, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'unvalidate':
                    result = {
                        id: 'unvalidate',
                        icon: <>Відмінити</>, // <UnpublishedOutlinedIcon className='Icon errorIcon' />,
                        onClick: () => {
                            const city = getClientCity(window) || DEFAULT_CITY;
                            nodeDispatch({type: NODE_ACTIONS.UNVALIDATE, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'validate':
                    result = {
                        id: 'validate',
                        icon: <>Підтвердити</>, // <CheckCircleOutlinedIcon className='Icon successIcon' />,
                        onClick: () => {
                            const city = getClientCity(window) || DEFAULT_CITY;
                            nodeDispatch({type: NODE_ACTIONS.VALIDATE, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'delete':
                    result = {
                        id: 'delete',
                        icon: <>Видалити</>, // <DeleteOutlineIcon className='Icon errorIcon' />,
                        onClick: () => {
                            const city = getClientCity(window) || DEFAULT_CITY;
                            nodeDispatch({type: NODE_ACTIONS.DELETE_START, data: {city, uri: node._uri}})
                        }
                    }
                    break;

                case 'history':
                    result = {
                        id: 'history',
                        icon: <>В роботу</>, // <HistoryOutlinedIcon className='Icon' />,
                        onClick: () => {
                            const city = getClientCity(window) || DEFAULT_CITY;
                            navigate(`/profile/history/node/${city}/${node._did}`)
                        }
                    }
                    break;

                case 'clean':
                    result = {
                        id: 'clean',
                        icon: <>Виконано</>, // <CleaningServicesIcon className='Icon errorIcon' />,
                        onClick: () => {
                            // showPromt({
                            //     snackbarMessage: 'Ви бажаєте очистити історію і видалити всі файли з корзини?',
                            //     snackbarDuration: 10000,
                            //     onApproveHandler: () => {
                            //         closeToaster();
            
                            //         cleanNodeHistory(node._city, node._did)
                            //             .then(() => {
                            //                 hideBackdropPreloader();
                            //                 nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                            //             })
                            //             .catch(() => {
                            //                 hideBackdropPreloader();
                            //             })
                            //     },
                            // })
                        }
                    }
                    break;
            }
            return result;
        });
        setList(list);
    }, [userState, node, hideHistory])

    return <InlineActions actions={list} className={className} unfolded={unfolded} direction={direction} />
}
