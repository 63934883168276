import {getClientConfig} from 'ultra/configs/general';
import {post, get} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export async function telegramLogin(bot, data) {
    if (data.init) {
        return post(`${api}/bot/${bot}/login`, data);
    }
    else {
        return new Promise(resolve => {
            console.error('Відсутній обєкт initData з Телеграму')
            resolve({})
        })
    }
}

// TODO: ? remove ?
export async function getTelegramLogedUser() {
    return get(`${api}/bot/user`).promise;
}
