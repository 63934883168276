import React, {useEffect, useState} from 'react';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import {useLoaderData, useNavigation, useParams} from "react-router-dom";
import {isMobile} from "react-device-detect";

// import {DEFAULT_CITY} from 'ultra/const/general';
// import {getClientCity} from 'ultra/configs/general';
import {getFacebookLink, getInstagramLink} from 'ultra/helpers/messengers';

import {setPageTitle} from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';

import {useConfigStore} from '../../../../../Stores/config';

import PreloaderTable from '../../../../../Components/PreloaderTable';

import UserInlineActions from '../../../Components/UserInlineActions'
import UserNodesList from '../../../Components/UserNodesList'
import UserSingeltonesList from '../../../Components/UserSingeltonesList'
import UserLibraryList from '../../../Components/UserLibraryList'
import UserAdminActions from '../../../Components/UserAdminActions'

import TableView from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import UserMessageLink from '../../../../Content/Widgets/UserMessageLink';
import Link from '../../../../Content/Widgets/Link'
import Avatar from '../../../../Content/Widgets/Avatar';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
// import {useToasterStore} from '../../../../../Stores/toster';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import {userHasAllNodes} from '../../../../../Helpers/user';
import {getUserHistory} from '../../../Helpers/user';

import './index.scss';

export default () => {
    const initHistory = useLoaderData();

    let {id} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    // const {showError} = useToasterStore();
    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore(); // showBackdropPreloader
    const {userState} = useGlobalCustomContext();

    // const [city] = useState(getClientCity(window) || DEFAULT_CITY);
    const [history, setHistory] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _code: {
            type: 'notification',
            placeholder: 'Тип'
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Ініціатор'
        },
        details: {
            type: 'history-details',
            placeholder: 'Деталі'
        }
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_code'],
            hidden: ['details'],
            noHeaders: true
        } : {
            visible: ['_timestamp', '_code'],
            hidden: ['details'],
            noHeaders: true,
            unfolded: true,
        })

        // updateHistory(initHistory);

        setPermits({})

        setPageTitle('Історія користувача')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState])

    // useEffect(() => {
    //     if (userState?.userRefreshStarted) {
    //         nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
    //     }
    // }, [userState])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateHistory(initHistory);
        }
    }, [navigation])

    function updateHistory(history) {
        setHistory({...history})

        hideBackdropPreloader()
    }

    // const actions = (id) => <NodeHistoryInlineActions isRemoved={!history?.user?._uri} content={history?.list?.[id]} />

    const selectClasses = (data) => {
        return data?._code
            ? 'tr_' + data?._code.replace(':', '_')
            : '';
    }

    const content = {
        title: history?.user?.displayName
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\history'] = {title: "Історія"}

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateHistory({});
        getUserHistory(id, url.search)
            .promise
            .then(updateHistory)
    }

    return <div id="Content" className='Content'>
            <div className='HistoryUserAdminPage'>
                {history && history?.user && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && history?.user && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs}/>}

                {isMobile && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>}

                {(!fields) && <PreloaderTable/>}

                {history?.user && <div className='HistoryUserAdminPageDetails'>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <div className='UserCard UserInfo'>
                                <Avatar image={history?.user?.photoURL}/>

                                <div className='Details'>
                                    <UserMessageLink variant="button" label="Написати" fullWidth id={id}/>
                                </div>
                            </div>
                            <Grid container spacing={1}>
                                {(history?.user?._blocked || history?.user?._blocked) && <Grid item xs={12}>
                                    <div className='labelWrap'>
                                        <span className='labelValue'>
                                            {history?.user?._blocked && <Chip variant='outlined' className='errorLabel' label={history?.user?.__blocked_comment ? `Заблокований: ${history?.user?.__blocked_comment}` : `Заблокований`} />}
                                            {history?.user?._not_checked && <Chip variant='outlined' className='errorLabel' label="Проходить перевірку" />}
                                        </span>
                                    </div>
                                </Grid>}
                                <Grid item xs={12}>
                                    <div className='labelWrap'>
                                        <span className='label'>ID:</span>
                                        <span className='labelValue'>
                                            {id}
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {history?.user?.roles && <div className='labelWrap'>
                                        <span className='label'>Ролі:</span>
                                        <span className='labelValue'>
                                            {history?.user?.roles.map(r => <Chip key={r} variant='outlined' label={r} />)}
                                        </span>
                                    </div>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className='labelWrap'>
                                        <span className='label'>email:</span>
                                        <span className='labelValue'>
                                            {history?.user?.email}
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='labelWrap'>
                                        <span className='label'>facebook:</span>
                                        <span className='labelValue'>
                                            <Link blank link={getFacebookLink(history?.user?.facebook)} />
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='labelWrap'>
                                        <span className='label'>instagram:</span>
                                        <span className='labelValue'>
                                            <Link blank link={getInstagramLink(history?.user?.instagram)} />
                                        </span>
                                    </div>
                                </Grid>
                                {userHasAllNodes(history?.user?.nodes) > 0 && <Grid item xs={12}>
                                    <div className='labelWrap labelWrapRow'>
                                        <span className='label'>Контент:</span>
                                        <span className='labelValue'>
                                            <UserNodesList list={history?.user?.nodes?.all}/>
                                        </span>
                                    </div>
                                </Grid>}
                                {Object.keys(history?.user?.nodes?.singleton).length > 0 && <Grid item xs={12}>
                                    <div className='labelWrap labelWrapRow'>
                                        <span className='label'>Singleton:</span>
                                        <span className='labelValue'>
                                            <UserSingeltonesList list={history?.user?.nodes?.singleton}/>
                                        </span>
                                    </div>
                                </Grid>}
                                {history?.user?.library?.length > 0 && <Grid item xs={12}>
                                    <div className='labelWrap labelWrapRow'>
                                        <span className='label'>Бібліотека:</span>
                                        <span className='labelValue'>
                                            <UserLibraryList list={history?.user?.library} />
                                        </span>
                                    </div>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
{/*                     
                    // {!isEmptyMessengerData(userInfo.telegram) && <div className="labelWrap">
                    //     <span className="label">Telegram:</span>
                    //     <span className="labelValue">
                    //         <Link blank link={getTelegramLink(userInfo.telegram.number)} />
                    //     </span>
                    // </div>}
                    // {!isEmptyMessengerData(userInfo.whatsup) && <div className="labelWrap">
                    //     <span className="label">WhatsUp:</span>
                    //     <span className="labelValue">
                    //         <Link blank link={getWhatsupLink(userInfo.whatsup.number)} />
                    //     </span>
                    // </div>} */}
                </div>}

                {history?.user?.id && <UserInlineActions showCleanHistory user={history?.user} className="CurrentPageInlineActions" hideHistory/>}

                {/* <UserMessageLink id={values.details.id} /> */}

                {fields && history && <TableView
                    showPreloader={!history?.list}
                    content={history}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    actions={false}
                    selectClasses={selectClasses}
                />}
            </div>
            <UserAdminActions />
        </div>
}
