import { create } from 'zustand'

export const useNavigationStore = create((set) => ({
  contentLoading: false,
  startLoading: () => set(() => {
    return {contentLoading: true}
  }),
  stopLoading: () => set(() => {
    return {contentLoading: false}
  }),
}))
