import {useState, useEffect} from 'react';
import {useNavigate, useNavigation} from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import {getClientConfig} from 'ultra/configs/general';
import {NOTIFY, HISTORY_LIBRARY, HISTORY_NODE, HISTORY_USER, NOTIFY_NETWORKING} from "ultra/const/log";

import {getAuthUserID, isYoursAccount} from '../../../../Helpers/user';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import {useToasterStore} from '../../../../Stores/toster';

import {NODE_ACTIONS} from '../../Reducers/node';
import {USER_ACTIONS} from '../../../Profile/Reducers/user';

import './index.scss';

export default function UserNotify() {
    // props
    const [ws, setWS] = useState();
    const [notifyMessage, setNotifyMessage] = useState();

    const navigate = useNavigate();
    const navigation = useNavigation();

    const {showSuccess} = useToasterStore(); // showError
    const {userState, userDispatch, nodeDispatch} = useGlobalCustomContext();
    
    useEffect(() => {
        // update connection on user login/logout
        getMessages();
    }, [userState?.user?.id])

    useEffect(() => {
        // update connection if it's lost
        if (navigation.state === 'idle') {
            getMessages();
        }
    }, [navigation])

    function isOpenWs(ws) {
        // ws.readyState != 2 && ws.readyState != 3
        return ws?.readyState == 1;
    }

    function showNotification(msg, txt) {
        if (msg._code === NOTIFY.READED) return;

        setNotifyMessage(<span className='NotifyMessage'><span className='icon'>🔔</span> {txt ? txt : `Ви отримали сповіщення`}</span>);
    }

    function getMessages() {
        // connect if user present but no connection
        if (getAuthUserID(userState) && !isOpenWs(ws)) {
        // userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

        console.log('ws.sub')
        const config = getClientConfig(window);
        const notifications = new WebSocket(config.notifications + "/" + getAuthUserID(userState))

        // clear keep alive connection if it's already present
        if (window._notify_interval) clearInterval(window._notify_interval);

        // keep alive connection (default alive - 60s)
        const ping_interval = 50 * 1000;
        window._notify_interval = setInterval(() => {
            console.log('ws.ping', window._notify_interval)

            if (notifications) {
                notifications.send(JSON.stringify({ping: 1}));
            } else {
                clearInterval(window._notify_interval);
            }
        }, ping_interval)

        notifications.addEventListener('close', () => {
            clearInterval(window._notify_interval);
        })

        setWS(notifications)

        function onUserBlockHandler() {
            // TODO: move onExitHandler to ACTIONS
            // showError({
            // snackbarMessage: 'Ваш аккаунт було заблоковано',
            // snackbarDuration: 60000,
            // onCloseHandler: () => {
            //     userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
            //     onExitHandler();
            //     navigate('/');
            // }
            // })
        }

        function onNetworkRequesHandler(msg, short, callback) {
            showSuccess({
            snackbarMessage: <>
                <p>Ви отримали заявку на послуги з розділу нетворкінгу від {msg._init_by_name}</p>
                {/* <p>{msg.details.details}</p> */}
                {!short && <p>Деталі заявки можна дізнатись в розділі "Сповіщення"</p>}
            </>,
            snackbarDuration: 60000,
            onCloseHandler: () => {
                if (callback) callback();
                // navigate('/profile/messages');
            }
            })
        }

        // TODO: unsubscribe !
        notifications.addEventListener("message", async event => {
            const msg = JSON.parse(event.data);
            if (!msg._code) return;

            if (window.location.pathname === '/profile/messages' && window.location.search.length === 0) { // isEmptyObj(content) && 
                if (msg._code === HISTORY_USER.BLOCK) {
                    // TODO !!!! FIX IT // empty function now 
                    // same code user in UserPic, should be done with redux actions
                    onUserBlockHandler();
                    return;
                }

                if (msg._code === NOTIFY_NETWORKING.REQUEST) {
                    onNetworkRequesHandler(msg, true, () => {
                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                        nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                    });
                    return;
                }
                
                showNotification(msg);
                userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                return;
            }

            switch (msg._code) {
                case HISTORY_USER.LOGOUT:
                    break;

                case NOTIFY.READED:
                    userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                    break;

                case HISTORY_USER.BLOCK:
                    onUserBlockHandler();
                    break;

                case NOTIFY_NETWORKING.REQUEST:
                    onNetworkRequesHandler(msg, false);
                    break;

                case HISTORY_LIBRARY.TRANSFER_TO_LIBRARY:
                    showNotification(msg, "В бібліотеку передана нова книга");
                    userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                    break;

                case HISTORY_LIBRARY.CANCEL:
                case HISTORY_LIBRARY.RESERVE:
                case HISTORY_LIBRARY.RESERVE_NOT_APPROVED:
                case HISTORY_LIBRARY.RESERVE_APPROVED:
                case HISTORY_LIBRARY.RETURN:
                case HISTORY_LIBRARY.RETURN_APPROVE:
                    const page_did = document.getElementById('page_did')?.innerText  
                    if (msg.details._did === page_did) {
                        nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                    }

                    // to prevent self notifications
                    if (!isYoursAccount(userState, msg._init_by)) {
                        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                        if (msg._code === HISTORY_LIBRARY.RESERVE) {
                            showNotification(msg, `${msg._init_by_name} забронював книгу`);
                        }
                        else if (msg._code === HISTORY_LIBRARY.CANCEL) {
                            showNotification(msg, "Запит на книгу було відмінено");
                        }
                        else if (msg._code === HISTORY_LIBRARY.RESERVE_NOT_APPROVED) {
                            showNotification(msg, "Бронь книги було відмінено");
                            userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                        }
                        else if (msg._code === HISTORY_LIBRARY.RESERVE_APPROVED) {
                            showNotification(msg, "Бронь книги підтверджено");
                        }
                        else if (msg._code === HISTORY_LIBRARY.RETURN) {
                            showNotification(msg, "Запит на повернення книги");
                        }
                        else if (msg._code === HISTORY_LIBRARY.RETURN_APPROVE) {
                            showNotification(msg, "Запит на повернення книги підтверджено");
                        }
                    }
                    break;

                case HISTORY_NODE.UPDATE:
                case HISTORY_NODE.WORKER_CREATE:
                case HISTORY_NODE.WORKER_UPDATE:
                case HISTORY_NODE.WORKER_REMOVE:

                    // react if message inti by other client OR from other user tabs
                    if (!isYoursAccount(userState, msg._init_by) || (isYoursAccount(userState, msg._init_by) && document.hidden == true)) {
                    // TODO: move to specify cases
                    userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                    const page_did = document.getElementById('page_did')?.innerText  
                    if (msg.details._did === page_did) {
                        let text
                        if (!isYoursAccount(userState, msg._init_by)) {
                            text = 'Сторінка була відредагована іншим користувачем'
                        }
                        else {
                            text = 'Ви оновили сторінку'
                        }

                        showNotification(msg, text);
                        nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                    }
                    else {
                        showNotification(msg);
                    }
                    }
                    break;


                case HISTORY_NODE.MOVE:
                case HISTORY_NODE.RENAME:
                case HISTORY_NODE.REMOVE:
                    if (!!isYoursAccount(userState, msg._init_by) || (isYoursAccount(userState, msg._init_by) && document.hidden == true)) {
                    userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                    const page_did = document.getElementById('page_did')?.innerText 
                    if (msg.details._did === page_did) {
                        if (msg._code === HISTORY_NODE.REMOVE) {
                        showNotification(msg, "Сторінка була видалена");
                        navigate('/');
                        } else {
                        showNotification(msg, "Адреса сторінки була змінена");
                        navigate(msg.details._new_url);
                        }
                    }
                    else {
                        showNotification(msg);
                    }
                    }
                    break;

                default:
                    // just notify, no actions
                    // HISTORY_NODE.VALIDATE,
                    // HISTORY_NODE.UNVALIDATE,
                    // HISTORY_NODE.RESTORE

                    if (!isYoursAccount(userState, msg._init_by)) {
                    showNotification(msg);
                    userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
                    }
                }
            })
        }

        // showPromt({
        //   snackbarMessage: 'Сторінка була відредагована, бажате оновити?',
        //   onApproveHandler: () => {
        //     nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
        //     closeToaster();
        //     hideBackdropPreloader();
        //   },
        // })


        // close connection if no logged user
        if (!getAuthUserID(userState) && isOpenWs(ws)) {
        clearInterval(window._notify_interval);
        ws.close();
        setWS(null)
        }
    }

    function handleNotifyClose() {
        setNotifyMessage(null);
    }

    return <Snackbar
        className='ShortNotifications'
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={Boolean(notifyMessage)}
        message={notifyMessage}
        autoHideDuration={4000}
        onClose={handleNotifyClose}
        action={<CloseIcon onClick={handleNotifyClose} />}
    />
}
