import {hasFullAccess} from 'ultra/helpers/auth';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';
import WorkerDetails from '../../../Components/WorkerDetails'
import UserUpdatePage from '../../../Components/UserUpdatePage';

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        return <div className="NotificationTitle">
            <>Був оновлений шлях </>
            <WorkerDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
        </div>
    }

    if (fullAccess) {
        return <div className="NotificationTitle">
            <UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} />
            <> оновив шлях </>
            <WorkerDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
        </div>
    }

    return <UserUpdatePage values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
}
