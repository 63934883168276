import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

// import TelegramIcon from '@mui/icons-material/Telegram';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// import FormControl from '@mui/material/FormControl';

// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';

import './index.scss';

export default function Location(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [value, setValue] = useState({})
    const [showLatLong, setShowLatLong] = useState(false)
    const [showHowToGetCoordinates, setShowHowToGetCoordinates] = useState(false)
    const [showSign, setShowSign] = useState(false)

    const [className, setClassName] = useState('Location'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''))

    useEffect(() => {
        setClassName('Location'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    useEffect(() => {
        setValue(content[field.id] || {})
    }, [content[field.id]])

    useEffect(() => {
        setShowSign(Boolean(content[field.id]?.title));
    }, [])

    const onTitleChange = (e) => {
        const result = {...value, title: e.target.value}
        setValue(result)
        onChange({target: {value: result}})
    }

    const onLinkChange = (e) => {
        const link = e.target.value
        const result = {...value, link}
        setValue(result)
        onChange({target: {value: result}})
    }

    const onLatitudeChange = (e) => {
        const result = {...value, lat: e.target.value}
        setValue(result)
        onChange({target: {value: result}})
    }

    const onLongitudeChange = (e) => {
        const result = {...value, lng: e.target.value}
        setValue(result)
        onChange({target: {value: result}})
    }

    const onShowLatLong = () => {
        setShowLatLong(!showLatLong)
    }

    return (<>
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='FormFieldsGroupLabel'>
                        {field.placeholder} {field.required ? '*' : ''}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='FormFieldsGroup'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    className='LocationLink'
                                    fullWidth
                                    disabled={isDisabled}
                                    value={value.link}
                                    label={`Посилання на мапу ${field.required ? '*' : ''}`}
                                    variant="outlined"
                                    onChange={onLinkChange}
                                />
                                <div className='fieldExample'>
                                    Наприклад, для Google Maps: https://maps.app.goo.gl/TXwti2qXqwEivxN56
                                </div>
                            </Grid>
                    
                            {value.link && <Grid item xs={12}>
                                <div className='addSign'>
                                {showSign ? '-' : '+'}  <span className='pseudoLink' onClick={() => {setShowSign(!showSign)}}>{showSign ? 'Приховати' : 'Додати'} підпис</span>
                                </div>
                            </Grid>}
                            {showSign && <Grid item xs={12}>
                                <TextField
                                    className='LocationName'
                                    fullWidth
                                    disabled={isDisabled}
                                    value={value.title}
                                    label="Підпис"
                                    variant="outlined"
                                    onChange={onTitleChange}
                                />
                                <div className='fieldExample'>
                                    Приклад: Майдан Незалежності
                                </div>
                            </Grid>}

                            {value.link && <Grid item xs={12}>
                                <div className='fieldDetails'>
                                    📍 <span onClick={onShowLatLong} className='pseudoLink'>Додати мапу на сторінку</span>
                                </div>
                            </Grid>}
                            {showLatLong && <Grid item xs={12} className='howToGetCoordinatesWrap'>
                                <p className='howToGetCoordinates'>❓ <span className='pseudoLink' onClick={() => setShowHowToGetCoordinates(!showHowToGetCoordinates)}>Як отримати координати на Google Maps</span></p>
                                {showHowToGetCoordinates && <ol className='showHowToGetCoordinates'>
                                    <li>Знайдіть локацію на мапі</li>
                                    <li>Натисніть правою кнопокю на локації</li>
                                    <li>В меню що з'явилось на екрані будуть відображені координати, наприклад: -8.644709309665844, 115.14111461167892</li>
                                    <li>Натисніть на число і вони будуть скопійовані в буфер</li>
                                    <li>Перша цифра це Широта/Latitude, друга цифра це Довгота/Longitude</li>
                                </ol>}
                            </Grid>}
                            {showLatLong && <Grid item xs={6} md={6}>
                                <TextField
                                    className='LocationLatitude'
                                    fullWidth
                                    disabled={isDisabled}
                                    value={value.lat}
                                    label="Широта/Latitude"
                                    variant="outlined"
                                    onChange={onLatitudeChange}
                                />
                                <div className='fieldExample'>
                                    Наприклад: 50.450555
                                </div>
                            </Grid>}
                            {showLatLong && <Grid item xs={6} md={6}>
                                <TextField
                                    className='LocationLongitude'
                                    fullWidth
                                    disabled={isDisabled}
                                    value={value.lng}
                                    label="Довгота/Longitude"
                                    variant="outlined"
                                    onChange={onLongitudeChange}
                                />
                                <div className='fieldExample'>
                                    Наприклад: 30.5206945
                                </div>
                            </Grid>}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </>)
}
