import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getTime, timestampToDate, getFormatedDate, getFormatedTime} from 'ultra/helpers/date'

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import LinksLines from '../../../../../../Widgets/LinksLines'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    const [currentPage, setCurrentPage] = useState()
    
    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const location = useLocation();

    function getEventTime(date) {
        const baseDate = timestampToDate(date);

        if (getTime(baseDate, timeZone).hour() > 0) {
            return getFormatedTime(baseDate, timeZone)
        } else {
            return getFormatedDate(baseDate, timeZone)
        }
    }

    useEffect(() => {
        let url = new URL(window.location.href);
        setCurrentPage(url.searchParams.get('page'));
    }, [location])

    return <div className='NewsListPage'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <LinksLines
            config={config}
            
            permits={permits}
            showPagination={content.showPagination}
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}

            title={(link) => <div>
                <span className='date'>{getEventTime(link._created)}</span>
                {link.title}
            </div>}
        />

        {!currentPage
            && <CKEditorContent
                showSkeleton={contentLoading}
                content={content.description}
            />
        }
    </div>
}
