export const TILE_SIZES = {
    THUMBNAIL: {
        DESKTOP: {
            H: 200,
        },
        MOBILE: {
            H: 140,
        }
    },
    GALLERY: {
        DESKTOP: {
            H: 200,
        },
        MOBILE: {
            H: 140,
        }
    },
    BANNER: {
        DESKTOP: {
            H: 190,
        },
        MOBILE: {
            H: 140,
        }
    },
    POSTER: {
        DESKTOP: {
            H: 300,
        },
        MOBILE: {
            H: 200,
        }
    }
}
