import {useState, useEffect, useCallback} from 'react';

import Button from '@mui/material/Button';

import {isEmptyMessengerData} from 'ultra/helpers/utils';

import {USER_ACTIONS} from '../../../../../../../../../Profile/Reducers/user';

import {useGlobalCustomContext} from '../../../../../../../../../../Helpers/context';
import {useBackdropPreloaderStore} from '../../../../../../../../../../Stores/backdropPreloader';
// import {getTelegramMode, getTelegramUser} from '../../../../../../../../../../Helpers/telegram';

import FormContainer from '../../../../../../../../../../Components/Form/FormContainer';

import Avatar from '../../../../../../../../Widgets/Avatar' 
import MessengerValue from '../../../../../../../../Widgets/MessengerValue';

const pName = {
    options: {
        regexp: '^[A-Za-z\-\—\⸺ ]*$'
    },
    type: 'text',
    placeholder: 'ПІБ англійською (із закордоного паспорту)',
    required: true,
    notes: '* Лише англійські літери та знак "-"',
    default: 'template:user.pname',
}

const accept = {
    type: 'checkbox',
    placeholder: 'Погоджуюсь на обробку даних',
    required: true,
}

export default function PersonDetailsStep(props) {
    const {submit} = props;

    const {userState, userDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    // const [telegram] = useState(getTelegramUser());

    const onSubmit = (data) => {
        hideBackdropPreloader();
        submit(data);
    }

    function goToProfile() {
        userDispatch({type: USER_ACTIONS.PROFILE_EDIT})
    }

    // if (getTelegramMode()) {
    //     const config = {
    //         fields: {
    //             pName,
    //             messenger: {
    //                 default: 'template:user.messenger',
    //                 type: 'messenger',
    //                 placeholder: 'Ваш месенджер',
    //                 required: true,
    //             },
    //             telegram_id: {
    //                 default: 'template:user.telegram.id',
    //                 type: 'hidden',
    //             },
    //             email: {
    //                 type: 'email',
    //                 placeholder: 'Ваш email',
    //                 default: 'template:user.email',
    //             },
    //             accept
    //         },
    //         fieldsOrder: [
    //             'pName',
    //             'messenger',
    //             'email',
    //             'accept'
    //         ],
    //         form: {
    //             submitText: "Підтвердити дані"
    //         }
    //     };

    //     return <>
    //         <FormContainer
    //             config={config}
    //             submitHandler={onSubmit}
    //         />
    //     </>
    // }

    const config = {
        fields: {
            pName,
            accept
        },
        fieldsOrder: [
            'pName',
            'accept'
        ],
        form: {
            submitText: "Підтвердити дані"
        },
        defaultValues: {
            pName: userState?.user?.pName
        }
    };

    return <>
            {userState?.user && <div>
                {(!userState?.user?.displayName || isEmptyMessengerData(userState?.user?.messenger)) && <>
                    <div className="step">У вашому профілі відсутні дані, необхідні для бронювання книги</div>

                    <div className="UserCard userDetails">
                        <span className='UserLogo'>
                            <Avatar image={userState?.user?.photoURL} />
                        </span>

                        <p className={`labelWrap ${!userState?.user?.displayName && 'absent'}`}>
                            <span className="label">Імʼя:</span>
                            {!userState?.user?.displayName && <span className="labelValue">
                                дані відсутні
                            </span>}

                            {userState?.user?.displayName}
                        </p>

                        <p className={`labelWrap ${isEmptyMessengerData(userState?.user?.messenger) && 'absent'}`}>
                            <span className="label">Месенджер:</span>
                            {isEmptyMessengerData(userState?.user?.messenger) && <span className="labelValue">
                                дані відсутні
                            </span>}

                            <MessengerValue messenger={userState?.user?.messenger} />
                        </p>
                    </div>

                    <div className="submit">
                        <Button variant="contained" fullWidth onClick={goToProfile}>Редагувати дані</Button>
                    </div>
                </>}

                {(userState?.user?.displayName && !isEmptyMessengerData(userState?.user?.messenger)) && <>
                    <FormContainer
                        config={config}
                        submitHandler={onSubmit}
                    />
                </>}
            </div>}
    </>
}
