import {useEffect, useState} from 'react'

import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {getClientCity} from 'ultra/configs/general'
import {DEFAULT_CITY} from 'ultra/const/general';
import {hasFullAccess} from 'ultra/helpers/auth';

// import {useConfigStore} from '../../../../Stores/config';
import {useSearchStore} from '../../../../Stores/search';

import NotPublishedIcon from '../../Widgets/NotPublishedIcon';
import Pagination from '../../Widgets/Pagination';
import NodeInlineActions from '../../Widgets/NodeInlineActions';

import PreloaderLinks from '../../../../Components/PreloaderLinks';
import Icon from '../../../../Components/Icon';

import {useGlobalCustomContext} from '../../../../Helpers/context';
// import {filterBySearch} from '../../Helpers/content';

import './index.scss';

export default function Links(props) {
    const {links, order, showPagination, className, showSkeleton} = props

    const {search} = useSearchStore();
    const {userState} = useGlobalCustomContext();
    // const {configs} = useConfigStore();

    const [sortedLinks, setSortedLinks] = useState([])
    const [city] = useState(getClientCity(window) || DEFAULT_CITY)

    function getOrderedLinks(order) {
        if (!order) return false;
        const urisList = []

        order.map(did => {
            Object.keys(links.list).map(uri => {
                if (!did) return;
                if (urisList.includes(uri)) return;

                if (links.list[uri]?._did === did || links.list[uri]?.originalDid === did) {
                    urisList.push(uri);
                }
            })
        })

        // get items not in list
        const notInList = Object.keys(links.list).filter(item => !urisList.includes(item));

        return urisList.concat(notInList);
    }

    function setLinks() {
        const source = (getOrderedLinks(order) || links?.order.filter(i => i.length > 1));

        const sorted = []
        source?.map(link => sorted.push({...links?.list[link]}))
        setSortedLinks(sorted)
    }

    useEffect(setLinks, [])
    useEffect(setLinks, [links, search])

    if (!links?.list) return <></>

    if (showSkeleton) return <PreloaderLinks className={`childrensList ${className ? className : ''}`} />

    if (sortedLinks.length === 0) return <></>

    return <>
        <ul className={`childrensList ${className ? className : ''}`}>
            {sortedLinks.map(link => <li key={link._uri}>
                {link._uri && <span className='wrap'>
                    <span className={link._published ? 'wrapLink' : 'wrapLink notPublishedWrap'}>
                        {!link._published && <NotPublishedIcon />}
                        <RouterLink className="link" to={link._uri.slice(1)}>
                            <NavigateNextIcon className="arrow" />
                            {link._icon && <span className="icon">
                                <Icon icon={link._icon} />
                                {/* {!link._icon && <Icon icon="subject" />} */}
                            </span>}

                            <span className="information">
                                <span className='title'>{link.title}</span>
                                {link._short_description && <span className="description">{link._short_description}</span>}
                            </span>
                        </RouterLink>
                        <NodeInlineActions permits={links?.permits?.[link?._uri]} node={link} city={city} hasFullAccess={hasFullAccess(userState.user)} />
                    </span>
                </span>}
                {!link._uri && <span className='wrap noLink'>
                    <span className={link._published ? 'wrapLink' : 'wrapLink notPublishedWrap'}>
                    {!link._published && <NotPublishedIcon />}
                        <span className='link'>
                            {link._icon && <span className="icon">
                                <Icon icon={link._icon} />
                                {/* {!link._icon && <Icon icon="subject" />} */}
                            </span>}

                            <span className="information">
                                <span className='title'>{link.title}</span>
                                {link._short_description && <span className="description">{link._short_description}</span>}
                            </span>
                        </span>
                    </span>
                </span>}
            </li>)}
        </ul>
        {showPagination && <Grid item xs={12}>
            <Pagination pagination={links.pagination} />
        </Grid>}
    </>
}
