import {getClientConfig} from 'ultra/configs/general';

import {get} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function getNetworking(city, search) {
    let link = `${api}/networking/${city}/list`;
    if (search) link += search
    return get(link);
}
