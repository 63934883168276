import UserHistoryLink from '../../../../../Content/Widgets/UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../Helpers/user';

import NodeDetails from '../NodeDetails'

export default function UserUpdatePage(props) {
    const {values, fullAccess} = props;
    const {userState} = useGlobalCustomContext();
    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);

    return <span>
        <UserHistoryLink onlyText={!fullAccess} id={values._init_by} name={values._init_by_name} />
        <> оновив </>
        <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
    </span>
}
