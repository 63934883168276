import React from 'react';

import ErrorPage from './Modules/Content/Pages/ErrorPage';
import AppWrap from './Modules/Content/Pages/AppWrap';

import {getConfigs} from './Helpers/config'

export const routes = [
  {
    path: "/",
    element: <AppWrap />,
    loader: () => getConfigs().promise,
    errorElement: <ErrorPage />,
    children: []
  },    
]
