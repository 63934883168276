import {getClientCity} from 'ultra/configs/general';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import {DEFAULT_CITY} from 'ultra/const/general';
import {isAdmin, isAuthor} from 'ultra/helpers/auth';

import PriceWidget from '../../../../../../Widgets/Price';
import CKEditorContent from '../../../../../../Widgets/CKEditorContent';
import Gallery from '../../../../../../Widgets/Gallery';

import TitleWidget from '../../../../../../Widgets/Title';
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs';
import MessageLink from '../../../../../../Widgets/MessageLink'
import FilterChip from '../../../../../../Widgets/FilterChip'

import {useGlobalCustomContext} from '../../../../../../../../Helpers/context';
import {updateContent} from '../../../../../../../../Helpers/content';

import {useBackdropPreloaderStore} from '../../../../../../../../Stores/backdropPreloader';

import {NODE_ACTIONS} from '../../../../../../Reducers/node';


import './index.scss'

export default function Template(props) {
    const {content, form, permits, config, breadcrumbs} = props

    const {userState, nodeDispatch} = useGlobalCustomContext();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()

    const onSoldOut = () => {
        showBackdropPreloader();
        updateContent({
            city: getClientCity(window) || DEFAULT_CITY,
            uri: content._uri
        }, {
            ...content,
            soldout: true
        })
            .then(() => {
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                hideBackdropPreloader();
            })
    }

    const onPublish = () => {
        showBackdropPreloader();
        updateContent({
            city: getClientCity(window) || DEFAULT_CITY,
            uri: content._uri
        }, {
            ...content,
            canceled: true
        })
            .then(() => {
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                hideBackdropPreloader();
            })
    }

    const onPublishCancel = () => {
        showBackdropPreloader();
        updateContent({
            city: getClientCity(window) || DEFAULT_CITY,
            uri: content._uri
        }, {
            ...content,
            canceled: false,
            soldout: false
        })
            .then(() => {
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                hideBackdropPreloader();
            })
    }

    return <div className='SaleTemplate'>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <Gallery content={content.gallery} />

        {content.soldout && <div className='pseudoButton errorButton'>Продано</div>}

        {content.canceled && <div className='pseudoButton errorButton'>Не активне оголошення</div>}

        <div className='SalePrice'>
            <PriceWidget value={content.price} />
        </div>

        <div className='SaleRegion'>
            {content.region && <div className='labelWrap'>
                <span className="label">Район:</span>
                <span className="labelValue">
                    <FilterChip className="textOnly" value={content.region} config={form.fields.region} />
                </span>
            </div>}
        </div>

        <CKEditorContent content={content.description} />

        {!content.soldout && !content.canceled &&
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MessageLink
                        variant="button"
                        label="Написати автору"
                        messenger={content.messenger}
                        id={content._created_by}
                    />
                </Grid>
            </Grid>
        }

        {(isAdmin(userState?.user) || isAuthor(content, userState?.user)) && <Grid className="SaleActions" container spacing={2}>
            <Grid item xs={12}>
                <div className="SaleActionsLabel">
                    Управління оголошенням:
                </div>
            </Grid>
            {!content.soldout && !content.canceled && <Grid item md={6} xs={12}>
                <Button className="successButton" variant="outlined" fullWidth onClick={onSoldOut}>💸 Товар продано</Button>
            </Grid>}

            {!content.soldout && !content.canceled && <Grid item md={6} xs={12}>
                <Button className="errorButton" variant="outlined" fullWidth onClick={onPublish}>🚫 Зняти з публікації</Button>
            </Grid>}

            {(content.soldout || content.canceled) && <Grid item md={12} xs={12}>
                <Button className="errorSuccess" variant="outlined" fullWidth onClick={onPublishCancel}>✅ Опублікувати повторно</Button>
            </Grid>}
        </Grid>}
    </div>
}
