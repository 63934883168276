import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import Links from '../../../../../../Widgets/Links'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import LocationWidget from '../../../../../../Widgets/LocationWidget'

export default function Template(props) {
    const {content, config, children} = props

    const {contentLoading} = useNavigationStore()

    return <div>
        <CKEditorContent content={content.description} />

        <ContentImage src="banner" image={content.banner} />

        {content.location && <LocationWidget showSkeleton={contentLoading} location={content.location} />}
    </div>
}
