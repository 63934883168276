import React, {useState, useEffect} from 'react';

import Grid from '@mui/material/Grid';

import './index.scss'

export default function Subtitle(props) {
    const {disabled, field, isEditMode} = props;
    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [className] = useState(isDisabled ? 'Subtitle disabled' : 'Subtitle')

    return (<>
        <div className={className}>
            {field.placeholder}
        </div>
    </>)
}
