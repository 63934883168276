import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {useNavigationStore} from '../../../../../../../../Stores/navigation'

import LinksTileWithCategories from '../../../../../../Widgets/LinksTileWithCategories'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'

import './index.scss'

const messangersColors = {
    'telegram': '#1fa0e1',
    'whatsup': '#4caf50',
}

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    let hasCategories = false
    children.order.map(id => {
        if (hasCategories) return;

        if (children.list[id].category) {
            hasCategories = true;
        }
    })

    function ChatTitle(props) {
        const {chat} = props;
        
        return <div className='chatName'>
            {chat.type === 'telegram' && <TelegramIcon sx={{color: messangersColors[chat.type]}}/>}
            {chat.type === 'whatsup' && <WhatsAppIcon sx={{color: messangersColors[chat.type]}}/>}
            <span className='pseudoLink'>{chat.title}</span>
        </div>
    }

    return <div className='ChatsTemplate'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />
        {/* showSkeleton={contentLoading}  */}
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {config?.templateOptions?.descriptionPosition !== 'bottom' && <CKEditorContent content={content.description} />}

        <LinksTileWithCategories
            xs={6} md={3}
            permits={permits}
            
            categoriesConfig={config.categories}
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}
            isExternalLinksSection={config?.templateOptions?.isExternalLinksSection}
            title={(chat) => <ChatTitle chat={chat}/>}
        />

        {config?.templateOptions?.descriptionPosition === 'bottom' && <CKEditorContent content={content.description} />}
    </div>
}
