import {useEffect, useState} from "react";

import FormContainer from '../../../../Components/Form/FormContainer'

import {getModule} from '../../../../Helpers/module'
import {useGlobalCustomContext} from '../../../../Helpers/context';

import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import PreloaderForm from '../../../../Components/PreloaderForm';

import {USER_ACTIONS} from '../../Reducers/user';

import './index.scss';

export default function Edit(props) {
    const {user, onClose} = props;

    const [profile, setProfile] = useState();
    // const [content, setContent] = useState();
    const [formConfig, setFormConfig] = useState();

    const {hideBackdropPreloader} = useBackdropPreloaderStore()
    const {userDispatch} = useGlobalCustomContext();

    const onBeforeSubmit = () => {
        userDispatch({type: USER_ACTIONS.USER_UPDATE_IN_PROGRESS})
    }

    const onAfterSubmit = ({}) => new Promise(async (resolve) => {
        // content
        userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
        
        onClose();
        hideBackdropPreloader();

        resolve();
    })

    useEffect(() => {
        if (user) {
            // setContent({
            //     displayName: user.displayName,
            //     photoURL: user.photoURL,
            //     messenger: user.messenger,
            //     instagram: user.instagram,
            //     facebook: user.facebook,
            // })

            getModule('profile')
                .then(profile => {
                    setProfile(profile);

                    if (!profile.editForm.form.endpoint.includes(user.id)) {
                        profile.editForm.form.endpoint += `/${user.id}`;
                    }
                    setFormConfig(profile.editForm);
                })
        }
    }, [])

    if (!profile) return <PreloaderForm />;

    return <div className='ProfilePage ProfileEditPage'>
        <div className="userDetails">
            <div className="userEditForm">
                <div className="Form">
                    {user && <FormContainer
                        config={formConfig}
                        content={user}
                        beforeSubmit={onBeforeSubmit}
                        afterSubmit={onAfterSubmit}
                    />}
                </div>
            </div>
        </div>
    </div>
}
