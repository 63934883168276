var isObject = require('lodash').isObject;
var _a = require('./utils'), isFile = _a.isFile, isFilePath = _a.isFilePath;
export function isGalleryObject(gallery) {
    if (!gallery)
        return false;
    if (Object.is(gallery, null))
        return false;
    if (!isObject(gallery))
        return false;
    var hasFiles;
    var keys = Object.keys(gallery);
    if (keys.length === 0)
        return false;
    keys.map(function (name) {
        if (hasFiles)
            return;
        if (isFile(gallery[name]))
            hasFiles = true;
    });
    if (hasFiles)
        return true;
    var hasTextPath = true;
    keys.map(function (name) {
        if (!hasTextPath)
            return;
        if (typeof gallery[name] !== 'string')
            hasTextPath = false;
        if (!isFilePath(decodeURIComponent(name)))
            hasTextPath = false;
    });
    return hasTextPath;
}
