import {getClientConfig} from 'ultra/configs/general';
import {post} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export async function clearAllCache() {
    return post(`${api}/cache/all`, {});
}

export async function clearContentCache() {
    return post(`${api}/cache/content`, {});
}

export async function clearSystemCache() {
    return post(`${api}/cache/system`, {});
}
