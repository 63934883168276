import React, {useState, useEffect} from 'react';

import {isMobile} from "react-device-detect";
import {Grid} from '@mui/material';

import NumberField from '../../../../../Components/Form/Number';
import TimestampField from '../../../../../Components/Form/Timestamp';

import {DEFAULT_CHILDREN} from "ultra/const/general";

import SortDirection from '../SortDirection';

import './index.scss'

export default function Range(props) {
    const {range, activeRange, rangesSort, defaultOrder, handleChange, handleSortChange} = props;

    const [label] = useState(range.options.label);
    const [orderValue, setOrderValue] = useState(DEFAULT_CHILDREN.RANGE);
    const [rangeValues, setRangeValues] = useState({from: '', to: ''});

    const [fromField] = useState({
        id: 'from',
        placeholder: range.options.from,            
        options: range.field.type === 'timestamp' ? {
            timePicker: false
        } : {}
    })

    const [toField] = useState({
        id: 'to',
        placeholder: range.options.to,
        options: range.field.type === 'timestamp' ? {
            timePicker: false
        } : {}
    })

    useEffect(() => {
        const result = {}
        result[range.id] = rangesSort?.[range.id] || DEFAULT_CHILDREN.RANGE

        setOrderValue(result)

        const activeFrom = activeRange.find(item => item[0] === range.id && item[1] === '>=')
        const activeTo = activeRange.find(item => item[0] === range.id && item[1] === '<=')

        setRangeValues({
            from: activeFrom ? activeFrom[2] : '',
            to: activeTo ? activeTo[2] : '',
        })
    }, [])

    const onFromChangedHandler = (e) => {
        setRangeValues({
            ...rangeValues,
            from: e.target.value
        })
        
        handleChange({
            type: 'from',
            field: range.id,
            value: e.target.value
        })
    }

    const onToChangedHandler = (e) => {
        setRangeValues({
            ...rangeValues,
            to: e.target.value
        })

        handleChange({
            type: 'to',
            field: range.id,
            value: e.target.value
        })
    }

    return <Grid item xs={12}>
        <div className='FilterWidgetRangeLabel'>
            {label}:
        </div>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {range.field.type === 'number' && <NumberField
                    content={rangeValues}
                    field={fromField}
                    onChange={onFromChangedHandler}
                />}

                {range.field.type === 'timestamp' && <TimestampField
                    content={rangeValues}
                    field={fromField}
                    onChange={onFromChangedHandler}
                />}
            </Grid>
            <Grid item xs={6}>
                {range.field.type === 'number' && <NumberField
                    content={rangeValues}
                    field={toField}
                    onChange={onToChangedHandler}
                />}

                {range.field.type === 'timestamp' && <TimestampField
                    content={rangeValues}
                    field={toField}
                    onChange={onToChangedHandler}
                />}
            </Grid>
            <Grid item xs={12} className="labelWrap sortRangeWrap">
                <span className="label">Сортувати:</span>
                <span className="labelValue">
                    <SortDirection
                        defaultOrder={defaultOrder}
                        activeOrder={orderValue}
                        handleSortDirectionChange={handleSortChange}
                    />
                </span>
            </Grid>
        </Grid>
    </Grid>
}
