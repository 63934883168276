import React, {useEffect, useState} from 'react';

import {useLoaderData, useNavigation} from "react-router-dom";

import {isMobile} from "react-device-detect";

import {hasFullAccess} from 'ultra/helpers/auth';

import {useGlobalCustomContext} from '../../../../Helpers/context';

import {getUsers} from '../../Helpers/admin';

import {getModule} from '../../../../Helpers/module'
// import {attachUsersForFields} from '../../Helpers/user';

import UserInlineActions from '../../Components/UserInlineActions'
import PreloaderTable from '../../../../Components/PreloaderTable'
import UserAdminActions from '../../Components/UserAdminActions'

import TableView from '../../../Content/Widgets/Table'
import BreadcrumbsWidget from '../../../Content/Widgets/Breadcrumbs'
import TitleWidget from '../../../Content/Widgets/Title'
import FiltersLine from '../../../Content/Widgets/FiltersLine';

// import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import {NODE_ACTIONS} from '../../../Content/Reducers/node';

import {setPageTitle} from '../../../../Helpers/router';

import './index.scss';

const TITLE = 'Користувачі';

function attachExtraUserFields(fields) {
    fields = {
        ...fields,
        email: {
            type: 'text',
            placeholder: 'Email'
        },
        id: {
            type: 'text',
            placeholder: 'User ID'
        },
        emailNotVerified: {
            type: 'boolean',
            placeholder: 'Підтверджений email',
            options: {
                labels: {
                    true: 'ні',
                    false: 'так'
                }
            }
        },
        sendEmailVerification: {
            type: 'boolean',
            placeholder: 'Запит на верифікацію email',
            options: {
                labels: {
                    true: 'відправлено',
                }
            }
        },
        _not_checked: {
            type: 'boolean',
            placeholder: 'Перевірка',
            options: {
                labels: {
                    true: 'не проводилась',
                }
            }
        },
        _blocked: {
            type: 'boolean',
            placeholder: 'Заблоковано',
            options: {
                labels: {
                    true: 'так',
                }
            }
        },
        __blocked_comment: {
            type: 'text',
            placeholder: 'Причина блокування',
        },
        creationTime: {
            type: 'text',
            placeholder: 'Створено'
        },
        userStatus: {
            type: 'userStatus',
            placeholder: 'Останній вхід'
        }
    }

    fields.displayName.type = 'user-title';

    return fields;
}

export default () => {
    const rawUsers = useLoaderData();

    const navigation = useNavigation();

    // const userFields = ['id'];

    const {nodeState, userState, nodeDispatch} = useGlobalCustomContext();
    // const {hideBackdropPreloader} = useBackdropPreloaderStore();

    const [users, setUsers] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [fields, setFields] = useState();

    useEffect(() => {
        getModule('profile').then(profile => {
            setFields(attachExtraUserFields(profile.editForm.fields));
        })

        setTableOptions(isMobile ? {
            visible: ['photoURL', 'displayName'],
            hidden: ['_blocked', '__blocked_comment', 'email', 'emailNotVerified', 'messenger', 'facebook', 'instagram', 'sendEmailVerification', '_not_checked', 'creationTime', 'lastSignInTime', 'id'],
            noHeaders: true
        } : {
            visible: ['photoURL', 'displayName', 'messenger', 'creationTime'],
            hidden: ['_blocked', '__blocked_comment', 'email', 'emailNotVerified', 'facebook', 'instagram', 'sendEmailVerification', '_not_checked', 'lastSignInTime', 'id']
        })

        // updateNodes(rawUsers);

        setPageTitle(TITLE);
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState])

    // useEffect(() => {
    //     if (userState?.userRefreshStarted) {
    //         nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
    //     }
    // }, [userState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateNodes(rawUsers)
        }
    }, [navigation])

    function updateNodes(nodes) {
        setUsers(nodes)
        // attachUsersForFields(nodes, userFields)
        //     .then(() => {
        //         setUsers(nodes);
        //         hideBackdropPreloader();
        //     })
    }

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateNodes({});
        getUsers(url.search)
            .promise
            .then(updateNodes)
    }

    const selectClasses = (data) => {
        if (data?._not_checked) {
            return 'not_checked'
        }
        if (data?._blocked) {
            return 'blocked'
        }
    }

    const content = {
        title: TITLE
    }

    const permits = {
        children: {
            read: ['node']
        }
    }

    const config = {
        showFilters: {
            filterByInline: [
                {
                    id: 'users',
                    field: {
                        default: 'not_checked',
                        type: "complex",
                        placeholder: "Фільтр",
                        options: {
                            hasNoEmptyValue: true,
                        },
                        values: {
                            values: {
                                not_checked: "Неперевірені користувачі",
                                checked: "Перевірені користувачі",
                                blocked: "Заблоковані",
                            },
                            order: [
                                'not_checked',
                                'blocked',
                                'checked',
                            ]
                        }
                    }
                },
            ]
        }
    }

    const breadcrumbs = {}

    return <div id="Content" className='Content'>
                <div className='ProfileUsersPage'>
                    <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />
                    <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs}/>

                    {isMobile && <div className='MobileFiltersLine'>
                        <FiltersLine
                            size="small"
                            filters={config?.showFilters?.filterByInline}
                        />
                    </div>}

                    {!fields && <PreloaderTable/>}

                    {fields && users && <TableView
                        showPreloader={!users?.list}
                        content={users}
                        fields={fields}
                        showPagination={true}
                        tableOptions={tableOptions}
                        actions={(id) => UserInlineActions({
                            user: users.list[id],
                            unfolded: isMobile ? true : false,
                            hasFullAccess: hasFullAccess(userState.user)
                        })}
                        selectClasses={selectClasses}
                    />}
                </div>

                <UserAdminActions />
            </div>
}
