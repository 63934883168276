import {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import {isMobile} from "react-device-detect";

import Grid from '@mui/material/Grid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getTime, getFormatedDate, timestampToDate, getFormatedTime} from 'ultra/helpers/date';
import {getShopDetails} from 'ultra/helpers/shop';

import {useConfigStore} from '../../../../../../../../Stores/config';

import ShopProductPrice from '../../../../../../Widgets/ShopProductPrice'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import ShopingCart from '../../../../../../Widgets/ShopingCart'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import AddToCalendar from '../../../../../../Widgets/AddToCalendar'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'

import Gallery from '../../../../../../Widgets/Gallery';

import './index.scss'

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props

    const {shop} = getShopDetails(content, breadcrumbs)

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const [days, setDays] = useState()

    useEffect(() => {
        const start = getTime(timestampToDate(content.date), timeZone);
        const end = start.add(content.duration, 'hour').subtract(1, 'second');

        if (start.date() !== end.date()) {
            setDays(1 + end.date() - start.date());
        } else {
            setDays(1);
        }
    }, [])

    return <div className="Retreat">
        <TopPagePicture cutHeightForDesktop image={content.banner}/>

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <div className="details">
            {!isMobile && <div className="labelWrap price">
                <span className="label">Ціна:</span>
                <span className="labelValue">
                    <ShopProductPrice product={content} currency={shop?.partner?.config?.currency} />
                </span>
            </div>}

            {!isMobile && <ShopingCart
                fullWidth
                item={content}
                shop={shop}
            />
            }

            {isMobile && <ShopingCart
                fullWidth
                item={content}
                shop={shop}
                price={content.price}
            />}

        </div>

        {days === 1 && content.date && <div className='date'>
            {content.date && <div className='labelWrap'>
                <span className="label">Дата та час:</span>
                <span className="labelValue">
                    {getFormatedDate(getFormatedTime(content.date, timeZone))}
                </span>
            </div>}
        </div>
        }

        {days > 1 && content.date && <div className='date'>
            {content.date && <div className='dates'><CalendarMonthIcon /> {getFormatedTime(timestampToDate(content.date), timeZone)} - {getFormatedTime(dayjs(timestampToDate(content.date)).add(content.duration, 'hour', timeZone), timeZone)}</div>}
        </div>
        }

        <CKEditorContent content={content.description} />

        <Gallery content={content.gallery} />

        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <AddToCalendar
                    title={content.title}
                    date={content.date}
                    duration={content.duration}
                    // details=''
                    location={content.place}
                />
            </Grid>
        </Grid>

    </div>
}
