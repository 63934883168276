import {getClientConfig} from 'ultra/configs/general';
import {GoogleMap, useLoadScript, MarkerF} from '@react-google-maps/api';
import Skeleton from '@mui/material/Skeleton';

import './index.scss'

export default function LocationWidget(props) {
    const {location, showSkeleton} = props;

    const {firebase} = getClientConfig(window);
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: firebase.apiKey
    });

    if (showSkeleton) {
        return <div className='LocationWidget'>
            <Skeleton variant="rectangular" width="100%" height={200} />
        </div>
    }

    if (loadError) {
        return <div>Google Maps error loading</div>;
    }

    if (!isLoaded) {
        return <div>Google Maps loading</div>;
    }

    return <div className='LocationWidget'>
      <div className='labelWrap'>
        <span className="label">Локація:</span>
        <span className="labelValue">
            <a href={location.link} target='_blank'>{location.title || location.link}</a>
        </span>
      </div>

      {location.lat && location.lng && <div className='LocationWidgetMap'><GoogleMap
        mapContainerStyle={{
            width: '100%',
            height: '300px',
            borderRadius: '15px'
        }}
        zoom={18}
        center={{
            lat: Number(location.lat),
            lng: Number(location.lng)
        }}
      >
        <MarkerF
            position={{
                lat: Number(location.lat),
                lng: Number(location.lng)
            }}
            // icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
        />
      </GoogleMap></div>}
    </div>
}
