import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import DehazeIcon from '@mui/icons-material/Dehaze';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';
import CalendarWidget from '../../Widgets/Calendar';

import './index.scss'

export default function CalendarButton(props) {
    const location = useLocation();
    // const navigate = useNavigate();

    const [view, setView] = useState();
    const [open, setOpen] = useState(false);
    const [navigationInProgress, setNavigationInProgress] = useState(false);
    
    // const {showBackdropPreloader} = useBackdropPreloaderStore()

    useEffect(() => {
        const url = new URL(window.location.href);
        setView(url.searchParams.get('view') || 'list');
    }, [])

    useEffect(() => {
        const url = new URL(window.location.href);
        setView(url.searchParams.get('view') || 'list');
        setNavigationInProgress(false);
    }, [location])

    const handleClickOpen = (e, view) => {
        if (navigationInProgress) {
            e.preventDefault();
            return;
        }

        // const url = new URL(window.location.href);

        setView(view)

        if (view === 'calendar') {
            document.body.classList.add('show_calendar')

            // url.searchParams.delete('view')
            // url.searchParams.delete('perPage')
            // url.searchParams.delete('page')
        }
        else if (view === 'list') {
            document.body.classList.remove('show_calendar')

            // url.searchParams.set('view', 'list')
            // url.searchParams.delete('perPage')
            // url.searchParams.delete('page')
            // url.searchParams.delete('ranges')
        }

        // setNavigationInProgress(true);
        // showBackdropPreloader();
        // navigate(url.pathname + url.search);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return <React.Fragment>
        <ToggleButtonGroup
                value={view}
                exclusive
                size='small'
                onChange={handleClickOpen}
            >

            <ToggleButton value="calendar">
                <CalendarMonthIcon />
            </ToggleButton>

            <ToggleButton value="list">
                <ViewStreamOutlinedIcon />
            </ToggleButton>

        </ToggleButtonGroup>

        <Dialog onClose={handleClose} open={open} className='CalendarWidgetDialog'>
            <DialogContent>
                <div className='CalendarWidgetTitle'>
                    <CloseIcon onClick={handleClose} className='CalendarClose' />
                    <div className='CalendarTitle'>Календар</div>
                </div>

                <CalendarWidget field="date" />
            </DialogContent>
        </Dialog>
    </React.Fragment>
}
