exports.ROLES = {
    ADMIN: 'admin',
    MODERATOR: 'moderator',
    LIBRARY: 'library',
    EXCHANGE: 'exchange',
    OWNER: 'owner',
    IDENTIFIED: 'identified',
    ANONYMOUS: 'anonymous',
    TELEGRAM: 'telegram',
    VERIFIED: 'verified',
    NOT_VERIFIED: 'not_verified',
    BLOCKED: 'blocked',
    NOT_BLOCKED: 'not_blocked',
    CHECKED: 'checked',
    NOT_CHECKED: 'not_checked',
};
