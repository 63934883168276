import {getShopDetails} from 'ultra/helpers/shop';
import { Link as RouterLink } from "react-router-dom";
import {useNavigate} from "react-router-dom";

import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {useNavigationStore} from '../../../../../../../Stores/navigation';

import LinksLines from '../../../../../Widgets/LinksLines'
import BreadcrumbsWidget from '../../../../../Widgets/Breadcrumbs';
import TitleWidget from '../../../../../Widgets/Title';
import ShopingCart from '../../../../../Widgets/ShopingCart'
import FilterChip from '../../../../../Widgets/FilterChip';

import './index.scss'

export default function Template(props) {
    const {content, children, config, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()
    const navigate = useNavigate();

    const onDetailsClick = (link) => {
        navigate(link)
    }

    return <div className="ServicesTemplate">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <LinksLines
            config={config}

            permits={permits}
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}

            title={item => <div className='ServiceTitle'>
                <div><RouterLink to={item?._uri?.slice(1)} className="LinksTileImageLink">{item.title}</RouterLink></div>
                <div className='SaleItemPrice'>
                    {item._type !== 'shop' && <ShopingCart
                        item={item}
                        price={item.price}
                        shop={getShopDetails(item, breadcrumbs)?.shop}
                    />}
                    {item._type === 'shop' && <Button
                            variant="contained"
                            onClick={() => onDetailsClick(item?._uri?.slice(1))}
                        >
                            <InfoOutlinedIcon />
                            &nbsp;
                            Детальніше
                    </Button>}
                </div>
            </div>}

            label={(item) => <div className='ServiceCategories'>
                {item?.category?.map(c => <FilterChip value={c} config={config.categories} />)}
            </div>}
        />
    </div>
}
