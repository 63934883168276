import {useEffect, useState} from "react";

// import {DEFAULT_CITY} from 'ultra/const/general';
// import {getClientCity} from 'ultra/configs/general';
// import {getMessengerLink} from 'ultra/helpers/messengers';
// import {MESSENGER_COLORS} from 'ultra/const/messengers';

// import Button from '@mui/material/Button';
// import TelegramIcon from '@mui/icons-material/Telegram';

import UserMessageLink from '../../../../../../../Widgets/UserMessageLink'
// import {getTelegramUser} from '../../../../../../../../../Helpers/telegram'

export default function MessageToOwner(props) {
    const {book, libraryModule, onClick, belongsToLibrary} = props;

    const [title, setTitle] = useState()
    const [userId, setUserId] = useState()
    // const [telegram, setTelegram] = useState()
    // const [city] = useState(getClientCity(window) || DEFAULT_CITY)

    const [loading, setLoading] = useState()

    useEffect(() => {
        setLoading(true)

        // TODO: move to endpoint
        // const user = libraryModule.librarians[city][book.region];
        setTitle(belongsToLibrary ? 'Написати бібліотекарю' : 'Написати власнику')
    
        setUserId(book?._created_by)
        setLoading(false);
    }, [book, libraryModule])

    // close window

    if (belongsToLibrary) return <></>

    if (userId) {
        return <UserMessageLink fullWidth loading={loading} variant="button" label={title} id={userId} onClick={onClick} />
    }

    // if (telegram) {
    //     return <Button
    //             startIcon={<TelegramIcon sx={{color: MESSENGER_COLORS['telegram']}} />}
    //             variant="outlined"
    //             fullWidth
    //             className="UserMessageLink"
    //             target="_blank"
    //             href={getMessengerLink({telegram: {number: telegram}})}>
    //                 {title}
    //         </Button>
    // }

    return <></>
}
