import {useEffect, useState} from "react";

import Chip from '@mui/material/Chip';

export default function WorkerName(props) {
    const {details} = props;
    
    const [worker, setWorker] = useState()

    useEffect(() => {
        if (details) {
            setWorker(<Chip variant="outlined" label={'worker: ' + Object.keys(details)[0]} />)
        }
    }, [details])
    
    if (!details) return <></>

    if (!worker) return <></>

    return worker
}
