import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import ShopProductsTable from '../../../../../../Widgets/ShopProductsTable'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'

export default function Template(props) {
    const {content, permits, config, children, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    return <div>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <CKEditorContent showSkeleton={contentLoading} content={content.description} />

        <ShopProductsTable showSkeleton={contentLoading} links={children} content={content} breadcrumbs={breadcrumbs} />
    </div>
}
