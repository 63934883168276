import {useState} from "react";
import {isString} from "lodash";

import UserMessageLink from '../UserMessageLink'

export default function MessageToReader(props) {
    const {reading} = props;

    const [title] = useState('Написати')

    if (isString(reading)) {
        return <UserMessageLink variant="button" label={title} id={reading} fullWidth />
    }
    else {
        return <></>
    }
}
