import {isMobile} from "react-device-detect";
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {useGlobalCustomContext} from '../../../../../../../../Helpers/context';
// import {isYoursAccount} from '../../../../../../../../Helpers/user';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'

import FilterChip from '../../../../../../Widgets/FilterChip'
import FilterMultiChip from '../../../../../../Widgets/FilterMultiChip'

import ContentImage from '../../../../../../Widgets/ContentImage';
import NetworkingRequest from '../../../../../../Widgets/NetworkingRequest'
import URLValue from '../../../../../../Widgets/URLValue'

import PreloaderThumbnailNode from '../../../../../../../../Components/PreloaderThumbnailNode'

import './index.scss'

export default function Template(props) {
    const {form, content, config, children, permits, breadcrumbs} = props

    const {configs} = useConfigStore();

    const {userState} = useGlobalCustomContext();
    const {contentLoading} = useNavigationStore()

    let hasCategories = false
    children.order.map(id => {
        if (hasCategories) return;

        if (children.list[id].category) {
            hasCategories = true;
        }
    })

    // const onEnableNotifications = () => {
    //     Notification.requestPermission().then(function(result) {
    //     });

    //     Notification.requestPermission()
    //         .then(function(permission) {
    //         });
    // }

    return <div className='NetworkingTemplate'>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>
        
        {contentLoading && <PreloaderThumbnailNode />}

        {!contentLoading && content.thumbnail && isMobile && <div className="photoWrap">
            {content.status && <div className="photoLabel">
                <FilterChip value={content.status} config={configs.content.nodes[content._type].fields.status} />
            </div>}
            <ContentImage src="thumbnail" image={content.thumbnail} className="thumbnail" />
        </div>
        }

        {!contentLoading && !isMobile && <div className='ContactCard'>
            <div className="photoWrap">
                {content.status && <div className="photoLabel">
                    <FilterChip value={content.status} config={configs.content.nodes[content._type].fields.status} />
                </div>}
                <ContentImage src="thumbnail" image={content.thumbnail} className="thumbnail" />
            </div>

            <NetworkingRequest id={content._created_by} />
            <URLValue className="pseudoButton" value={content.link} />
        </div>}

        {!contentLoading && <div className='NetworkingDescription'>
            {isMobile && <div className='ContactCard'>
                <NetworkingRequest id={content._created_by} />
                <URLValue className="pseudoButton" value={content.link} />
            </div>}

            {content.direction && <div className='labelWrap labelWrapRow networkingDirection'>
                <span className="label">Напрямок:</span>
                <span className="labelValue">
                    <FilterMultiChip className="textOnly" values={content?.direction} config={form.fields.direction}/>
                </span>
            </div>}

            {content.region && <div className='labelWrap labelWrapRow region'>
                <span className="label">Район:</span>
                <span className="labelValue">
                    <FilterChip className="textOnly" value={content.region} config={form.fields.region} />
                </span>
            </div>}

            {content.description && <div className="NetworkingInfo"><CKEditorContent content={content.description} /></div>}

            {/* {('Notification' in window) && (isYoursAccount(userState, content._created_by)) && <div className="infoBlock">
                <InfoOutlinedIcon />
                <span>
                    Щоб одразу отримувати сповіщення про заявку від користувачів що зацікавились вашою анкетою, не забудьте <span className="pseudoLink" onClick={onEnableNotifications}>увімкнути нотифікацію в браузері</span>
                </span>
            </div>} */}
        </div>}
    </div>
}
