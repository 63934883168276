import React, {useEffect, useState} from 'react';

import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {countries as countriesList} from 'ultra/configs/countries';

import {useConfigStore} from '../../../../Stores/config';
import {ukLocale} from '../../../../Helpers/locale';

import './index.css';

export default function Form(props) {
    const {from, to, clients, children, coverage, countries, sport, insuranceConfig} = props;

    const uaIndex = countriesList.indexOf('Україна')
    let list = countriesList.slice(0, uaIndex).concat(countriesList.slice(uaIndex + 1));
    const ruIndex = countriesList.indexOf('російська федерація')
    list = list.slice(0, ruIndex).concat(list.slice(ruIndex + 1));
    const brIndex = countriesList.indexOf('Білорусь')
    list = list.slice(0, brIndex).concat(list.slice(brIndex + 1));

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
      const city = getClientCity(window) || DEFAULT_CITY;
      setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    let maxDays = 3
    Object.keys(insuranceConfig.options).map(company => {
      const companyConfig = insuranceConfig.options[company]

      if (companyConfig.maxTerm > maxDays) maxDays = companyConfig.maxTerm
    })
    maxDays = maxDays - 1;
  
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete
              multiple
              freeSolo

              fullWidth

              options={list.map((option) => option)}

              value={countries}

              onChange={(e, value) => {
                  props.onCountriesChanged({target: {value}})
              }}

              renderTags={(value, get) =>
                  value.map((option, index) => {
                    const props = get({index});
                    return <Chip variant="outlined" label={option} key={props.key} className={props.className}/>;
                  })
              }

              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant='outlined'
                      label='Територія дії'

                      placeholder='Введіть країну та натисніть Enter'
                  />
              )}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk" localeText={ukLocale}>
            <DatePicker
              closeOnSelect
              className='DatePicker'
              value={from ? dayjs(from) : null}
              minDate={dayjs().add(1, 'day')}
              onChange={props.onStartDateChange}
              label="Початок"
              timezone={timeZone}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk" localeText={ukLocale}>
            <DatePicker
              closeOnSelect
              className='DatePicker'
              disabled={!from}
              value={to ? dayjs(to) : null}
              minDate={dayjs(from).add(2, 'day')}
              maxDate={dayjs(from).add(maxDays, 'day')}
              onChange={props.onEndDateChange}
              label="Завершення"
              timezone={timeZone}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Кількість осіб</InputLabel>
            <Select
              value={clients}
              label="Кількість осіб"
              onChange={props.onClientsAmountChange}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Покриття</InputLabel>
            <Select
              value={coverage}
              label="Покриття"
              onChange={props.onCoverageChange}
            >
              <MenuItem value="Всі доступні">Всі доступні</MenuItem>
              <MenuItem value="30000$">30 000$</MenuItem>
              <MenuItem value="50000$">50 000$</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Діти (2-14)</InputLabel>
            <Select
              value={children}
              label="Діти (2-14)"
              onChange={props.onChildrensAmountChange}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} className="info">
          * Дітям від 2 до 14 років знижка {insuranceConfig.coefficients.arx.discounts.children}%
        </Grid> */}
        <Grid item xs={12} md={8}>
          <FormGroup>
            <FormControlLabel
              className='InsuranceSport'
              control={
                <Checkbox checked={sport} onChange={props.onSportsOptionChange} />
              }
              label="Планую активний відпочинок або заняття спортом"
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
}
