// TODO: add preloader and handler for multiple images
import React, {useRef, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import {getStoreFileName} from 'ultra/helpers/storage';
import {getFileName} from 'ultra/helpers/files';
import {isFile} from 'ultra/helpers/utils';

import {useToasterStore} from '../../../Stores/toster';
import ContentImage from '../../../Modules/Content/Widgets/ContentImage';

import './index.scss';

export default function Gallery(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const inputRef = useRef(null);
    let [textValue, setTextValue] = useState('')
    const [ammount, setAmmount] = useState(0);
    const [sizeInfo, setSizeInfo] = useState(null);
    const [value, setValue] = useState(content[field.id])
    const [placeholder] = useState(field.placeholder ? field.placeholder + (field.required ? ' *' : '') : undefined)
    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])
    const [actionClassName, setActionClassName] = useState(showErrorText ? 'action error' : 'action')
    const [textFieldClassName, setTextFieldClassName] = useState()

    function getTextFieldClassName() {
        let textFieldClassName = isDisabled ? 'filesList' : 'filesList emulateEnabled';
        textFieldClassName += showErrorText ? ' error' : ''
        return textFieldClassName
    }

    const {showError} = useToasterStore();

    useEffect(() => {
        if (content[field.id]) {
            setValue(content[field.id]);
            let count = 0
            Object.keys(content[field.id]).map(i => {
                if (!getFile(content[field.id][i]).removed) {
                    count += 1
                }
            })
            setAmmount(count || 0);
        }
        updateTextValue()
    }, [content[field.id]])

    useEffect(() => {
        if (field?.options?.size) {
            setSizeInfo(`Рекомендовані розміри зображення - ${field?.options?.size.width} x ${field?.options?.size.height}`)
        }

        setTextFieldClassName(getTextFieldClassName());
    }, [])

    useEffect(() => {
        setTextFieldClassName(getTextFieldClassName());
        setActionClassName(showErrorText ? 'action error' : 'action')
    }, [showErrorText])

    const onButtonClick = (e) => {
        inputRef.current.click()
    }

    const updateTextValue = () => {
        let calc = 0
        value && Object.keys(value).map(i => {
            if (isFile(value[i]) && value[i].size > 0) {
                calc++
            }
            if (value[i] && typeof value[i] === 'string')  {
                calc++
            }
        })

        calc > 0 ? setTextValue(`Додано ${calc} файлів`) : setTextValue('')
    }

    const onRemoveHandler = (name) => {
        inputRef.current.value = null;
        onChange({target: {files: [new File([''], name)]}})
    }

    function getFile(file) {
        let img, name, removed;

        if (isFile(file)) {
            name = getFileName(file)
            img = URL.createObjectURL(file)
            if (file.size === 0) removed = true
        } else if (typeof file === 'string') {
            name = getStoreFileName(file)
            img = file
        }

        return {img, name, removed}
    }

    return (<div className='GalleryUploadField'>
        <div className='fileUploadButton'>
            <div className='details'>
                <input disabled={isDisabled} multiple className='originalControl' accept="image/*" type="file" ref={inputRef}  onChange={e => {
                    const a = ammount + e.target.files.length;

                    if (field?.options?.max && field?.options?.max < a) {
                        showError({snackbarMessage: `Ви можете прикріпити не більше ${field?.options?.max} файлів`});
                        return;
                    }

                    setAmmount(a);
                    onChange(e);
                    inputRef.current.value = null;
                }}/>

                <TextField
                    disabled
                    fullWidth
                    className={textFieldClassName}
                    variant="outlined"
                    label={placeholder}
                    value={textValue}
                    error={Boolean(showErrorText)}
                    helperText={showErrorText}
                />

                <Button
                    className={actionClassName}
                    variant="contained"
                    component="label"
                    tabIndex={-1}
                    onClick={onButtonClick}
                >
                    <AddIcon />
                </Button>
            </div>

            <div className='fieldNotes'>
                {sizeInfo && <p>* {sizeInfo}</p>}
                {field?.options?.max && <p>* Прикріпити можливо не більше {field?.options?.max} файлів</p>}
                {field.description && <p>* {field.description}</p>}
            </div>
        </div>

        {value && Object.keys(value).length > 0 && <Grid container spacing={2} className='formPhotos'>
            {Object.keys(value)
                .map((key) =>
                    (!getFile(value[key]).removed) && <Grid item xs={6} md={4} key={getFile(value[key]).name}><div className="photo">
                        <span className='imgWrap'>
                            <span className='close' onClick={() => onRemoveHandler(getFile(value[key]).name)}>
                                <ClearIcon />
                            </span>

                            {getFile(value[key]).img.indexOf('http') !== 0 && <ContentImage image={getFile(value[key]).img} />}
                            {getFile(value[key]).img.indexOf('http') === 0 && <img loading="lazy" src={getFile(value[key]).img} />}
                        </span>
                        <span className='name'>
                            {getFile(value[key]).name}
                        </span>
                    </div></Grid>
                )}
        </Grid>}
    </div>)
}
