import {getClientConfig} from 'ultra/configs/general';
import {cachedGet} from './http';

const {api} = getClientConfig(window)

export function getModule(module) {
    return cachedGet(`${api}/module/${module}`);
}

export function getInsuranceConfig() {
    return cachedGet(`${api}/config/content/insurance`);
}
