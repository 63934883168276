import { NavLink as RouterLink } from "react-router-dom";

function getDocumentHistory(city, did) {
    return `/profile/history/node/${city}/${did}`
}

export default function DocumentHistoryLink(props) {
    const {city, details, onlyText} = props;

    if (!details) return <></>
    if (onlyText) return details?.title

    return <RouterLink to={getDocumentHistory(city, details._did)}>{details.title}</RouterLink>
}
