var isNull = require('lodash').isNull;
var dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
require('dayjs/locale/uk');
export function getTime(date, timeZone) {
    if (isNull(date) || !date)
        return null;
    dayjs.extend(timezone);
    dayjs.extend(utc);
    date = dayjs(date);
    return date.locale('uk').tz(timeZone);
}
export function getTimezoneNameString(date, timeZone) {
    return "GMT".concat(getTime(date, timeZone).format('Z'), " (").concat(timeZone, ")");
}
export function getTimezoneOffset(timeZone) {
    var date = new Date();
    var utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    var tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
    return (tzDate.getTime(new Date(), timeZone) - utcDate.getTime(new Date(), timeZone)) / 6e4;
}
function formatedTime(date, timeZone, format) {
    if (format === void 0) { format = 'DD.MM.YYYY | HH:mm'; }
    if (isNull(date))
        return null;
    return getTime(date, timeZone).format(format);
}
function formatedDate(date, timeZone, format) {
    if (format === void 0) { format = 'DD.MM.YYYY'; }
    if (isNull(date))
        return null;
    return getTime(date, timeZone).format(format);
}
export var getFormatedTime = formatedTime;
export var getFormatedDate = formatedDate;
export var isTimestamp = function (date) {
    if (!(date === null || date === void 0 ? void 0 : date._seconds))
        return false;
    return true;
};
export var TimestempInPast = function (date) {
    var result = false;
    if (isTimestamp(date)) {
        var d = timestampToDate(date);
        result = dayjs().diff(d, 'second') > 0;
    }
    return result;
};
export var timestampToDate = function (timestamp) {
    if (isTimestamp(timestamp)) {
        return new Date(timestamp._seconds * 1000);
    }
    return null;
};
export var isSameTimestamp = function (timestamp1, timestamp2) {
    if (timestamp1._seconds !== timestamp2._seconds)
        return false;
    if (timestamp1._nanoseconds !== timestamp2._nanoseconds)
        return false;
    return true;
};
