import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {useNavigation, useLocation} from 'react-router-dom';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getTimezoneOffset, getTime, timestampToDate} from 'ultra/helpers/date'

import {getNodeContent} from '../../../../Helpers/content';

import {useConfigStore} from '../../../../Stores/config';

import {ukLocale} from '../../../../Helpers/locale';

import PreloaderCalendar from '../../../../Components/PreloaderCalendar';

import './index.scss'

dayjs.extend(utc);
dayjs.extend(timezone);

function getChildrenByDate(nodes = {}, day, configs) {
    const result = {}
    const city = getClientCity(window) || DEFAULT_CITY;
    const timeZone = getClientCityTimezone(configs, city)

    Object.keys(nodes).map(i => {
        const date = getTime(timestampToDate(nodes[i].date), timeZone);
        // console.log(day.date(), date.date())
        // || day.month() != date
        if (day.date() != date.date()) return;

        result[i] = {...nodes[i]};
    })

    // console.log(nodes)
    // console.log(day)
    // console.log(configs)

    return result;
}

function Day(props) {
    const { selectedChildren = {}, day, outsideCurrentMonth, ...other } = props;

    const {configs} = useConfigStore();

    const nodes = getChildrenByDate(selectedChildren?.list, props.day, configs)
    const amount = Object.keys(nodes).length

    return (
      <Badge
        key={props.day.toString()}
        color='primary'
        badgeContent={!props.outsideCurrentMonth && amount || undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
}

export default function Calendar(props) {
    const {field, monthes} = props;

    const navigation = useNavigation();
    const location = useLocation();

    const navigate = useNavigate();

    const [controllers, setControllers] = useState([])
    const [updateCalendarInProgres, setUpdateCalendarInProgres] = useState(true)
    
    const [currentRange, setCurrentRange] = useState()

    const [selectedRangeFrom, setSelectedRangeFrom] = useState()
    const [selectedChildren, setSelectedChildren] = useState()
    const [selectedDate, setSelectedDate] = useState()

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const onDateChanged = (selectedChildren, date) => {
        setSelectedDate(dayjs(date));
        switchToRange(getTime(date, timeZone), 'day');
    }

    const onMonthChanged = (date) => {
        setSelectedDate(null);
        switchToRange(getTime(date, timeZone), 'month');
    }

    const switchToRange = (date, period) => {
        const url = new URL(window.location.href);

        const from = [field, '>=', date.startOf(period).valueOf()];
        const to = [field, '<=', date.endOf(period).valueOf()];

        url.searchParams.set('ranges', JSON.stringify([from, to]));

        navigate(url.pathname + url.search);
    }

    const updateCalendar = (start) => {
        setUpdateCalendarInProgres(true);
        clearRequests();

        // !!! TODO: return only CHILDREN HERE
        const {promise, controller} = getNodeContent(getCurrentMonthRangeUrl(start));
        setControllers(controllers.concat([controller]));

        promise
            .then(node => {
                setSelectedChildren(node.children);

                setUpdateCalendarInProgres(false);
            })
    }

    const getCurrentDateFromUrl = (search) => {
        const url = new URL(window.location.origin + window.location.pathname + search);
        const ranges = url.searchParams.get('ranges');
        let date;

        if (ranges) {
            let activeRanges = JSON.parse(ranges);
            const from = activeRanges?.find(i => i[0] === 'date' && i[1] === '>=');
            const to = activeRanges?.find(i => i[0] === 'date' && i[1] === '<=');

            // used (to[2] + 1), because it has end of the day, and next second will move to start of next day
            const days = ((to[2] + 1) - from[2])/1000/60/60/24

            if (ranges && days === 1) {
                date = JSON.parse(ranges).find(i => i[1] === '>=')[2];
            }
        }

        return date;
    }

    const getRangeFromUrlSearch = (search) => {
        const currentDay = getCurrentDateFromUrl(search);

        if (currentDay) return undefined;

        const url = new URL(window.location.origin + window.location.pathname + search);
        const ranges = url.searchParams.get('ranges');
        let activeRanges = JSON.parse(ranges);
        const from = activeRanges?.find(i => i[0] === 'date' && i[1] === '>=');
        const to = activeRanges?.find(i => i[0] === 'date' && i[1] === '<=');

        if (!from || !to) return undefined;

        return {from: from[2], to: to[2]}
    }

    const formatRangeForUrl = (range) => {
        const from = [field, '>=', range.from];
        const to = [field, '<=', range.to];    
        return [from, to];
    }

    const getMonthRange = (date) => {
        return {
            from: date.startOf('month').valueOf(),
            to: date.endOf('month').valueOf()
        };
    }

    const getCurrentMonthRangeUrl = (date) => {
        const url = new URL(window.location.href);

        let urlRange = formatRangeForUrl(getMonthRange(date))

        url.searchParams.set('ranges', JSON.stringify(urlRange));
        url.searchParams.set('showPagination', 'false');

        const city = getClientCity(window) || DEFAULT_CITY;

        return {
            city,
            uri: url.pathname + url.search
        };
    }

    const clearRequests = () => {
        controllers.map(controller => {
            controller.abort()
        })
    }

    useEffect(() => {
        function isInRange(r1, r2) {
            return JSON.stringify(r1) === JSON.stringify(r2);
        }

        // go to another page
        if (navigation?.state
            && navigation?.location?.pathname
            && navigation?.state === 'loading'
            && window.location.pathname !== navigation?.location?.pathname) {
                clearRequests();
                setUpdateCalendarInProgres(true);
        }

        const currentDay = getCurrentDateFromUrl(navigation?.location?.search || location.search);
        let selectedRange = getRangeFromUrlSearch(navigation?.location?.search || location.search);

        // page with no filters opened
        if (!currentDay && !selectedRange) {
            const from = dayjs().utcOffset(getTimezoneOffset(timeZone));

            setSelectedRangeFrom(from);
            setSelectedDate(null);
            updateCalendar(from);

            setCurrentRange(getMonthRange(from));
        }
        // page with day range
        else if (currentDay) {
            // add offset
            const from = dayjs(currentDay).utcOffset(getTimezoneOffset(timeZone));

            const range = getMonthRange(from);

            setSelectedDate(from);

            if (!currentRange || !isInRange(range, currentRange)) {
                updateCalendar(from);
                setCurrentRange(range);
            }
        }
        // some other range selected
        else if (selectedRange) {
            // add offset
            const from = dayjs(selectedRange?.from).utcOffset(getTimezoneOffset(timeZone));

            setSelectedDate(null);
            
            if (!currentRange || !isInRange(selectedRange, currentRange)) {
                setSelectedRangeFrom(from);
                updateCalendar(from);

                setCurrentRange(selectedRange);
            }
        }
    }, [navigation])

    useEffect(() => {
        return () => {
            clearRequests()
        }
    }, [])

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk" localeText={ukLocale}>
        {updateCalendarInProgres && <div>
            <PreloaderCalendar />
        </div>}
        {/* monthes */}
        {!updateCalendarInProgres && <DateCalendar
            className='DateCalendar'

            onChange={(e) => onDateChanged(selectedChildren, e)}
            onMonthChange={onMonthChanged}

            timezone={timeZone}

            views={['month', 'day']}

            reduceAnimations

            value={selectedDate}
            referenceDate={selectedRangeFrom}

            slots={{day: Day}}
            slotProps={{day: {selectedChildren}}}

            // showDaysOutsideCurrentMonth
        />}
      </LocalizationProvider>
}
