import {getClientConfig} from 'ultra/configs/general';
import {patch, get, del} from './http';

const {api} = getClientConfig(window)

export function getFormContent(path, search) {
    let link = `${api}/form/${path.city}/path${path.uri}`;
    if (search) link += search
    return get(link);
}

export function getFormByDIDContent({city, did}) {
    let link = `${api}/form/${city}/did/${did}`;
    return get(link);
}

export function getNodeContent(path, search) {
    let link = `${api}/content/${path.city}/path${path.uri}`;
    if (search) link += search
    return get(link);
}

export function getNodeByDIDContent(path) {
    let link = `${api}/content/${path.city}/did${path.did}`;
    return get(link);
}

export async function removeContent(path) {
    return del(`${api}/content/${path.city}/path${path.uri}`)
}

export function getNodeLinks(path) {
    return get(`${api}/content/${path.city}/utils/links${path.uri}`);
}

export async function updateContent(path, body) {
    return patch(`${api}/content/${path.city}/path${path.uri}`, body)
}

export async function validateContent(path) {
    return patch(`${api}/content/${path.city}/utils/validate${path.uri}`)
}

export function getPathByDID(city, did) {
    return get(`${api}/content/${city}/utils/uri/${did}`);
}

export function getTags(city, type, fieldId) {
    return get(`${api}/tags/${city}/${type}/${fieldId}`)
}

// helpers
export function getTagsFilter(city, type, fieldId) {
    return new Promise((resolve, reject) => getTags(city, type, fieldId)
        .promise
        .then(values => {
            const result = {}
            values?.tags?.map(i => {
                result[i] = i;
            })

            resolve(result)
        })
        .catch(reject)
    )
}

export function getNodeTypeTitle(title, pre = '', firstLetterCaps) {
    if (!title) return;

    let result = title.toLowerCase();

    function changeLastLetter(str) {
        const lastSymbolIndex = str.length;

        // лікарня
        if (str.slice(-3) != 'ння' && str.slice(-1) === 'я') {
            return str.substring(0, lastSymbolIndex - 1) + 'ю'
        }
    
        // форма
        if (str.slice(-1) === 'а') {        
            return str.substring(0, lastSymbolIndex - 1) + 'у'
        }

        // лікар
        if (str.slice(-3) === 'кар') {        
            return str.substring(0, lastSymbolIndex - 3) + 'каря'
        }

        // посилання
        // страхування
        return str
    }

    const name = result.split(' ').reduce((res, part) => {
            return res += changeLastLetter(part) + ' ';
        }, '').trim();
    
    let res

    if (pre) {
        res = pre + ' ' + name;
    } else {
        res = name;
    }

    if (firstLetterCaps && res) {
        res = res[0].toUpperCase() + res.substring(1)
    }

    return res;
}
