import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import './index.scss';

export default function URL(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [value, setValue] = useState({})
    const [showSign, setShowSign] = useState()

    const [className, setClassName] = useState('URL'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''))

    useEffect(() => {
        setClassName('URL'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    useEffect(() => {
        setValue(content[field.id] || {})
    }, [content[field.id]])

    useEffect(() => {
        setShowSign(Boolean(content[field.id]?.title));
    }, [])

    const onTitleChange = (e) => {
        const result = {...value, title: e.target.value}
        setValue(result)
        onChange({target: {value: result}})
    }

    const onLinkChange = (e) => {
        const result = {...value, link: e.target.value}
        setValue(result)
        onChange({target: {value: result}})
    }

    return (<>
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='FormFieldsGroupLabel'>
                        {field.placeholder} {field.required ? '*' : ''}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='FormFieldsGroup'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    className='URLLink'
                                    fullWidth
                                    disabled={isDisabled}
                                    value={value.link}
                                    label={`Посилання на онлайн ресурс ${field.required ? '*' : ''}`}
                                    variant="outlined"
                                    onChange={onLinkChange}
                                />
                                <div className='fieldExample'>
                                    Приклад: https://google.com
                                </div>
                            </Grid>

                            {value.link && <Grid item xs={12}>
                                <div className='addSign'>
                                {showSign ? '-' : '+'}  <span className='pseudoLink' onClick={() => {setShowSign(!showSign)}}>{showSign ? 'Приховати' : 'Додати'} підпис</span>
                                </div>
                            </Grid>}
                            {showSign && <Grid item xs={12}>
                                <TextField
                                    className='URLTitle'
                                    fullWidth
                                    disabled={isDisabled}
                                    value={value.title}
                                    label={`Підпис ${field.required ? '*' : ''}`}
                                    variant="outlined"
                                    onChange={onTitleChange}
                                />
                                <div className='fieldExample'>
                                    Приклад: Сайт
                                </div>
                            </Grid>}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </>)
}
