import { create } from 'zustand'

export const useBackdropPreloaderStore = create((set) => ({
  preloader: false,
  showBackdropPreloader: () => set(() => {
    return {preloader: true}
  }),
  hideBackdropPreloader: () => set(() => {
    return {preloader: false}
  }),
}))
