import React, {useState, useEffect} from 'react';

import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import './index.scss';

export default function NotPublishedIcon() {
    
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipToggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(!open);
    };

    return <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Буде опубліковано після перевірки модератором"
            placement="top"
        >
            <span className="notPublished" onClick={handleTooltipToggle}>🚫 </span>
        </Tooltip>
    </ClickAwayListener>
}
