import {isMobile} from "react-device-detect";
import Skeleton from '@mui/material/Skeleton';

import {TILE_SIZES} from '../../../../../../../../Helpers/preloaders';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import LocationWidget from '../../../../../../Widgets/LocationWidget'
import MessengerValue from '../../../../../../Widgets/MessengerValue'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import FilterChip from '../../../../../../Widgets/FilterChip'
import ContentImage from '../../../../../../Widgets/ContentImage'

import './index.scss'

export default function Template(props) {
    const {content, permits, config, form, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    const device = isMobile ? 'MOBILE' : 'DESKTOP'

    return <div className="Clinic">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {!contentLoading && <ContentImage src="banner" image={content.banner} className="banner" />}
        {contentLoading && <Skeleton variant="rectangular" width="100%" className="banner" height={TILE_SIZES['BANNER'][device]['H']} sx={{marginBottom: '2rem'}} />}

        {content.description && <CKEditorContent showSkeleton={contentLoading} content={content.description} />}

        {!contentLoading && content.region && <p className='labelWrap region'>
            <span className="label">Район:</span>
            <span className="labelValue">
                <FilterChip value={content.region} config={form.fields.region} />
            </span>
        </p>}

        {!contentLoading && content.departments && <p className='labelWrap region'>
            <span className="label">Віділлення:</span>
            <span className="labelValue">
                {content.departments.join(', ')}
            </span>
        </p>}

        {!contentLoading && content.messenger && <MessengerValue messenger={content.messenger} />}

        {content.location && <LocationWidget showSkeleton={contentLoading} location={content.location} />}
    </div>
}
