import {NavLink as RouterLink} from "react-router-dom";

import {useConfigStore} from '../../../../Stores/config'

export default function NodeValue(props) {
    const {value} = props;

    const {configs} = useConfigStore();

    return <>{configs?.content?.nodes?.[value]?.title}</>
}

