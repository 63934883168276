import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

import {useNavigationStore} from '../../../../../../../Stores/navigation';

import PriceWidget from '../../../../../Widgets/Price';
import LinksTile from '../../../../../Widgets/LinksTile'
import CKEditorContent from '../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../Widgets/Breadcrumbs'
import TopPagePicture from '../../../../../Widgets/TopPagePicture'

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    const [currentPage, setCurrentPage] = useState()

    const location = useLocation();

    useEffect(() => {
        let url = new URL(window.location.href);
        setCurrentPage(url.searchParams.get('page'));
    }, [location])

    return <div className='SalesTemplate'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {!currentPage
            && <CKEditorContent
                showSkeleton={contentLoading}
                content={content.description}
            />
        }

        <LinksTile
            imgLoading={config?.templateOptions?.imgLoading}

            src="gallery"
            showSkeleton={contentLoading}
            showPagination={content.showPagination}
            showFilters={config?.showFilters || content?.showFilters}
            links={children}
            xs={6}
            md={3}
            label={(item) => {
                if (item.soldout) return <div className='pseudoButton errorButton'>Продано</div>
                if (item.canceled) return <div className='pseudoButton errorButton'>Не активно</div>
                return <div className='SaleItemPrice'><PriceWidget value={item.price} /></div>
            }}
        />
    </div>
}
