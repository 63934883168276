import Grid from '@mui/material/Grid';
import MessengerValue from '../MessengerValue'

import {useConfigStore} from '../../../../Stores/config'

import './index.scss'

export default function MessengerField(props) {
    const {field, type, content} = props;

    const {configs} = useConfigStore();

    const messenger = content[field];

    return <div className="MessengerField">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <span className="labelWrap">
                    <span className="label">
                        {configs.content.nodes[type].fields[field].placeholder}:
                    </span>
                    <span className="labelValue">
                        <MessengerValue messenger={messenger} />
                    </span>
                </span>
            </Grid>
        </Grid>
    </div>
}
