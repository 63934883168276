import React, {useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";
import { NavLink as RouterLink } from "react-router-dom";

import {DEFAULT_CITY} from 'ultra/const/general';
import {useLoaderData, useNavigation, useParams} from "react-router-dom";
import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {getClientCity} from 'ultra/configs/general';
import {hasFullAccess} from 'ultra/helpers/auth';
import {getClientUrl} from 'ultra/configs/general'

import Chip from '@mui/material/Chip';

import { setPageTitle } from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getDocumentHistory, restoreDocument} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';

import PreloaderTable from '../../../../../Components/PreloaderTable';

import TableView from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
import FiltersLine from '../../../../Content/Widgets/FiltersLine';
import NodeInlineActions from '../../../../Content/Widgets/NodeInlineActions';
import DateValue from '../../../../Content/Widgets/DateValue';
import UserHistoryLinkByID from '../../../../Content/Widgets/UserHistoryLinkByID';

import NodeHistoryInlineActions from '../../../Components/NodeHistoryInlineActions';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
import {useToasterStore} from '../../../../../Stores/toster';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import './index.scss';

export default () => {
    const initHistory = useLoaderData();

    let {city, did} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    const {showError} = useToasterStore();
    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {userState} = useGlobalCustomContext();

    const [history, setHistory] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Ініціатор',
        },
        _code: {
            type: 'notification',
            placeholder: 'Тип'
        },
        patch: {
            type: 'history-details',
            placeholder: 'Дані'
        }
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_code'],
            hidden: ['patch', '_timestamp'],
            noHeaders: true
        } : {
            visible: ['_timestamp', '_code'],
            hidden: ['patch'],
            noHeaders: true,
            unfolded: true,
        })

        // updateHistory(initHistory);

        setPermits({})

        setPageTitle('Історія сторінки')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }

        if (nodeState.restoreStart) {
            const reqParams = {...nodeState.restoreStart}

            showBackdropPreloader();
            nodeDispatch({type: NODE_ACTIONS.RESTORE_CLEANED})

            // const city = getClientCity(window) || DEFAULT_CITY;
            restoreDocument(city, did, reqParams)
                .then(() => {
                    nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                })
                .catch((err) => {
                    showError({snackbarMessage: err.message})
                })
        }

    }, [nodeState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateHistory(initHistory);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    function updateHistory(history) {
        setHistory({...history})

        hideBackdropPreloader()
    }

    const selectClasses = (data) => {
        return data?._code
            ? 'tr_' + data?._code.replace(':', '_')
            : '';
    }

    const content = {
        title: history?.page?.title
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\history'] = {title: "Історія"}

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateHistory({});
        getDocumentHistory(city, did, url.search)
            .promise
            .then(updateHistory)
    }

    return <div id="Content" className='Content'>
            <div className='HistoryNodesAdminPage'>
                {history && history?.page && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && history?.page && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs}/>}

                {isMobile && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>}

                {(!fields) && <PreloaderTable/>}

                {history?.page && <div className='HistoryNodeAdminPageDetails'>
                    <div className='labelWrap'>
                        <span className='label'>dID:</span>
                        <span className='labelValue'>
                            ${did}
                        </span>
                    </div>
                    <div className='labelWrap'>
                        <span className='label'>Створено:</span>
                        <span className='labelValue'>
                            <DateValue value={history?.page?._created} />
                            &nbsp;
                            &nbsp;
                            <UserHistoryLinkByID id={history?.page?._created_by} />
                        </span>
                    </div>
                    {history?.parent && <div className='labelWrap path'>
                        <span className='label'>Посилання:</span>
                        <span className='labelValue'>
                            {city !== DEFAULT_CITY && <Chip className='cityLabel' variant="outlined" label={configs.content.cities[city].city} />}
                            {city === DEFAULT_CITY && <Chip className='cityLabel' variant="outlined" label="Головна" />}

                            <RouterLink to={getClientUrl(city, restorePath(normalizeRoute(history.parent._uri)))}>{history.parent.title}</RouterLink>
                            {history?.page?.title && <>
                                &nbsp;
                                /
                                &nbsp;
                            </>}
                            {history?.page?.title && <RouterLink to={getClientUrl(city, restorePath(normalizeRoute(history.page._uri)))}>{history?.page?.title}</RouterLink>}
                        </span>
                    </div>}
                </div>}

                {fields && history && <TableView
                    showPreloader={!history?.list}
                    content={history}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    actions={(id) => NodeHistoryInlineActions({
                        content: history?.list?.[id],
                        isRemoved: !history?.page?._uri,
                        unfolded: true
                    })}
                    selectClasses={selectClasses}
                />}

                {history?.page && <NodeInlineActions permits={history?.permits} node={history?.page} hideHistory={true} showCleanHistory unfolded={!isMobile} className="CurrentPageInlineActions" city={city} hasFullAccess={hasFullAccess(userState.user)} />}
            </div>
        </div>
}
