import {hasFullAccess} from 'ultra/helpers/auth';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import DocumentHistoryLink from '../../../../../../Content/Widgets/DocumentHistoryLink'

import DocumentType from '../../../Components/DocumentType'

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    if (!values.details) {
        return <div className="NotificationTitle">Видалено історію для сторінки </div>
    }

    if (values.details) {
        return <div className="NotificationTitle">Видалено історію для
            <DocumentType details={values.details} />
            <DocumentHistoryLink onlyText={noFullAccess} details={values.details} city={values._city} />
        </div>
    }
}
