import Skeleton from '@mui/material/Skeleton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function PreloaderLinks(props) {
    const {className} = props;

    return <ul className={className ? `PreloaderLinks ${className}` : className}>
        <li>
            <span className='wrap skeleton'>
                <span className='link'>
                    <Skeleton variant="rectangular" width={140} height={14} className='itemSkeleton' />
                    <NavigateNextIcon className="arrow" />
                </span>
            </span>
        </li>
        <li>
            <span className='wrap skeleton'>
                <span className='link'>
                    <Skeleton variant="rectangular" width={240} height={14} className='itemSkeleton' />
                    <NavigateNextIcon className="arrow" />
                </span>
            </span>
        </li>
        <li>
            <span className='wrap skeleton'>
                <span className='link'>
                    <Skeleton variant="rectangular" width={180} height={14} className='itemSkeleton' />
                    <NavigateNextIcon className="arrow" />
                </span>
            </span>
        </li>
        <li>
            <span className='wrap skeleton'>
                <span className='link'>
                    <Skeleton variant="rectangular" width={120} height={14} className='itemSkeleton' />
                    <NavigateNextIcon className="arrow" />
                </span>
            </span>
        </li>
        <li>
            <span className='wrap skeleton'>
                <span className='link'>
                    <Skeleton variant="rectangular" width={140} height={14} className='itemSkeleton' />
                    <NavigateNextIcon className="arrow" />
                </span>
            </span>
        </li>
        <li>
            <span className='wrap skeleton'>
                <span className='link'>
                    <Skeleton variant="rectangular" width={200} height={14} className='itemSkeleton' />
                    <NavigateNextIcon className="arrow" />
                </span>
            </span>
        </li>
    </ul>
}
