import React, {useEffect, useState} from 'react';

import {useConfigStore} from '../../../Stores/config';

import Filter from '../Filter'

export default function Node(props) {
    const {configs} = useConfigStore();

    const [updatedField, setUpdatedField] = useState();

    useEffect(() => {
        const nodes = {}
        Object.keys(configs.content.nodes).map(node => {
            nodes[node] = configs?.content?.nodes?.[node]?.title + ' (' + node + ')'
        })

        const field = {...props.field};
        field.options = field.options || {};
        field.options.hasNoEmptyValue = true;
        field.values = {
            values: nodes
        };

        setUpdatedField(field);
    }, [])

    if (!updatedField) return <></>

    return <Filter {...props} field={updatedField} />
}
