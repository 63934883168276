import {hasFullAccess} from 'ultra/helpers/auth';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if(isYoursAccount(userState, values?.details?.id)) {
        return <div className="NotificationTitle">Ваш аккаунт успішно перевірено</div>
    }
    
    if (initByCurrentUser) {
        return <div className="NotificationTitle">
            <>Ви перевірили аккаунт </>
            <UserHistoryLink onlyText={noFullAccess} id={values?.details?.id} name={values?.details?.displayName} />
        </div>
    } 
    
    return <div className="NotificationTitle">
        <UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} />
        <> перевірив аккаунт </>
        <UserHistoryLink onlyText={noFullAccess} id={values?.details?.id} name={values._init_by_name} />
    </div>
}
