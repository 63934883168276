import React, {useEffect} from 'react'; // useState

import {useLocation} from 'react-router-dom'
import {useNavigate} from "react-router-dom";

import Profile from './Pages/Profile';
import SignUp from './Pages/SignUp';
import SignIn from './Pages/SignIn';
import Reset from './Pages/Reset';

import {useGlobalCustomContext} from '../../Helpers/context';
import ErrorPageContent from '../../Modules/Content/Pages/ErrorPage/ErrorPageContent';
import {USER_ACTIONS} from './Reducers/user';

// import Edit from './Pages/Edit';

import './index.scss';

function SignInPage() {
  const {userState, userDispatch, nodeDispatch} = useGlobalCustomContext();

  const navigate = useNavigate();

  const onLoginSuccess = () => {
    navigate('/');
    userDispatch({type: USER_ACTIONS.USER_REFRESH_START});
  }

  return <div className="SignInPage">
      <div className="SignInPageTitle">Авторизація</div>
      <SignIn onSuccess={onLoginSuccess} />
  </div>
}

export default () => {
    const [Component, setComponent] = React.useState(<></>);

    const location = useLocation();

    const error = {
      breadcrumbs: {},
      status: '404',
      statusText: `Сторінка ${window.location.href} не знайдена`
    }

    useEffect(() => {
      const {pathname} = location;
      const componentName = pathname.substring(pathname.lastIndexOf('/') + 1);
      const components = {
        login: SignInPage,
        reset: Reset,
        register: SignUp,
        profile: Profile,
        // edit: Edit,
      }

      if (components[componentName]) {
        setComponent(React.createElement(components[componentName]))
      } else {
        setComponent(<ErrorPageContent error={error} />)
      }
    }, [location]);

    return (
        <div id='Content' className='Content'>
          <div className='Profile'>
            {Component}
          </div>
        </div>
    )
}
