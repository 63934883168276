import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';

import './index.scss'

export default function ButtonWithPreloader(props) {
    const {children, inprogress} = props;

    return <Button
        {...props}
        className={inprogress === 'true' ? 'buttonWithPreloader loading' : 'buttonWithPreloader'}
    >
            {children}
            {inprogress === 'true' && <CircularProgress />}
    </Button>
}