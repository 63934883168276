const GLOBAL_STORE = '__quick_solution_cache_node__';

// init
if (!window.__cache) window.__cache = {};
if (!window.__cache[GLOBAL_STORE]) window.__cache[GLOBAL_STORE] = {};
if (!window.__cache[GLOBAL_STORE]) window.__cache[GLOBAL_STORE] = {};

if (!window.__cache_in_progress) window.__cache_in_progress = {};
if (!window.__cache_in_progress[GLOBAL_STORE]) window.__cache_in_progress[GLOBAL_STORE] = {};
if (!window.__cache_in_progress[GLOBAL_STORE]) window.__cache_in_progress[GLOBAL_STORE] = {};

export function setCacheObjInProgress(store, id) {
    if (!window.__cache_in_progress[GLOBAL_STORE][store]) window.__cache_in_progress[GLOBAL_STORE][store] = {};

    window.__cache_in_progress[GLOBAL_STORE][store][id] = true
}

export function getCacheObjInProgress(store, id) {
    return window.__cache_in_progress?.[GLOBAL_STORE]?.[store]?.[id]
}

export function removeCacheObjInProgress(store, id) {
    delete window.__cache_in_progress[GLOBAL_STORE][store][id]
}

export function setCacheObj(store, id, value) {
    if (!window.__cache[GLOBAL_STORE][store]) window.__cache[GLOBAL_STORE][store] = {};

    window.__cache[GLOBAL_STORE][store][id] = value
}

export function getCacheObj(store, id) {
    return window.__cache[GLOBAL_STORE]?.[store]?.[id] || null;
}

export function delCacheStore(store) {
    if (window.__cache[GLOBAL_STORE]?.[store]) {
        delete window.__cache[GLOBAL_STORE][store]
        delete window.__cache_in_progress[GLOBAL_STORE][store]
    }
    else {
        return null;
    }
}

export function delCacheObj(store, id) {
    if (window.__cache[GLOBAL_STORE]?.[store]?.[id]) {
        delete window.__cache[GLOBAL_STORE][store][id]
        delete window.__cache_in_progress[GLOBAL_STORE][store][id]
    }
    else {
        return null;
    }
}

export function clearAllCache() {
    window.__cache = {}
    window.__cache_in_progress = {}
}
