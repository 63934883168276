import Skeleton from '@mui/material/Skeleton';
import {isMobile} from "react-device-detect";

export default function PreloaderTopThumbnail() {
    const style = {marginBottom: '2rem'}
    if (!isMobile) {
        style.width = '240px'
        style.height = '240px'
    }

    return <Skeleton variant="rectangular" width="100%" height={300} style={style} />
}
