import {isMobile} from "react-device-detect";
import Skeleton from '@mui/material/Skeleton';

import {TILE_SIZES} from '../../../../Helpers/preloaders';

import ContentImage from '../ContentImage'

import './index.scss'

const device = isMobile ? 'MOBILE' : 'DESKTOP'

export default function TopPagePicture(props) {
    const {image, showSkeleton, cutHeightForDesktop} = props;

    if (showSkeleton) return <div className={cutHeightForDesktop ? 'TopPagePicture CutHeightForDesktop' : 'TopPagePicture'}>
        <Skeleton variant="rectangular" width="100%" height={TILE_SIZES['BANNER'][device]['H']} />
    </div>

    if (!image) return <></>

    return <div className={cutHeightForDesktop ? 'TopPagePicture CutHeightForDesktop' : 'TopPagePicture'}>
        <ContentImage src="banner" image={image} />
    </div>
}
