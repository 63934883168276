import {useEffect, useState} from "react";

export default function UploadedFileType(props) {
    const {file} = props;
    
    const [field, setField] = useState()

    useEffect(() => {
        if (file) {
            setField(Object.keys(file)[0])
        }
    }, [file])
    
    if (!file) return <> файл </>

    if (!field) return <></>

    switch (field) {
        case 'thumbnail':
        case 'banner':
        case 'poster':
            return <> зображення </>

        default:
            return <> файл у поля {field} </>;

    }
}
