import {isMobile} from "react-device-detect";

import Popover from '@mui/material/Popover';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';

import CloseIcon from '@mui/icons-material/Close';

import '../index.scss';

export default function AdaptiveMenu(props) {
    const {open, title, links, header, footer, onClose, anchorMenu, className} = props;

    return <>
        {!isMobile && <Popover
            anchorEl={anchorMenu}
            className={`MenuPopup ${className ? className : ''}`}
            open={open}
            onClose={onClose}>
                {header}
                {links}
                {footer}
        </Popover>}

        {isMobile && <SwipeableDrawer
                disableBackdropTransition
                disableDiscovery
                className="SwipeableDrawerContainer"
                swipeAreaWidth={20}
                anchor="bottom"
                open={open}
                onClose={onClose}
                onOpen={()=>{}}
                ModalProps={{
                    keepMounted: false,
                }}
            >
                <div className='SwipeableDrawer'>
                    {title && <div className='SwipeableDrawerTitle'>
                        <CloseIcon className='close' onClick={onClose} />
                        {title}
                    </div>}
                    <div className={`${title ? 'SwipeableDrawerContentWrapper' : 'SwipeableDrawerContentWrapper noTitle'} ${className ? className : ''}`}>
                        {header}
                        {links}                  
                        {footer}
                    </div>
                </div>
        </SwipeableDrawer>}
    </>
}
