import PriceWidget from '../../../../../../../../Widgets/Price';

import MessageToOwner from '../../MessageToOwner';

export default function DepositReturnStep(props) {
    const {submit, content, belongsToLibrary} = props;

    return <>
        <p className="titleEmojiText titleEmojiTitle">Заявка на повернення відправлена</p>
        <p className="titleEmoji">💬</p>

        {belongsToLibrary && <p className="titleEmojiText">
            Очікуйте на повідомлення від бібліотекаря
        </p>}

        {!belongsToLibrary && <p className="titleEmojiText">
            <span>Для {content?.deposit?.price && <>повернення завдатку <PriceWidget value={content.deposit} /> та </>} </span>
            <span>отримання книги - зв'яжіться з власником </span>
        </p>}

        <div className="contact">
            <MessageToOwner belongsToLibrary={belongsToLibrary} book={content} onClick={submit}/>
        </div>
    </>
}
