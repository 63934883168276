import {useState, useEffect} from 'react';
import {isMobile} from "react-device-detect";

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getTime, getTimezoneNameString, timestampToDate, getFormatedTime} from 'ultra/helpers/date'

import {useConfigStore} from '../../../../../../../../Stores/config';
import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import PreloaderTopPagePicture from '../../../../../../../../Components/PreloaderTopPagePicture';
import PreloaderText from '../../../../../../../../Components/PreloaderText';

import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../../Widgets/Title'
import AddToCalendar from '../../../../../../Widgets/AddToCalendar'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import FilterChip from '../../../../../../Widgets/FilterChip'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import LocationWidget from '../../../../../../Widgets/LocationWidget'

import './index.scss'

function EventActions(props) {
    const {content} = props;

    const onAction = (action) => {
    }

    return <div>
        {content.place && <div className='Actions'>
            <AddToCalendar
                title={content.title}
                date={content.date}
                duration={content.duration}
                // details=''
                location={content.place}
            />
        </div>}
        <div className='actions'>
            <Button
                size="small"
                onClick={() => onAction('interested')}
                startIcon={<StarBorderOutlinedIcon />}
                variant="outlined"
                >
                <span>Зацікавлений</span>
            </Button>

            <Button
                size="small"
                onClick={() => onAction('interested')}
                startIcon={<CheckCircleOutlineOutlinedIcon />}
                variant="outlined"
            >
                <span>Прийду</span>
            </Button>
        </div>
    </div>
}

function EventDetails(props) {
    const {content, form} = props;

    const [days, setDays] = useState()

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    useEffect(() => {
        const start = getTime(timestampToDate(content.date), timeZone);
        const end = start.add(content.duration, 'hour').subtract(1, 'second');

        if (start.date() !== end.date()) {
            setDays(1 + end.date() - start.date());
        } else {
            setDays(1);
        }
    }, [])

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    // console.log(timestampToDate(content.date))
    // console.log(timeZone)

    return <div>
        <p className='dateDetails'>
            <span className='labelWrap'>
                <span className="label">Дата та час:</span>
                <span className='labelValue'>
                    {getFormatedTime(timestampToDate(content.date), timeZone)}
                </span>
            </span>

            <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={getTimezoneNameString(timestampToDate(content.date), timeZone)}
                placement="top"
            >
                <IconButton size='small' className='timezoneDetails'>
                    <InfoOutlinedIcon onClick={handleTooltipOpen} />
                </IconButton>
            </Tooltip>
            </ClickAwayListener>
        </p>
        {/* <p className='timezone'></p> */}
        {days > 1 && <p>Тривалість: {days} {days > 4 ? 'днів' : 'дні'}</p>}
        <div className="labels labelsWrap">
            <FilterChip value={content?.region} config={form?.fields?.region} />
            <FilterChip value={content?.type} config={form?.fields?.type} />
            <FilterChip value={content?.payment} config={form?.fields?.payment} />
        </div>

        {content?.location?.link && <p><a href={content?.location?.link}>{content?.location?.name}</a></p>}
    </div>
}

export default function Template(props) {
    const {content, permits, config, form, breadcrumbs} = props

    const {contentLoading} = useNavigationStore() // {contentLoading: true}; 

    if (contentLoading) return <>
        <PreloaderTopPagePicture />
        <PreloaderText />
    </>

    return <div className="Event">
        <TopPagePicture cutHeightForDesktop image={content.banner} />
        {/* showSkeleton={contentLoading}  */}
        
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {/* {isMobile && contentLoading && <Skeleton variant="rectangular" width="100%" height={60} style={{marginBottom: '1rem'}} />} */}
        {/* {isMobile && <EventDetails content={content} form={form} />}  !contentLoading &&  */}
        
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                {isMobile && <div className='EventDetailsMobile'><EventDetails content={content} form={form} /></div>}

                <CKEditorContent
                    showSkeleton={contentLoading}
                    content={content.description}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                {/* {!isMobile && contentLoading && <div className='EventDetailsDesktop'><Skeleton variant="rectangular" width="100%" height={60} style={{marginBottom: '1rem'}} /></div>} */}
                {/* !contentLoading &&  */}
                {!isMobile && <div className='EventDetailsDesktop'><EventDetails content={content} form={form} /></div>}

                {/* {contentLoading && <Skeleton variant="rectangular" width="100%" height={200} />} */}
                {/* {!contentLoading &&  */}

                <div>
                    {content.link && <div className='Link'><Button
                            fullWidth
                            href={content.link.link}
                            target="_blank"
                            startIcon={<OpenInNewIcon />}
                            variant="outlined"
                        >
                        {content.link.title}
                    </Button></div>}

                    <EventActions content={content} />
                </div>
                {/* } */}

                {isMobile && content.place && <LocationWidget showSkeleton={contentLoading} location={content.place} />}

                {/* !contentLoading &&  */}
                {!isMobile && <div className='DesktopEmptyBlock'></div>}
            </Grid>
            <Grid item xs={12}>
                {!isMobile && content.place && <LocationWidget showSkeleton={contentLoading} location={content.place} />}
                {!isMobile && <div className='DesktopEmptyBlock'></div>}
            </Grid>
        </Grid>
    </div>
}
