import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom"; // useLoaderData, useNavigation,
// import { NavLink as RouterLink } from "react-router-dom";
import {debounce} from 'lodash'; // cloneDeep, sortBy
import DOMPurify from "dompurify"

import {restorePath} from 'ultra/helpers/route'; // normalizeRoute, getParentPath

import {getClientCity} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {timestampToDate, getFormatedTime} from 'ultra/helpers/date'

import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import SearchIcon from '@mui/icons-material/Search';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import Fab from '@mui/material/Fab';

// import {useSearchStore} from '../../../../../../Stores/search';
import {useConfigStore} from '../../../../../../Stores/config';
// import {useHistoryStore} from "../../../../Stores/history";
import NodeImage from '../../../../Widgets/NodeImage';
import ContentImage from '../../../../Widgets/ContentImage';
import FilterChip from '../../../../Widgets/FilterChip'
// import CKEditorContent from '../../../../Widgets/CKEditorContent'
import MessengerValue from '../../../MessengerValue'
import URLValue from '../../../../Widgets/URLValue'

import Preloader from '../../../../../../Components/Preloader';

import {searchNode} from '../../../../../../Helpers/search';
// import {filterContent} from '../../../../Helpers/content';

import './index.scss';

function SearchResultNodeTags(props) {
    const {node} = props;

    let result = <></>

    const {configs} = useConfigStore();

    switch (node._type) {
        case 'book':
            if (node.genre?.length > 0) {
                result = <div className='searchResultItemTags'>
                    {node.genre && <div>
                        {node.genre.map(genre => <FilterChip  value={genre} config={configs.content.nodes['book'].fields.genre} />)}
                    </div>}
                </div>
            }
            break;
    }
    
    return result;
}

function SearchResultNodeRows(props) {
    const {node} = props;
    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()

    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    let result = <></>

    switch (node._type) {
        case 'recommendation':
            if (!node.region && !node.messenger && !node.url) {
                result = <></>
            }
            else {
                result = <div className='searchResultItemDetails recommendation'>
                    {/* {node.category && <div className='labelWrap'>
                        <div className="label">Категорія:</div>
                        <FilterChip className="textOnly" value={node.category} config={configs.content.nodes[node._type]?.fields?.category} />
                    </div>} */}

                    {node.region && <div className='labelWrap'>
                        <div className="label">Район:</div>
                        {node.region && <FilterChip className="textOnly" value={node.region} config={configs.content.nodes[node._type]?.fields?.region} />}
                    </div>}
                    {node.messenger && <div className='labelWrap'>
                        <span className="label">Месенджер:</span>
                        <span className="labelValue">
                            <MessengerValue messenger={node.messenger} />
                        </span>
                    </div>}
                    {node.url && <div className='labelWrap'>
                        <span className="label">Посилання:</span>
                        <span className="labelValue">
                            <URLValue value={node.url} />
                        </span>
                    </div>}
                </div>
            }
            break;

        case 'book':
            result = <div className='searchResultItemDetails book'>
                {node.author && <div className='labelWrap'>
                    <span className="label">Автор:</span>
                    <span className="labelValue">
                        {node.author.join(', ')}
                    </span>
                </div>}
            </div>
            break;

        case 'event':
        case 'travel':
        case 'retreat':
            result = <div className={`searchResultItemDetails ${node._type}`}>
                {node.date && <div className='labelWrap'>
                    <span className="label">Дата та час:</span>
                    <span className='labelValue'>
                        {getFormatedTime(timestampToDate(node.date), timeZone)}
                    </span>
                </div>}
            </div>
            break;

        case 'analogue':
            result = <div className='searchResultItemDetails analogue'>
                {node.analogue && <div className='labelWrap'>
                    <span className="label">Аналоги:</span>
                    <span className="labelValue">
                        {node.analogue.join(', ')}
                    </span>
                </div>}
                {node.active_substances && <div className='labelWrap'>
                    <span className="label">Діючі речовини:</span>
                    <span className="labelValue">
                        {node.active_substances.join(', ')}
                    </span>
                </div>}
                {node.international && <div className='labelWrap'>
                    <span className="label">Міжнародна назва:</span>
                    <span className="labelValue">
                        {node.international}
                    </span>
                </div>}
            </div>
            break;
    }

    return result;
}

function SearchResultNode(props) {
    const {className, node, closeSearchResults} = props;

    const {configs} = useConfigStore();
    const navigate = useNavigate();

    const searchResultHandler = (uri) => {
        closeSearchResults();
        navigate(restorePath(uri))
    }

    const goToPage = (path) => {
        closeSearchResults();
        navigate(path);
    }

    return <div className={className}>
        {/* fields={configs.content.nodes[node._type].fields || parentConfig.childrenOptions?.[node._type]?.fields} */}
        <div className='searchResultItemParent'>
            <span className='pseudoLink' onClick={() => goToPage(restorePath(node?.parent?.uri))}>
                {node?.parent?.title}
            </span>
        </div>
        <div className='searchResultItemTitle'>{node.title}</div>

        <div className='searchResultItemContent'>
            {(node.thumbnail || node.poster) && <NodeImage values={node} className='thumbnail'/>}

            <div className='content'>
                {node.banner && !node.thumbnail && !node.poster && <ContentImage src="banner" className="searchResultItemBanner" image={node.banner} />}

                {/* {node.short_description && <div className='shortDescription'>{node.short_description}</div>} */}

                {node.category && <div className='category'><FilterChip className="textOnly" value={node.category} config={configs.content.nodes[node._type]?.fields?.category} /></div>}

                {node.description && node.highlights?.filter(highlight => highlight?.field !== 'title')?.length === 0 && <div className='description'>{node.description.substring(1, 120)} {node.description.length > 120 && <>...</>}</div>}

                <SearchResultNodeRows node={node} />

                {node.highlights?.filter(highlight => highlight?.field !== 'title')?.length > 0 && <div className='searchResultItemHighlights'>
                    {node.highlights
                        ?.filter(highlight => highlight?.field !== 'title')
                        ?.map(highlight => highlight?.snippet && <div className='searchResultItemHighlight' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('...' + highlight.snippet + '...')}} />)}
                </div>}

                <SearchResultNodeTags node={node} />

                {/* hasDetailsPageLink(node) */}
                {/* {!node.noChildLinks && <div className='searchResultItemLink'>
                    <span className="emulateButton small" onClick={() => {closeSearchResults(); searchResultHandler(node._uri)}}>Детальніше</span>
                </div>}
                {node.noChildLinks && <div className='searchResultItemLink'>
                    <span className="emulateButton small" onClick={() => {closeSearchResults(); searchResultHandler(getParentPath(node._uri))}}>Перейти до списку</span>
                </div>} */}
                <div className='searchResultItemLink'>
                    <span className="emulateButton small" onClick={() => {closeSearchResults(); searchResultHandler(node._uri)}}>Детальніше</span>
                </div>
            </div>
        </div>
    </div>
}

export default function GlobalSearchNodeButton(props) {
    const {config, children} = props

    const location = useLocation();

    const [searchResult, setSearchResult] = useState([]);
    const [pageSearchResult, setPageSearchResult] = useState([]);
    const [showSearch, setShowSearch] = useState(false)
    const [requestInProgress, setRequestInProgress] = useState(false)

    let controllers = []

    const onSearchTextChange = async (e) => {
        controllers.map(c => {c.abort()});
        setRequestInProgress(false)

        const value = e.target.value;

        if (value?.length < 4) {
            cleaneSearchResults()
            return;
        }

        if (!value?.length) {
            cleaneSearchResults()
            return;
        }

        const city = getClientCity(window) || DEFAULT_CITY;

        setRequestInProgress(true)
        const {promise, controller} = await searchNode(city, value)
        controllers.push(controller)

        const sortSearch = (a, b) => {
            if (a.title.toLowerCase() === value.toLowerCase()) {
                return -1
            }
            else if (a.title.toLowerCase().indexOf(value.toLowerCase() + ' ') === 0) {
                return -1
            }
            else {
                return 1
            }
        }

        promise.then(result => {
            // find on page:
            const page = []
            const global = []

            result.map(node => {
                if (restorePath(node._uri) === location.pathname) return;

                let isNodeOnPage = false;
                children.order.map(uri => {
                    if (children.list[uri]._did === node.id) {
                        isNodeOnPage = true;
                    }
                })
                
                if (isNodeOnPage) {
                    // if (node.title.toLowerCase() === value.toLowerCase()) {
                    if (node.highlights.find(h => h.field === 'title')) {
                        page.unshift(node)
                    }
                    else {
                        page.push(node)
                    }
                }
                else {
                    if (node.highlights.find(h => h.field === 'title')) {
                        global.unshift(node)
                    }
                    else {
                        global.push(node)
                    }
                }

                page.sort(sortSearch)
                global.sort(sortSearch)
            });

            setPageSearchResult(page);
            setSearchResult(global);

            setRequestInProgress(false);

            // const list = filterContent(configs.content.nodes, children.list, config?.templateOptions?.searchBy, value)
            // Object.keys(list).map(uri => {
            //     if (config?.templateOptions?.searchIgnoreNodes) {
            //         if (!config?.templateOptions?.searchIgnoreNodes?.includes(list[uri]._type)) {
            //             page.push(list[uri])    
            //         }
            //     } else {
            //         page.push(list[uri])
            //     }
            // })

            // remove data from page into global:
            // setSearchResult(result.filter(i => {
            //     const isNodeOnPage = page.findIndex(node => node._did === i.id) > -1;
            //     return !isNodeOnPage;
            // }));
        })
    }

    const onSearchToggle = () => {
        if (!showSearch) {
            document.body.classList.add('search')
        } else {
            document.body.classList.remove('search')
            cleaneSearchResults()
        }

        setShowSearch(!showSearch);
    }

    const cleaneSearchResults = () => {
        setSearchResult([]);
        setPageSearchResult([]);
    }

    const closeSearchResults = () => {
        cleaneSearchResults();
        setShowSearch(false);
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();

                document.body.classList.remove('search')
                closeSearchResults();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [])

    return <div className='SearchNodeButton'>
        <Fab color="contrast" onClick={onSearchToggle}><SearchIcon /></Fab>

        <Slide direction='left' in={(showSearch)} mountOnEnter unmountOnExit>
            <div className="SearchWrap">
                <div className="SearchWrapContainer">
                    <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        onChange={debounce(onSearchTextChange, 500)}
                        className='SearchWrapInput'
                    />

                    <SearchIcon className='SearchWrapIcon'/>

                    {<span className="SearchWrapClose" onClick={onSearchToggle}>
                        <CancelIcon />
                    </span>}
                </div>
                <div className='searchResultItemWrap'>
                    {requestInProgress && <div className='searchResultPreloader'>
                        <Preloader />
                    </div>}

                    {pageSearchResult.length > 0 && <>
                        <div className='searchResultSectionTitle'>Знайдено на поточній сторінці:</div>
                        <div className='searchResultSectionResult'>
                            {pageSearchResult?.map(node => <SearchResultNode className='searchResultItem' key={`search_page_${node._uri}`} node={node} closeSearchResults={closeSearchResults} parentConfig={config}/>)}
                        </div>
                    </>}

                    {searchResult.length > 0 && <>
                        <div className='searchResultSectionTitle'>Знайдено на Балі | Простір:</div>
                        <div className='searchResultSectionResult'>
                            {searchResult?.map(node => <SearchResultNode className='searchResultItem' key={`search_global_${node._uri}`} node={node} closeSearchResults={closeSearchResults}/>)}
                        </div>
                    </>}
                </div>
            </div>
        </Slide>

        <Backdrop
            onClick={onSearchToggle}
            sx={{zIndex: (theme) => theme.zIndex.modal + 1}}
            open={showSearch}
        ></Backdrop>
    </div>
}
