import {timestampToDate} from 'ultra/helpers/date'; // isTimestamp
import dayjs from 'dayjs';

import './index.scss';

export default function DateValue(props) {
    const {value} = props;

    if (!value) return <></>

    return <span className='DateValueWidget'>
        <span className='date'>{dayjs(new Date(timestampToDate(value))).format('YYYY-MM-DD')} </span>
        <span className='time'>{dayjs(new Date(timestampToDate(value))).format('HH:mm')}</span>
    </span>;
}

