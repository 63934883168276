import {forwardRef} from 'react';
import {isMobile} from "react-device-detect";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';

import './index.scss';

const UpTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdaptiveDialog(props) {
    const {open, children, title, onClose} = props;

    return <>
        {isMobile && <SwipeableDrawer
            className="SwipeableDrawerContainer"
            swipeAreaWidth={20}
            anchor="bottom"
            open={open}
            onClose={onClose}
            onOpen={()=>{}}
            ModalProps={{
                keepMounted: false,
            }}
        >
        <div className='SwipeableDrawer'>
            {title && <div className='SwipeableDrawerTitle'>
                <CloseIcon className='close' onClick={onClose} />
                {title}
            </div>}
            <div className={title ? 'SwipeableDrawerContentWrapper' : 'SwipeableDrawerContentWrapper noTitle'}>
                <div className='SwipeableDrawerContentWrapperInner'>
                    {children}
                </div>
            </div>
        </div>
        </SwipeableDrawer>}

        {!isMobile && <Dialog
                open={open}
                TransitionComponent={UpTransition}
                // keepMounted
                onClose={onClose}
                disableEnforceFocus
            >
            {title && <DialogTitle>
                <div className='DialogContentTitle'>
                    <CloseIcon className='close' onClick={onClose} />
                    {title}
                </div>
            </DialogTitle>}

            <DialogContent>
                <div className='DialogContentWrapper'>
                    {children}
                </div>
            </DialogContent>
        </Dialog>}
    </>
}
