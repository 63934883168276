import {useState, useEffect} from 'react';
import dayjs from 'dayjs';

import {timestampToDate} from 'ultra/helpers/date';
import {getClientCity} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';

import Button from '@mui/material/Button';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import CurrencyFormated from '../../../../Components/CurrencyFormated'
import {useShoppingStore} from '../../../../Stores/shopping';
import {useConfigStore} from '../../../../Stores/config';

import './index.scss'

export default function ShopingCart(props) {
    const {price, item, shop, fullWidth, submitButton} = props;

    const {configs} = useConfigStore();
    const city = getClientCity(window) || DEFAULT_CITY;

    const {cart, addProduct, removeAllProduct} = useShoppingStore()
    const [selected, setSelected] = useState(false)

    const [isDisabled, setIsDisabled] = useState(false)

    const checkSelected = () => {
        const product = cart.find(i => (i.product.id === item.did || i.product.id === item._did) && i.shop.id === shop.partner.shop)
        setSelected(Boolean(product))
    }

    const checkDisabled = () => {
        if (item._type === 'shop-product') {
            if (!item.available) setIsDisabled(true)
        }
        else if (item._type === 'tour' || item._type === 'retreat') {
            if (dayjs().isAfter(dayjs(timestampToDate(item.date)))) setIsDisabled(true)
        }
    }

    const onClickHandler = (e) => {
        e.stopPropagation()

        if (selected) {
            removeAllProduct(item) // shop
        } else {
            addProduct(item, shop)
        }
        checkSelected()
    }

    useEffect(() => {
        checkSelected()
        checkDisabled()
    }, [cart]);

    return <div className={selected ? 'ShopingCartButton selected' : 'ShopingCartButton'}>
        <Button
            variant="contained"
            fullWidth={fullWidth}
            disabled={isDisabled}
            onClick={onClickHandler}>
                {price ? <><AddShoppingCartIcon /> &nbsp; <CurrencyFormated value={price} currency={shop?.partner?.config?.currency || configs?.content?.cities?.[city]?.currency} /> </> : (submitButton || 'Замовити')}
        </Button>
    </div>
}
