import {useEffect, useState} from "react";

import Grid from '@mui/material/Grid';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getClientCity} from 'ultra/configs/general';
import {isLibrary} from 'ultra/helpers/auth'; // isAdmin
import {getFormatedDate, timestampToDate} from 'ultra/helpers/date'

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Button from '@mui/material/Button';

import {NODE_ACTIONS} from '../../../../../../../Reducers/node';
import {useToasterStore} from '../../../../../../../../../Stores/toster';
import {useConfigStore} from '../../../../../../../../../Stores/config';
import {useBackdropPreloaderStore} from '../../../../../../../../../Stores/backdropPreloader';
// import {isYoursAccount} from '../../../../../../../../../Helpers/user';
import {useGlobalCustomContext} from '../../../../../../../../../Helpers/context';
import {ownerApproveReturnBook} from '../../../../../../../../../Helpers/library';
import {getBookStatuscode} from '../../../../../../../Helpers/library'

import ReaderDetails from '../ReaderDetails';

export default function BookIsReading(props) {
    const {book} = props;
    const {showBackdropPreloader} = useBackdropPreloaderStore() // hideBackdropPreloader
    const {userState, nodeDispatch} = useGlobalCustomContext();

    const {configs} = useConfigStore();

    const {showPromt, showSuccess, closeToaster} = useToasterStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    // const [hasFullAccess] = useState(isAdmin(userState?.user) || isLibrary(userState?.user));
    // const [isOwner, setIsOwner] = useState();

    // useEffect(() => {
    //     setIsOwner(isYoursAccount(userState, book?._created_by))
    // }, [userState?.user?.id, book])

    const returnBookHandle = () => {
        const city = getClientCity(window) || DEFAULT_CITY;
        showBackdropPreloader();
        
        showPromt({
            snackbarMessage: 'Це примусова дія, доступна лише для бібліотекаря і має використовуватись лише в екстрених випадках. Бажаєте продовжити?',
            snackbarDuration: 10000,
            onApproveHandler: async () => {
                closeToaster();
                // hideBackdropPreloader();
                await ownerApproveReturnBook(city, book._uri)
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                showSuccess({
                    snackbarMessage: 'Книгу було успішно повернуто до бібліотеки'
                })
            },
        })
    }

    return <>
        <div className="text">
            <p>
                <span>Книга зайнята до </span>
                {getFormatedDate(timestampToDate(book?._reading_till), timeZone)}
            </p>
            {getBookStatuscode(book) === 'overdue' && <p>Прострочено термін повернення</p>}
        </div>
        
        {/* INFO: Доступно лише для бібліотеки, хоча API дозволяє власнику теж це робити */}
        {isLibrary(userState?.user) && <Grid container spacing={2}>
            {/* isLibrary(userState?.user) ? 6 : 12 */}
            <Grid item xs={6}>
                <ReaderDetails book={book} fullWidth />
            </Grid>
            {/* {isLibrary(userState?.user)  */}
            <Grid item xs={6}>
                <Button className="errorButton" startIcon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" fullWidth onClick={returnBookHandle}>Повернути</Button>
            </Grid>
        </Grid>}
    </>
}
