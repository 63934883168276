import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';

import {isMobile} from "react-device-detect";
import {useLocation, useNavigation} from "react-router-dom";

import Grid from '@mui/material/Grid';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {getClientCityTimezone} from 'ultra/configs/general';
import {getTime, timestampToDate, getFormatedDate, getFormatedTime} from 'ultra/helpers/date'

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import {getActiveFilterFromURL} from '../../../../../../Helpers/filters'

import TitleWidget from '../../../../../../../Content/Widgets/Title'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import LinksTile from '../../../../../../Widgets/LinksTile'
// import FilterButtonWidget from '../../../../../../Widgets/FilterButton';
import FiltersLine from '../../../../../../Widgets/FiltersLine';
import CalendarWidget from '../../../../../../Widgets/Calendar';

import './index.scss'

function Range(props) {
    const {from, to, sufix, prefix} = props;

    if (!from || !to) return <></>;

    if (from === to) {
        return <div>{prefix} {from} {sufix}</div>;
    } else {
        return <div>{prefix} {from} - {to} {sufix}</div>;
    }
}

function getCurrentDate(datePath) {
    let result = ''
    // datePath?.m?.text?.toLowerCase()

    if (datePath.d) {
        result += datePath.d.text
    }

    if (datePath.m) {
        result += ' '
        result += datePath.m.text
    }

    if (datePath.y) {
        result += ' '
        result += datePath.y.text
    }

    return result;
}

function Content(props) {
    const {config, content, timeZone, children, permits, hasPagination, datePath} = props;

    const {contentLoading} = useNavigationStore();

    // const [from, setFrom] = useState()
    // const [to, setTo] = useState()

    const [current, setCurrent] = useState()
    const [past, setPast] = useState()
    const [hasFilters, setHasFilters] = useState()

    const {state} = useNavigation();

    useEffect(() => {
        // const values = getActiveFilterFromURL();
        
        // if (values.ranges) {
            // const from = values.ranges.find(i => i[1] === '>=');
            // const to = values.ranges.find(i => i[1] === '<=');

            // setFrom(getFormatedDate(from[2], timeZone))
            // setTo(getFormatedDate(to[2], timeZone))
        // }

        const url = new URL(window.location.href);
        const filters = url.searchParams.get('filters');
        setHasFilters(filters ? true : false);

    }, [state])

    useEffect(() => {
        const current = {order: [], list: {}, permits: {}}
        const past = {order: [], list: {}, permits: {}}
        children.order.map(i => {
            if (timestampToDate(children.list[i].date) >= dayjs().hour(0).minute(0).second(0).millisecond(0).toDate()) {
                current.order.push(i)
                current.list[i] = children.list[i]
                current.permits[i] = children.permits[i]
            }
            else {
                past.order.push(i)
                past.list[i] = children.list[i]
                past.permits[i] = children.permits[i]
            }
        })
        setCurrent(current)
        setPast(past)
    }, [children])

    function getEventTime(date) {
        const baseDate = timestampToDate(date);
        if (getTime(baseDate, timeZone).hour() > 0) {
            return getFormatedTime(baseDate, timeZone)
        } else {
            return getFormatedDate(baseDate, timeZone)
        }
    }

    function isCurrentMonth() {
        return datePath.m.text.toLowerCase() == dayjs().locale('uk').format('MMMM');
    }

    return <div>
        <CKEditorContent content={content.description} />

        {/* {!contentLoading && Object.keys(children.list).length === 0 && <div className='noDataInPeriod'>
            {from && to && <Range from={from} to={to} prefix="На" sufix="відсутні зареєстровані заходи" />}
            {!from && !to && <div>Відсутні зареєстровані заходи</div>}
        </div>} */}

            
        {/* {past?.order?.length === 0 && current?.order?.length === 0 && <>
            <span>Події на </span>
            <span>{datePath?.m?.text?.toLowerCase()} </span>
            <span>{hasFilters ? 'зі вказаними параметрами ' : ''} </span>
            <span>не знайдено </span>
        </>} */}

        {current?.order?.length > 0 && <LinksTile
            className="currentEvents"
            showSkeleton={contentLoading}
            showPagination={hasPagination}
            // showFilters={content.showFilters}
            imgLoading={config?.templateOptions?.imgLoading}
            links={current}
            xs={6}
            md={4}
            src="poster"
            title={(link) => <div>
                <span className='date'>{getEventTime(link.date)}</span>
                {/* <span className='sep'>|</span> */}
                {link.title}
            </div>}
        />}

        {past?.order?.length > 0 && isCurrentMonth() && <p className='pastEventsTitle'>Події що вже відбулись:</p>}

        {past?.order?.length > 0 && <LinksTile
            className="pastEvents"
            showSkeleton={contentLoading}
            showPagination={hasPagination}
            // showFilters={content.showFilters}
            imgLoading={config?.templateOptions?.imgLoading}
            links={past}
            xs={6}
            md={4}
            src="poster"
            title={(link) => <div>
                <span className='date'>{getEventTime(link.date)}</span>
                {/* <span className='sep'>|</span> */}
                {link.title}
            </div>}
        />}

        {current?.order?.length === 0 && past?.order?.length === 0 && datePath && <div className="noResults">Подій на {getCurrentDate(datePath)} {hasFilters ? 'зі вказаними параметрами ' : ''} не знайдено</div>}
    </div>
}

export default function Template(props) {
    const {content, children, config, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    const [datePath, setDatePath] = useState({})

    const location = useLocation();

    const [filterBy, setFilterBy] = useState();

    const {configs} = useConfigStore();

    const [timeZone, setTimeZone] = useState()
    useEffect(() => {
        const city = getClientCity(window) || DEFAULT_CITY;
        setTimeZone(getClientCityTimezone(configs, city));
    }, [configs])

    const [hasPagination] = useState(content.showPagination);
    useEffect(() => {
        setDatePath(getCurrentPeriod())

        setFilterBy(content?.showFilters?.filterByInline);
    }, [location])

    const getCurrentPeriod = () => {
        const url = new URL(window.location.href);

        const ranges = url.searchParams.get('ranges');
        
        function getRangeUrl(day, period) {
            const ranges = [];

            ranges.push(['date', '>=', day.valueOf()])
            ranges.push(['date', '<=', day.endOf(period).valueOf()])

            const url = new URL(window.location.href);
            url.searchParams.delete('ranges');
            url.searchParams.set('ranges', JSON.stringify(ranges));

            return url.pathname + url.search;
        }

        function getDay(day) {
            return {
                text: day.locale('uk').format('D'),
                link: getRangeUrl(day, 'day')
            }
        }

        function getMonth(day) {
            return {
                text: day.startOf('month').locale('uk').format('MMMM').charAt(0).toUpperCase() + day.startOf('month').locale('uk').format('MMMM').slice(1),
                link: getRangeUrl(day.startOf('month'), 'month')
            }
        }

        function getYear(day) {
            return {
                text: day.startOf('year').locale('uk').format('YYYY'),
                link: getRangeUrl(day.startOf('year'), 'year')
            }
        }

        let result;
        // current month selected
        if (!ranges) {
            result = {
                y: getYear(getTime(new Date(), timeZone)),
                m: getMonth(getTime(new Date(), timeZone))
            }
        } else {
            let activeRanges = JSON.parse(ranges);
            const from = activeRanges?.find(i => i[0] === 'date' && i[1] === '>=');
            const to = activeRanges?.find(i => i[0] === 'date' && i[1] === '<=');

            if (!from || !to) return;

            // used to[2] + 1, because it has end of the day, and next second will move to start of next day
            const days = Math.ceil(((to[2] + 1) - from[2])/1000/60/60/24)
            const fromDate = getTime(from[2], timeZone);
            const toDate = getTime(to[2], timeZone);

            result = {
                y: getYear(fromDate),
            }

            if (days === 1) {
                result.d = getDay(fromDate);
            }

            if (days >= 1 && fromDate.month() === toDate.month()) {
                result.m = getMonth(fromDate);
            }
        }

        return result;
    }

    return <div className="Events">
        {isMobile && <div className='CalendarWidget'><CalendarWidget field="date" /></div>}

        <div className='TitleLine'>
            <div className="Breadcrumbs">
                <div className='currentDate'>
                    {getCurrentDate(datePath)}
                </div>
            </div>

            <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} ignoreRangeInFilters/>
        </div>
        
        {/* hideFilters={true} */}

        {!isMobile && <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
                <div className='ContentDesktop'>
                    <Content
                        config={config}
                        content={content}
                        children={children}
                        permits={permits}
                        timeZone={timeZone}
                        datePath={datePath}
                        
                        contentLoading={contentLoading}
                        hasPagination={hasPagination}
                    />
                </div>
            </Grid>
            <Grid item xs={6} md={4}>
                <div className='CalendarWidgetDesktop'>
                    <CalendarWidget field="date" /></div>
                    {/* monthes={3} */}
            </Grid>
        </Grid>}
        

        {isMobile && <div>
            
            {filterBy?.length && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={filterBy}
                    />
                </div>
            }
            
            <Content
                config={config}
                content={content}
                children={children}
                permits={permits}
                datePath={datePath}
                
                contentLoading={contentLoading}
                hasPagination={hasPagination}
            />
        </div>}

    </div>
}
