import React, { useEffect, useState } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

import Slide from '@mui/material/Slide';

import './index.scss';

export default function InlineActions(props) {
    const {actions, unfolded, className, direction} = props;

    const [open, setOpen] = useState(false);
    const [complexClassName, setComplexClassName] = useState();

    useEffect(() => {
        let newClasses = 'InlineActions';
        newClasses += ' InlineActions' + actions.length;

        if (className) newClasses += ' ' + className;
        if (unfolded) newClasses += ' ' + 'InlineActionsUnfolded';
        if (direction === 'up') newClasses += ' ' + 'InlineActionsUp';
        if (open) newClasses += ' ' + 'InlineActionsOpen';

        setComplexClassName(newClasses);
    }, [unfolded, actions, open])

    if (actions.length === 0) return <></>

    function showMoreHandle() {
        setOpen(!open)
    }

    if (unfolded) {
        return <div className={complexClassName}>
            {actions.map(action => <span key={action.id} className='ActionButton' onClick={(e) => {
                e.stopPropagation();
                action.onClick();
            }}>{action.icon}</span>)}
        </div>
    }

    return <div className={complexClassName}>
        <span className='ActionButton' onClick={showMoreHandle} >
            {!open && <MoreHorizIcon className='Icon'/>}
            {open && <CloseIcon className='Icon'/>}
        </span>
        <Slide direction={direction || 'right'} in={(open)} mountOnEnter unmountOnExit timeout={100}>
            <div className='actions'>
                {actions.map(action => <span key={action.id} className='ActionButton' onClick={(e) => {
                    e.stopPropagation();
                    action.onClick();
                }}>{action.icon}</span>)}
            </div>
        </Slide>
    </div>
}
