import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Button from '@mui/material/Button';

export default function EmailButton(props) {
    const {label, email, fullWidth} = props;
    
    if (!email) return <></>

    return <Button startIcon={<AlternateEmailIcon />} variant="outlined" fullWidth={fullWidth} target="_blank" href={`mailto:${email}`}>{label}</Button>
}
