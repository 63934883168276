import {getClientConfig} from 'ultra/configs/general';

import {get} from '../../../Helpers/http';

const {api} = getClientConfig(window)

export function getConversation(cid, search) {
    let link = `${api}/messages/conversation/${cid}`;
    if (search) link += search
    return get(link);
}
