import Skeleton from '@mui/material/Skeleton';

import './index.scss';

export default function PreloaderLinksBlocks() {
    return <ul className='LinksList PreloaderLinksBlocks PreloaderLinksBlocks'>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"70%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"50%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} width={"80%"} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"75%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} width={"90%"} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"70%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"50%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} width={"80%"} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"75%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} width={"90%"} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"70%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"50%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} width={"80%"} /></div>
                </div>
            </div>
        </li>
        <li>
            <div className='item'>
                <div className='icon'>
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className='information'>
                    <div className='title'><Skeleton variant="rectangular" height={20} width={"75%"} style={{marginBottom: '1rem', marginTop: '.2rem'}}/></div>
                    <div className='description'><Skeleton variant="rectangular" height={10} width={"90%"} /></div>
                </div>
            </div>
        </li>
    </ul>
}
