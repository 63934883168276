import { useEffect, useState } from "react";
import {useLocation} from 'react-router-dom'
import {isEmptyObj} from 'ultra/helpers/utils';

import {DEFAULT_CITY} from 'ultra/const/general';
import {normalizeRoute} from 'ultra/helpers/route';
import {getClientCity} from 'ultra/configs/general';

import {getNodeLinks} from '../../../../../../../Helpers/content'

import {hasFullAccess} from 'ultra/helpers/auth';

import TopPagePicture from '../../../../../Widgets/TopPagePicture'
import LinksList from '../../../../../Widgets/LinksList'
import Links from '../../../../../Widgets/Links'
import CKEditorContent from '../../../../../Widgets/CKEditorContent'
import TitleWidget from '../../../../../Widgets/Title'

import {useNavigationStore} from '../../../../../../../Stores/navigation';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';

import './index.scss'

export default function Template(props) {
    const {contentLoading} = useNavigationStore()
    const {userState} = useGlobalCustomContext();

    const {content, permits, config, children, breadcrumbs} = props

    const location = useLocation();

    const [links, setLinks] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [absentInConfig, setAbsentInConfig] = useState({})
    const [controllers, setControllers] = useState([])

    const clearRequests = () => {
        controllers.map(controller => {
            controller.abort()
        })
    }

    useEffect(() => {
        setIsLoading(true)
        const city = getClientCity(window) || DEFAULT_CITY;
        const {promise, controller} = getNodeLinks({city, uri: normalizeRoute(location.pathname)})
        setControllers(controllers.concat([controller]));

        promise
            .then(links => {
                setIsLoading(false)

                // if (links && isEmptyObj(links.data) && isEmptyObj(links.order)) return;

                const list = {}
                links.order.map(did => {
                    list[links.data[did]._uri] = links.data[did]
                })

                setLinks(list)

                const absent = {
                    list: {},
                    order: [],
                    permits: {}
                }

                Object.keys(children.list).map(uri => {
                    if (!list[uri]) {
                        absent.list[uri] = children.list[uri]
                        absent.permits[uri] = children.permits[uri]
                        absent.order.push(uri)
                    }
                })


                setAbsentInConfig(absent)
            })
        
        return () => {
            clearRequests();
        }
    }, [content])

    return <div className="LinksTemplate">
        <TopPagePicture cutHeightForDesktop image={content.banner} />

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>

        <LinksList
            showSkeleton={isLoading || contentLoading}
            links={links}
        />

        {content.description && <div className="pageDescription">
            <CKEditorContent showSkeleton={isLoading || contentLoading} content={content.description} />
        </div>}

        {hasFullAccess(userState?.user)
            && absentInConfig && !isEmptyObj(absentInConfig.list) && !isLoading && !contentLoading && <div className='adminChildrensList'>
                <div className="label">Відсутні в конфігурації:</div>
                <Links
                    className='notInConfig'
                    order={config.childrenOrder || content.childrenOrder}
                    links={absentInConfig}
                />
            </div>
        }
    </div>
}
