import {useNavigationStore} from '../../../../../../../../Stores/navigation'

import LinksTile from '../../../../../../Widgets/LinksTile'
import LinksTileWithCategories from '../../../../../../Widgets/LinksTileWithCategories'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()

    let hasCategories = false
    children.order.map(id => {
        if (hasCategories) return;

        if (children.list[id].category) {
            hasCategories = true;
        }
    })

    return <div className='Tile'>
        <TopPagePicture cutHeightForDesktop image={content.banner} />
        {/* showSkeleton={contentLoading}  */}

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {config?.templateOptions?.descriptionPosition !== 'bottom' && <CKEditorContent content={content.description} />}

        {(!hasCategories && !config.categories) && <LinksTile
            xs={6} md={3}
            imgLoading={config?.templateOptions?.imgLoading}
            
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}
            isExternalLinksSection={config?.templateOptions?.isExternalLinksSection}
        />}

        {(hasCategories || config.categories) && <LinksTileWithCategories
            xs={6} md={3}
            permits={permits}
            
            categoriesConfig={config.categories}
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}
            isExternalLinksSection={config?.templateOptions?.isExternalLinksSection}
        />}

        {config?.templateOptions?.descriptionPosition === 'bottom' && <CKEditorContent content={content.description} />}
    </div>
}
