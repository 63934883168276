import {useEffect, useState} from 'react';

import TelegramIcon from '@mui/icons-material/Telegram';

import {getTelegramContact} from "ultra/helpers/messengers";
import {getTelegramLink, getWhatsupLink} from 'ultra/helpers/messengers';
import {isEmptyMessengerData} from 'ultra/helpers/utils';

import './index.scss'
import WhatsApp from '@mui/icons-material/WhatsApp';

export default function MessengerValue(props) {
    const {messenger} = props;

    const [link, setLink] = useState();
    const [number, setNumber] = useState();

    useEffect(() => {
        if (messenger?.telegram) {
            setLink(getTelegramLink(getTelegramContact(messenger.telegram.number, false))?.href);
            setNumber(getTelegramContact(messenger.telegram.number));
        }
        else if (messenger?.whatsup) {
            setLink(getWhatsupLink(messenger.whatsup.number)?.href);
            setNumber(messenger.whatsup.number);
        }
    }, [])

    if (isEmptyMessengerData(messenger)) return <></>

    return <span className='MessengerValue'>
        {messenger?.telegram && <>
            <TelegramIcon className='Icon' />
            <a className='link' href={link} target="_blank">{messenger?.telegram?.label || number}</a>
        </>}

        {messenger?.whatsup && <>
            <WhatsApp className='Icon' />
            <a className='link' href={link} target="_blank">{messenger?.telegram?.label || number}</a>
        </>}
    </span>
}
