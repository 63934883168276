import React from 'react';
import {isEmptyObj} from 'ultra/helpers/utils';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DOMPurify from "dompurify";

import './index.scss'

export default function FAQ(props) {
  const {data} = props

  if (isEmptyObj(data)) return <></>

  return (
    <div className='FAQ'>
      {data?.general?.map.length > 0 &&
        <div className='FAQSection'>
          <div className='subtitle2'>Спільна інформація</div>
          {data?.general?.map(item => <Accordion key={`general_${item.id}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.title) }} />
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.details) }} />
              </AccordionDetails>
            </Accordion>)}
      </div>}

      {data?.vuso?.map.length > 0 &&
        <div className='FAQSection'>
          <div className='subtitle2'>VUSO</div>
          {data?.vuso?.map(item => <Accordion key={`vuso_${item.id}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.title) }} />
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.details) }} />
              </AccordionDetails>
            </Accordion>)}
      </div>}

      {data?.arx?.map.length > 0 &&
        <div className='FAQSection'>
          <div className='subtitle2'>ARX</div>
          {data?.arx?.map(item => <Accordion key={`arx${item.id}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.title) }} />
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.details) }} />
              </AccordionDetails>
            </Accordion>)}
      </div>}
    </div>
  );
}
