import {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import {useNavigate} from 'react-router-dom';

import {DEFAULT_CHILDREN} from "ultra/const/general";

import './index.scss'

export default function PaginationWidget(props) {
    const {pagination} = props

    const [page, setPage] = useState(); // TODO: add pagination?.current  ?
    const navigate = useNavigate();

    const navigateTo = (page) => {
        let url = new URL(window.location.href);
        
        if (page === 1) {
            url.searchParams.delete('page');
            url.searchParams.delete('perPage');
        } else {
            url.searchParams.set('page', page);
            if (!url.searchParams.get('perPage')) {
                url.searchParams.set('perPage', DEFAULT_CHILDREN.PER_PAGE);
            }
        }
        navigate(url.pathname + url.search);
    }

    const handleChangePage = (event, newPage) => {
        navigateTo(newPage);
    };

    useEffect(() => {
        setPage(pagination?.current)
    }, [pagination])


    if (!page || !pagination) {
        return <></>
    }

    if (pagination.pages < 2) {
        return <></>
    }

    return <div className="Pagination">
        <Pagination count={pagination.pages} page={page} onChange={handleChangePage} />
    </div>
}
