export function getAmountOfFiles(list) {
    if (!list) return 'Запущено видалення файлів що не використовуються, але не знайдено результатів';

    let i = 0;

    Object.keys(list).map(did => {
        i += list[did].length;
    })

    if (i === 1) {
        return 'Видалено файл що не використовується';
    }

    return 'Видалено ' + i + ' файлів що не використовуються';
}

export default function Template(props) {
    const {values} = props;

    return <span>{getAmountOfFiles(values?.details)}</span>
}
