export function getShopDetails(node, breadcrumbs) {
    var shop;
    var uri;
    if (!node || !breadcrumbs)
        return null;
    if (node.partner) {
        return {
            uri: node._uri,
            shop: node
        };
    }
    Object.keys(breadcrumbs).map(function (uri) {
        if (shop)
            return false;
        if (breadcrumbs[uri].partner) {
            shop = breadcrumbs[uri];
            uri = uri;
        }
    });
    return { uri: uri, shop: shop };
}
export function totalPrice(items) {
    var sum = 0;
    items.map(function (item) {
        sum += item.amount * (item.details.discount || item.details.price);
    });
    return sum;
}
