import DefaultImage from '../DefaultImage';

import './index.scss';

export default function Avatar(props) {
    return <div className={`AvatarWrap ${props.minimal === 'true' ? 'AvatarMinimal' : ''}`} onClick={props.onClick}>
        {props.roles && <span className="roles">{props.roles.join(', ')}</span>}

        {props.image && <DefaultImage {...props} className={`${props.className || ''} Avatar`} />}
        {!props.image && <DefaultImage image="config/images/default/thumbnail_dark.png" className={`${props.className} Avatar noPhoto`} />}
    </div>
}
