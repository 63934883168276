import Content from  '../../Modules/Content';
import {getNodeContent} from '../../Helpers/content';
import {getNodeContentLoader} from '../../Helpers/loader';

let controllers = []

export const routes = [
    {
        index: true,
        element: <Content/>,
        loader: getNodeContentLoader(controllers, getNodeContent),
    },
    {
        path: "*",
        element: <Content/>,
        loader: getNodeContentLoader(controllers, getNodeContent),
    },
]
