import {useNavigationStore} from '../../../../../../../../Stores/navigation';

import FormContainer from "../../../../../../../../Components/Form/FormContainer";

import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'

import './index.scss'

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props
  
    const {contentLoading} = useNavigationStore()

    return <div className="FormTemplate">
        {content.banner && <TopPagePicture showSkeleton={contentLoading} cutHeightForDesktop image={content.banner} />}

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {config?.templateOptions?.descriptionPosition === 'top' && <CKEditorContent showSkeleton={contentLoading} content={content.description} />}

        <div className="form">
          <FormContainer showSkeleton={contentLoading} config={content} />
        </div>

        {config?.templateOptions?.descriptionPosition !== 'top' && <CKEditorContent showSkeleton={contentLoading} content={content.description} />}
    </div>
}
