import Chip from '@mui/material/Chip';

import FilterValue from '../FilterValue';

import './index.scss';

export default function FilterChip(props) {
    const {value, config, className} = props;
    if (!value) return <></>
    return <Chip className={className} label={<FilterValue value={value} config={config} />} variant="outlined" />
}

