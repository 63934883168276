import React, {useState, useEffect} from 'react';
import DOMPurify from "dompurify"

import {getClientCity} from 'ultra/configs/general';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {getFilter} from '../../../../Helpers/filters';

import {useConfigStore} from '../../../../Stores/config';

import './index.scss'

export default function TourComplexity(props) {
    const {complexity, config} = props;

    const {configs} = useConfigStore();
    const {filters} = configs;

    const city = getClientCity(window);
    const [filter, setFilter] = useState();

    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(complexity);

    const handleChange = (panel) => (e, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  
    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        setFilter(getFilter(filters, city, config?.source));
    }, [complexity])


    if (!filter || !complexity) {
        return <></>
    }

    return <div className="TourComplexity">
        <span className='TourComplexityLabel'>Рівень складності:</span>
        <span>{filter.values[complexity]}</span>
        <span className='information'><InfoOutlinedIcon onClick={handleClickOpen} /></span>

        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                Рівень складності
                <CloseIcon className='close' onClick={handleClose} />
            </DialogTitle>
            <DialogContent>
                <div className='ComplexityDetails'>

                    {filter.order.map(item => <Accordion key={`complexity_${item}`} expanded={item === expanded} onChange={handleChange(item)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div>{filter.values[item]}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(filter.description[item]) }} />
                        </AccordionDetails>
                    </Accordion>)}
                </div>
            </DialogContent>
        </Dialog>
    </div>
}
