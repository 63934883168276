import {create} from 'zustand'
// import {getClientCity} from 'ultra/configs/general';
// import {DEFAULT_CITY} from 'ultra/const/general';

function getFieldsFromProduct(product) {
  // triggered from shopping card
  if (product.id) {
    return product;
  }

  const {_uri, title, price, discount, thumbnail} = product
  // const city = getClientCity(window) || DEFAULT_CITY;

  return {
    id: product.did || product._did,
    _uri,
    title,
    price,
    // city,
    thumbnail,
    discount,
  }
}

function getFieldsFromShop(shop) {
  // triggered from shopping card so no need to be updated
  if (shop.id) {
    return shop;
  }

  if (!shop.partner || !shop.partner.config) return {}
  const result = {}

  const {orderForm, currency} = shop.partner.config;
  const title = shop.partner.title;

  result.partner = shop.partner.id;
  result.id = shop.partner.shop;

  if (title) result.title = title;
  if (currency) result.currency = currency;
  if (orderForm) result.orderForm = orderForm;

  if (shop.partner.city) result.city = shop.partner.city;
  if (shop.partner.link) result.link = shop.partner.link;

  return result;
}

export const useShoppingStore = create((set) => ({
  cart: JSON.parse(localStorage.getItem('cart')) || [],
  clearCard: () => set(() => {
    localStorage.setItem('cart', JSON.stringify([]))
    return {cart: []};
  }),
  addProduct: (product, shop) => set((state) => {
    const result = [...state.cart]
    result.push({
      product: getFieldsFromProduct(product),
      shop: getFieldsFromShop(shop)
    })
    // return {cart: state.cart}
    localStorage.setItem('cart', JSON.stringify(result))
    return {cart: result};
  }),
  removeShop: (storeId) => set((state) => {
    let cart = [...state.cart]
    const newCart = []
    cart.map((item) => {
      if (item.shop.id == storeId) return;
      newCart.push(item)
    })
    
    localStorage.setItem('cart', JSON.stringify(newCart))
    return {cart: newCart}
  }),
  removeProduct: (product) => set((state) => {
    let cart = [...state.cart]

    const id = product.did || product._did || product.id

    const productToRemoveIndex = cart.findIndex(i => ((i.product.id == id)))
    if (productToRemoveIndex > -1) {
      cart.splice(productToRemoveIndex, 1)
    }

    localStorage.setItem('cart', JSON.stringify(cart))
    return {cart}
  }),
  removeAllProduct: (product) => set((state) => {
    let cart = [...state.cart]

    const id = product.did || product._did || product.id

    const updatedCart = cart.filter(i => ((i.product.id !== id)))

    localStorage.setItem('cart', JSON.stringify(updatedCart))
    return {cart: updatedCart}
  }),
}))
