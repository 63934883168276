import React, {useState, useEffect} from 'react';

import {useNavigation} from "react-router-dom";

import Badge from '@mui/material/Badge';

import Button from '@mui/material/Button';

import FilterListIcon from '@mui/icons-material/FilterList';

import {useConfigStore} from '../../../../Stores/config';

import {getNumberOfSelectedFilters} from '../../Helpers/filters'; // getFiltersFieldsConfig

import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import Filters from './Filters';

import './index.scss'

export default function FilterButtonWidget(props) {
    const {children, showFilters, defaultOrder} = props;

    const {configs} = useConfigStore();
    
    const [open, setOpen] = React.useState(false);
    const [hasShowFiltersButton, setHasShowFiltersButton] = React.useState(false);
    const [activeFiltersNumber, setActiveFiltersNumber] = useState(0)

    const {state} = useNavigation();

    useEffect(() => {
        setActiveFiltersNumber(getNumberOfSelectedFilters(showFilters, defaultOrder));
    }, [children])

    useEffect(() => {
        setHasShowFiltersButton(isHasShowFiltersButton());
    }, [state])

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const isHasShowFiltersButton = () => {
        if (!showFilters) return false;
        if (!showFilters.filterBy || !showFilters.filterBy.length) return false;

        // const nodes = configs.content.nodes;
        // const filters = getFiltersFieldsConfig(showFilters.filterBy, children, nodes);
        const filters = showFilters.filterBy;

        const showFilterBy = filters && filters.length > 0;
        const showOrderBy = showFilters.orderBy && Object.keys(showFilters.orderBy).length > 0;
        const showRangeBy = showFilters.rangeBy && Object.keys(showFilters.rangeBy).length > 0;

        if (showFilterBy || showOrderBy || showRangeBy) return true

        return false;
    }

    if (!hasShowFiltersButton) return <></>

    return <>
        <Button size="small" variant='outlined' onClick={handleClickOpen}>
            <Badge className='FilterWidgetIconBadge' badgeContent={activeFiltersNumber} color="error">
                <FilterListIcon />
            </Badge>
        </Button>

        <AdaptiveDialog open={open} onClose={handleClose}>
            <Filters
                configs={configs}
                children={children}
                showFilters={showFilters}
                defaultOrder={defaultOrder}
                activeFiltersNumber={activeFiltersNumber}
                setOpen={setOpen}
                handleClose={handleClose}
            />
        </AdaptiveDialog>
    </>
}
