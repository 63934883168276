import {create} from 'zustand'

import {initializeApp} from 'firebase/app';
import {getAuth} from "firebase/auth";

import {getClientConfig} from 'ultra/configs/general';
// import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const {firebase} = getClientConfig(window);

const app = initializeApp(firebase);

export const useFirebaseStore = create(() => ({
    // db: getFirestore(app),
    storage: getStorage(app),
    auth: getAuth(app)
}))
