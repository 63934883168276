import {isYoursAccount} from './user';

export function getConversationTitle(configs, conversation, userState, users) {
    let title;
    if (conversation.notification) {
        const topic = configs.notifications.topics[conversation.topic];

        if (topic.partner) {
            title = conversation.partner_title;
        }
        else {
            title = topic.title;
        }
    }

    if (conversation.conversation) {
        title = conversation.participants.filter(i => !isYoursAccount(userState, i)).map(u => users[u].displayName).join(', ')
    }

    return title;
}
