import React from 'react';
import {useConfigStore} from '../../../../Stores/config';

import './index.scss'

export default function UserNodesList(props) {
    const {list, type} = props;
    const {configs} = useConfigStore();

    if (!list) return <></>

    if (type) {
        return Object.keys(list)
            .filter(city => Object.keys(list[city]).length > 0)
            .map(city =>
                <div className='UserNodesList' key={`user_nodes_list_${city}`}>
                    <span className='cityName'><i>{configs.content.cities[city]?.city || "Головна"}:</i> </span>
                    <span className='cityValues'>
                        <span key={`user_nodes_list_${city}_${type}`}>
                            {list[city][type]}
                        </span>
                    </span>
                </div>
            )
    }

    return Object.keys(list)
        .filter(city => Object.keys(list[city]).length > 0)
        .map(city =>
            <div className='UserNodesList' key={`user_nodes_list_${city}`}>
                <span className='cityName'><i>{configs.content.cities[city]?.city || "Головна"}:</i> </span>
                <span className='cityValues'>
                    {Object.keys(list[city]).map((type, i) =>
                        <span key={`user_nodes_list_${city}_${type}`}>
                            {configs.content.nodes[type].title}: {list[city][type]}
                            {i + 1 != Object.keys(list[city]).length && <>, </>}
                        </span>
                    )}
                </span>
            </div>
        )
}
