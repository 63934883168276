import dayjs from 'dayjs';

import Button from '@mui/material/Button';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import PriceWidget from '../../../../../../../../Widgets/Price';

export default function RulesStep(props) {
    const {content, belongsToLibrary, submit} = props;

    const city = getClientCity(window) || DEFAULT_CITY;

    return <>
            {<div className='section'>
                {content?.deposit?.price && <div className="rule">
                    <span className='sectionIcon'>🏦 </span>
                    <span>Вам необхідно внести <b className='nowrap'>депозит <PriceWidget value={content.deposit} /></b></span>
                    {/* за книгу "{content.title}" */}
                </div>}
                {content.maxTerm && <div className="rule">
                    <span className='sectionIcon'>⏳ </span>
                    <span>Книгу необхідно повернути до <b>{dayjs().add(content.maxTerm, 'week').format('YYYY.MM.DD')}</b></span>
                </div>}
                {content.condition && <div className="rule">
                    <span className='sectionIcon'>🤲🏻 </span>
                    <span>
                        Стан книги: <b>{content.condition.join(', ').toLowerCase()}</b>
                    </span>
                </div>}

                <div className="rule">
                    <span className='sectionIcon'>📖 </span>
                    Ви можете взяти до 2х книг в бібліотеці
                </div>
            </div>}

            <div className="submit">
                <Button variant="contained" fullWidth onClick={submit}>Приймаю умови</Button>
            </div>
    </>
}
