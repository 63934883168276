var DEFAULT = "default";
export var NO_CITY = 'no_city';
export var DEFAULT_CITY = DEFAULT;
export var MASTER_DB = DEFAULT;
export var STORAGE_DOMAIN = "https://storage.googleapis.com";
export var DEFAULT_CHILDREN = {
    PAGE: 1,
    PER_PAGE: 12,
    ORDER: { title: 'asc' },
    RANGE: 'asc',
};
