import {getClientCity} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';

import CurrencyFormated from '../../../../Components/CurrencyFormated'
import {useConfigStore} from '../../../../Stores/config';

import './index.scss';

export default function ShopProductPrice(props) {
    const {product, currency} = props;

    const {configs} = useConfigStore();
    const city = getClientCity(window) || DEFAULT_CITY;

    if (!product) return <></>

    if (product.discount) {
        return <span className="ShopProductPrice">
            <span className='oldPrice'>
                <CurrencyFormated value={product.price} currency={currency || configs?.content?.cities?.[city]?.currency} />
            </span>
            <CurrencyFormated value={product.discount} currency={currency || configs?.content?.cities?.[city]?.currency} />
        </span>
    } else {
        return <span className="ShopProductPrice">
            <CurrencyFormated value={product.price} currency={currency || configs?.content?.cities?.[city]?.currency} />
        </span>
    }
}
