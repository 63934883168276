import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FormHelperText from '@mui/material/FormHelperText';

export default function Password(props) {
    const {content, disabled, field, isEditMode, error, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    const onPasswordDublicateControlChangeHandler = (e) => {
        onChange(e, `${field.id}-dublicate`)
    }

    if (isEditMode) return null;

    return <>
        <FormControl fullWidth required={field.required} variant="outlined">
        <InputLabel error={showErrorText}>{field.placeholder}</InputLabel>
        <OutlinedInput
            disabled={isDisabled}
            type={showPassword ? 'text' : 'password'}
            error={showErrorText}
            value={content[field.id] || ''}
            onChange={onChange}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
            label={field.placeholder}
        />
        </FormControl>
        <br/>
        <br/>
        <FormControl fullWidth required={field.required} variant="outlined">
        <InputLabel error={showErrorText}>Введіть пароль повторно</InputLabel>
        <OutlinedInput
            error={showErrorText}
            disabled={isDisabled}
            type={showPassword ? 'text' : 'password'}
            value={content[`${field.id}-dublicate`] || ''}
            onChange={onPasswordDublicateControlChangeHandler}
            label='Введіть пароль повторно'
        />
        {showErrorText && <FormHelperText error={showErrorText}>{error}</FormHelperText>}
        </FormControl>
    </>
}