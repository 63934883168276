import React, {useEffect, useState} from 'react';
import {isUndefined} from 'lodash';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './index.scss';

export default function Carousel(props) {
    const {children, effect, navigation, pagination, slidesPerView, className} = props;

    // https://swiperjs.com/demos
        // https://www.bacancytechnology.com/blog/react-carousel
        // https://medium.com/tinyso/how-to-create-the-responsive-and-swipeable-carousel-slider-component-in-react-99f433364aa0
        // https://github.com/leandrowd/react-responsive-carousel
        // https://builtin.com/articles/react-carousel-component-libraries
        // https://trendyol.github.io/react-carousel/docs/scrolling-carousel-api

    const [finalClassName, setFinalClassName] = useState();

    useEffect(() => {
        let finalClassName = 'Carousel'
        if (navigation) {
            finalClassName += ` hasNavigation`
        }
        setFinalClassName(finalClassName)
    }, [navigation])

    return <div className={finalClassName}>
        <div className={className}>
            <Swiper autoHeight={true} pagination={isUndefined(pagination) ? {clickable: true} : pagination} modules={[EffectFade, Pagination, Navigation]} effect={effect} slidesPerView={slidesPerView} navigation={navigation}>
                {children.map(child => <SwiperSlide key={child.key}>{child}</SwiperSlide>)}
            </Swiper>
        </div>
    </div>
}
