import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import LinksLines from '../../../../../../Widgets/LinksLines'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs';
import TitleWidget from '../../../../../../Widgets/Title';
import FilterChip from '../../../../../../Widgets/FilterChip'

import './index.scss'

export default function Template(props) {
    const {content, children, config, permits, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()
    const {configs} = useConfigStore();

    return <div className="NodeLinesCompactTemplate">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        <LinksLines
            config={config}
            
            permits={permits}
            showFilters={config?.showFilters || content?.showFilters}
            showSkeleton={contentLoading}
            links={children}
            order={config.childrenOrder || content.childrenOrder}

            label={(item) => <FilterChip value={item.region} config={configs.content.nodes[item._type].fields.region} />}
        />
    </div>
}
