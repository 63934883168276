import LinksTile from '../LinksTile';

import './index.scss'

export default function LinksLines(props) {
    const {config, showSkeleton, showFilters, showPagination, links, order, label, spacing, xs, md, title} = props;

    return <LinksTile
        className={`LinksLines`}
        imgLoading={config?.templateOptions?.imgLoading}
        showSkeleton={showSkeleton}
        showFilters={showFilters}
        showPagination={showPagination}
        title={title}
        links={links}
        order={order}
        spacing={spacing}
        xs={xs}
        md={md}
        label={label}
        src="banner"/>
}
