import {useState, useEffect, useRef} from 'react';
import {isArray} from "lodash";
import mediumZoom from 'medium-zoom';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity, getClientEnv, getClientImageUrl, getClientDefaultImageUrl} from 'ultra/configs/general';

export default function ContentImage(props) {
    const {city, image, src, className, onClick, width, height, zoom, imgLoading} = props;

    const [img, setImg] = useState();
    const zoomRef = useRef(null)

    useEffect(() => {
        if (!image && src) {
            setImg(getClientDefaultImageUrl(window, `config/images/default/${src}.png`))
        }
        else {
            const env = getClientEnv(window);
            const imgCity = city || getClientCity(window) || DEFAULT_CITY;
            setImg(getClientImageUrl(env, imgCity, isArray(image) ? image[0] : image))
            // src={getClientDefaultImageUrl(window, (!image && src) ? `config/images/default/${src}.png` : image)}
        }
    }, [image, src])

    // https://medium-zoom.francoischalifour.com/
    // https://github.com/francoischalifour/medium-zoom/tree/master/examples/react
    const attachZoom = node => {
        if (zoom) {
            if (zoomRef.current === null) {
                zoomRef.current = mediumZoom({margin: 30})
            }

            const zone = zoomRef.current

            if (node) {
                zone.attach(node)
            } else {
                zone.detach()
            }
        }
    }

    return <img
        width={width}
        height={height}
        loading={imgLoading || 'lazy'}
        className={className}
        onClick={onClick}
        ref={attachZoom}
        src={img}
    />
}
