exports.LOG_TYPES = {
    ERROR: "error",
    INFO: "info",
};
exports.NOTIFY_TELEGRAM = {
    SENDED: "telegram_sended",
};
exports.NOTIFY_INSURANCE = {
    REQUEST: "insurance_request",
    SUPPORT: "insurance_support",
};
exports.NOTIFY_NETWORKING = {
    REQUEST: "notify_networking_request",
};
exports.NOTIFY_REQUESTS = {
    FORM_SUBMIT: "form_request_submit",
    SHOP_SUBMIT: "shop_request_submit",
};
exports.HISTORY_LIBRARY = {
    TRANSFER_TO_LIBRARY: "library_transfer_to_library",
    RESERVE: "library_reserve",
    RESERVE_NOT_APPROVED: "library_reserve_not_approved",
    RESERVE_APPROVED: "library_reserve_approved",
    RETURN: "library_return",
    CANCEL: "library_cancel",
    RETURN_APPROVE: "library_return_approve",
};
exports.HISTORY_NODE = {
    CREATE: "node_create",
    REMOVE: "node_remove",
    VALIDATE: "node_validate",
    UNVALIDATE: "node_unvalidate",
    UPDATE: "node_update",
    UPDATE_FILE_UPLOAD: "node_update_file_upload",
    UPDATE_FILE_UPLOAD_ERROR: "node_update_file_upload_error",
    REMOVE_FILE: "node_remove_file",
    RENAME: "node_rename",
    MOVE: "node_move",
    RESTORE: "node_restore",
    REMOVE_NOT_USED_FILES: "node_remove_not_used_files",
    WORKER_CREATE: "node_worker_create",
    WORKER_UPDATE: "node_worker_update",
    WORKER_MOVE: "node_worker_move",
    WORKER_RENAME: "node_worker_rename",
    WORKER_REMOVE: "node_worker_remove",
    WORKER_RESTORE: "node_worker_restore",
};
exports.NOTIFY = {
    READED: "notify_readed",
    FLUSH: "notify_flush",
};
exports.HISTORY_USER = {
    FLUSH: "history_flush",
    CONTENT_CLEAN: "history_content_clean",
    CONTENT_FLUSH: "history_content_flush",
    USER_CLEAN: "history_user_clean",
    USER_FLUSH: "history_user_flush",
    LOGIN: "user_login",
    LOGOUT: "user_logout",
    UPDATE: "user_update",
    CREATE: "user_create",
    AVATAR_UPLOAD_ERROR: "user_image_upload_error",
    VALIDATE: "user_validate",
    BLOCK: "user_block",
    BLOCK_COMMENT_CHANGE: "user_block_comment_change",
    UNBLOCK: "user_unblock",
    EMAIL_VALIDATION_REQUEST_AFTER_LOGIN: "user_email_validation_request_after_login",
    EMAIL_VALIDATION_REQUEST_AFTER_LOGIN_ERROR: "user_email_validation_request_after_login_error",
    EMAIL_VALIDATION_SUCCESS: "user_email_validation_success",
    EMAIL_VALIDATION_ERROR: "user_email_validation_error",
    EMAIL_VALIDATION_CODE_ERROR: "user_email_validation_code_error",
    PASSWORD_UPDATE_REQUEST_EMAIL_SUCCESS: "user_password_update_request_email_success",
    PASSWORD_UPDATE_REQUEST_EMAIL_ERROR: "user_password_update_request_email_error",
    PASSWORD_UPDATE_CODE_VERIFY_SUCCESS: "user_password_update_code_verify_success",
    PASSWORD_UPDATE_CODE_VERIFY_ERROR: "user_password_update_code_verify_error",
    PASSWORD_UPDATE_CONFIRM_SUCCESS: "user_password_update_confirm_success",
    PASSWORD_UPDATE_CONFIRM_ERROR: "user_password_update_confirm_error",
    PASSWORD_UPDATE_AUTOLOGIN_SUCCESS: "user_password_update_autologin_success",
    PASSWORD_UPDATE_AUTOLOGIN_ERROR: "user_password_update_autologin_error",
};
exports.LOG_ERROR = {
    NODE: {
        CHANGE_PROPOSE: "node_change_propose_error",
        CHANGE_MODERATOR: "node_change_moderator_error",
        GET_LIST: "node_get_list_error",
        NO_HISTORY_RECORD: "node_no_history_record_error",
        RESTORE: "node_restore_error",
        RESTORE_WORKER: "node_restore_worker_error",
        PATH_NOT_EXIST: "node_path_not_exist_error",
        GET_TAG: "node_get_tag_error",
        CREATE_SINGLETON: "node_create_singleton_error",
        ALIAS: "node_alias_error",
        UPLOAD_FILE: "node_upload_file_error",
        UPLOAD_FILE_DID: "node_upload_file_did_error",
        UPLOAD_FILE_NO_FILE: "node_upload_file_no_file_error",
        UPLOAD_FILE_NO_DATA: "node_upload_file_no_data_error",
        UPLOAD_FILE_NO_BASE_INFO: "node_upload_file_no_base_info_error",
        DELETE_FILE: "node_delete_file_error",
        GET: "node_read_node_error",
        NEW: "node_new_node_error",
        NEW_PARENT_CHECK: "node_new_parent_check_error",
        NEW_RESERVED_PATH: "node_new_reserved_path_error",
        NEW_ALREADY_EXIST: "node_new_already_exist_error",
        NEW_POST: "node_new_post_error",
        DELETE_FILES: "node_delete_files_error",
        DELETE: "node_delete_error",
        DELETE_CHILD_PERMITS: "node_delete_child_permits_error",
        DELETE_CHILD_NOT_DELETABLE: "node_delete_child_not_deletable_error",
        UPDATE: "node_update_node_error",
        UPDATE_PARENT_CHECK: "node_update_parent_check_error",
        UPDATE_VALIDATION: "node_update_validation_error",
        UPDATE_EXTRA_FIELD: "node_update_extra_field_error",
        UPDATE_MAIN_PAGE_HAS_PARENT: "node_update_main_page_has_parent_error",
        UPDATE_MAIN_PATH_CHANGE: "node_update_main_path_change_error",
        UPDATE_CHILD_PERMITS: "node_update_child_permits_error",
        UPDATE_CHILD_NOT_UPDATABLE: "node_update_child_not_updatable_error",
        UPDATE_RESERVED_PATH: "node_update_reserved_path_error",
        UPDATE_ALREADY_EXIST: "node_update_already_exist_error",
        UPDATE_MOVE_PARENT_NOT_EXIST: "node_update_move_parent_not_exist_error",
        UPDATE_MOVE_PERMITS: "node_update_move_permits_error",
        UPDATE_MOVE: "node_update_move_error",
        UPDATE_MOVE_CHILD: "node_update_move_child__error",
        UPDATE_POST: "node_update_post_error",
        VALIDATE: "node_validate_node_error",
        HISTORY_CLEAN: "node_history_clean_error",
        HISTORY_FLUSH: "node_history_flush_error",
        SAVE: "node_save_error",
        DID_MORE_THEN_ONE: "node_did_more_then_one",
        DID_NOT_FOUND: "node_did_not_found",
        DID_NOT_FOUND_HISTORY: "node_did_not_found_history",
    },
    LIBRARY: {
        NO_BOOK: "library_no_book_error",
        GET_MY_LIBRARY: "library_get_my_library_error",
        NO_OWNER: "library_no_owner_error",
        NO_READER: "library_no_reader_error",
        NOT_READING_BY_YOU: "library_not_reading_by_you_error",
        LIMITS_REACHED: "library_limits_reached",
        NOT_POSSIBLE_RESERVE_OWN_BOOK: "library_not_possible_reserve_own_book_error",
        NOT_YOURS: "library_permits_not_yours_error",
        UPLOAD_DEPOSIT: "library_upload_deposit_error",
        UPLOAD_DEPOSIT_NO_FILE: "library_upload_deposit_no_file_error",
        UPLOAD_DEPOSIT_NO_DATA: "library_upload_deposit_no_data_error",
        UPLOAD_DEPOSIT_NO_BASE_INFO: "library_upload_deposit_no_base_info_error"
    },
    USER: {
        NOT_AUTH: "user_not_authorised",
        REGISTRATION_DISABLED: "user_registration_disabled_error",
        REGISTRATION_NO_CONFIRM: "user_registration_no_confirm_error",
        REGISTRATION_ALREADY_EXIST: "user_registration_already_exist_error",
        REGISTRATION_SAVE_TO_DB: "user_registration_save_to_db_error",
        REGISTRATION_PROVIDER_EMAIL_USED: "user_registration_provider_email_used_error",
        REGISTRATION_PROVIDER_EMAIL_INCORRECT: "user_registration_provider_email_incorrect_error",
        REGISTRATION_PROVIDER: "user_registration_provider_error",
        UPDATE: "user_update_error",
        UPDATE_NO_AUTH: "user_update_no_auth_error",
        UPDATE_NOT_BELONG: "user_update_not_belong_error",
        UPDATE_NO_USER: "user_update_no_user_error",
        VALIDATE_EMAI: "user_validate_email_error",
        VALIDATE_EMAIL_NOT_AUTH: "user_validate_email_not_auth_error",
        VALIDATE_EMAIL_NO_REQUESTS: "user_validate_email_no_requests_error",
        VALIDATE_EMAIL_WRONG_EMAIL: "user_validate_email_wrong_email",
        SAVE_RECORD_NO_EMAIL: "user_save_record_no_email",
        SAVE_RECORD_NOT_CORRECT_EMAIL: "user_save_record_not_correct_email",
        SAVE_RECORD_NO_ACTION: "user_save_record_no_action",
        SAVE_RECORD_NOT_CORRECT_ACTION: "user_save_record_not_correct_action",
        NOTIFY_TOKEN_SAVE: "user_notify_token_save",
        GET_USER_NO_ID: "user_get_user_no_id",
        UNBLOCK: "user_unblock_error",
        BLOCK: "user_block_error",
        BLOCK_COMMENT_CHANGE: "user_block_comment_change_error",
        VALIDATE: "user_validate_error",
        HISTORY_CLEAN: "user_history_clean_error",
        HISTORY_FLUSH: "user_history_flush_error",
    },
    CONFIG: {
        GET_NODE_FIELDS: "configs_node_fields_error",
        GET_GENERAL_NO_ID: "configs_general_no_id_error",
        GET_GENERAL_NO_DATA: "configs_general_no_data_error",
        GET_MODULE_NO_ID: "configs_module_no_id_error",
        GET_MODULE_NO_DATA: "configs_module_no_data_error",
    },
    CITY: {
        NOT_EXISTS: "city_not_exists",
    },
    HTTP: {
        400: "http_bed_request_error",
        401: "http_unauthorized_error",
        403: "http_forbidden_error",
        404: "http_not_found_error",
        429: "http_too_many_requests",
    },
    EXPRESS: {
        FORMIDABLE: "express_formidable_error",
    },
    ADMIN: {
        USERS: {
            GET_LIST: "admin_users_get_list_error",
        },
    },
    FORM: {
        SEND: "form_send_error",
        SAVE: "form_save_error",
        NO_CONFIG: "form_no_config_error",
        NO_DATA: "form_no_data_error",
        NO_STORE_DATA: "form_no_store_data_error",
        NO_REQUIRED: "form_required_error",
        EXTRA_DATA: "form_extra_data_error",
        NO_STORE_CONFIG_DATA: "form_no_store_config_data_error",
        SEND_IMG: "form_send_img_error",
    },
    NOTIFICATIONS: {
        GET_LIST: "notifications_get_list_error",
        NO_DATA: "notifications_no_data",
        FLUSH: "notifications_flush_error",
    },
    HISTORY: {
        FLUSH: "history_flush_error",
    },
    NETWORKING: {
        GET_LIST: "networking_get_list",
        REQUEST: "networking_get_request",
    },
    SHOP: {
        NO_PARTNER: "shop_no_partner_error",
    },
    INSURANCE: {
        GET_PRICES: "insurance_get_prices_error",
        SUBMIT: "insurance_submit_error",
        SUPPORT: "insurance_support_error",
    },
    TELEGRAM: {
        SEND: {
            GENERAL: "telegram_send_error",
            NO_MESSAGE: "telegram_send_no_message_error",
            IMG: "telegram_send_img_error",
        },
    },
    MESSAGE: {
        GET_LINK: {
            LIMITS: "message_get_link_limits_error",
        },
    },
};
