import {hasFullAccess} from 'ultra/helpers/auth';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';
import FileDetails from '../../../Components/FileDetails'
import UploadedFile from '../../../Components/UploadedFile';

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        return <>
            <div className="NotificationTitle">
                <>Видалено </>
                <FileDetails values={values} fullAccess={fullAccess} />
            </div>
            <div className="NodeImage"><UploadedFile city={values._city} file={values.patch} /></div>
        </>
    }

    return <>
        <div className="NotificationTitle">
            <UserHistoryLink onlyText={noFullAccess} id={values?._init_by} name={values?._init_by_name} />
            <> видалив </>
            <FileDetails values={values} fullAccess={fullAccess} />
        </div>
        <div className="NodeImage"><UploadedFile city={values._city} file={values.patch} /></div>
    </>
}
