import {hasFullAccess} from 'ultra/helpers/auth';

import UserHistoryLink from '../../../../UserHistoryLink'
import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';
import NodeDetails from '../../../Components/NodeDetails'

export default function Template(props) {
    const {values} = props;

    const {userState} = useGlobalCustomContext();

    const isDocumentOwner = isYoursAccount(userState, values?.details?.owner);
    const fullAccess = hasFullAccess(userState?.user);
    const noFullAccess = !fullAccess;

    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        return <div className="NotificationTitle">
            {!values?.details?.title && <span>Сторінка відновлена</span>}
            {values?.details?.title && <span>
                <>Ви відновили </>
                <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
            </span>}
        </div>
    } 
    return <div className="NotificationTitle">
        <UserHistoryLink onlyText={noFullAccess} id={values._init_by} name={values._init_by_name}/>
        <> відновив </>
        <NodeDetails values={values} fullAccess={fullAccess} isDocumentOwner={isDocumentOwner} />
    </div>
}
