import React, { useState, useRef, useEffect } from "react";

import Grid from '@mui/material/Grid';

import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import MoneyIcon from '@mui/icons-material/Money';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import MasksIcon from '@mui/icons-material/Masks';
import LuggageIcon from '@mui/icons-material/Luggage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import './index.scss';

export default function ProposalDetails(props) {
    return <div className='ProposalDetails'>
        <Grid container spacing={2}>
            <ProposalDetailsMore {...props} />
        </Grid>
    </div>
}

function ProposalDetailsBase(props) {
    const {pack, company} = props;

    const xs = props.xs || 12;
    const md = props.md || 12;

    return <>
        {pack.stomatology && <Grid item xs={xs} md={md}>
            <span className="labelWrap labelWrapRow">
                <span className="label">Стоматологія:</span>
                <span className='labelValue'>
                    <MasksIcon /> {pack.stomatology}
                </span>
            </span>
        </Grid>}
        {pack.franchise && <Grid item xs={xs} md={md}>
            <span className="labelWrap labelWrapRow">
                <span className="label">Франшиза:</span>
                <span className='labelValue'>
                    <MoneyIcon /> {pack.franchise}
                </span>
            </span>
        </Grid>}
        {pack.baggage && <Grid item xs={xs} md={md}>
            <span className="labelWrap labelWrapRow">
                <span className="label">Багаж:</span>
                <span className='labelValue'>
                    <LuggageIcon /> {pack.baggage}
                </span>
            </span>
        </Grid>}
        <Grid item xs={xs} md={md}>
            <span className="labelWrap labelWrapRow">
                <span className="label labelWrapRow">Байк:</span>
                <span className='labelValue'>
                    <ReportProblemIcon /> покрито
                </span>
            </span>
        </Grid>
        {pack.accident && <Grid item xs={12}>
            <span className="labelWrap labelWrapRow">
                <span className='label inline'>Нещасний випадок:</span>
                <span className='labelValue'>
                    <ReportProblemIcon /> {pack.accident}
                </span>
            </span>
        </Grid>}
    </>
}

function ProposalDetailsMore(props) {
    const {company, pack} = props;

    const xs = props.xs || 6;
    const md = props.md || 6

    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false)
    }

    return <Grid item xs={12}>
        <span className='details moreDetails' onClick={() => {setOpen(true)}}>
            <InfoOutlinedIcon />
            <span className='pseudoLink'>Детальніше про пропозицію</span>
        </span>

        <AdaptiveDialog open={open} onClose={onClose} title={`${company.toUpperCase()}: ${pack.label}`}>
            <div className="ProposalDetailsBase">
                <Grid container spacing={2}>
                    <Grid item xs={xs} md={md}>
                        <div className="labelWrap labelWrapRow">
                            <span className="label">Компанія асистанс:</span>
                            <span className='labelValue'>
                                <ReportProblemIcon />
                                <span>Asistans Co</span>
                            </span>
                        </div>
                    </Grid>
                    <ProposalDetailsBase {...props} />
                </Grid>
            </div>
        </AdaptiveDialog>
    </Grid>
}