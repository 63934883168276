import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {useBackdropPreloaderStore} from '../../Stores/backdropPreloader';
import {useToasterStore} from '../../Stores/toster';

// TODO: remove ?!
export default function PreloaderBackdrop() {    
    const {preloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {toaster, closeToaster} = useToasterStore()

    // to not show preloader circular with notification
    const toasterOpened = Boolean(toaster.snackbarMessage)

    const onBackdropClick = () => {
        if (!toasterOpened) return;

        if (toaster.onCloseHandler) toaster.onCloseHandler();
        closeToaster();

        hideBackdropPreloader();
    }

    return <Backdrop
        onClick={onBackdropClick}
        sx={{
            zIndex: (theme) => theme.zIndex.modal + 1
        }}
        open={preloader}
    >
        {!toasterOpened && <CircularProgress sx={{color: '#fff'}}/>}
    </Backdrop>
}
