import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";

import {isMobile} from "react-device-detect";

import {useGlobalCustomContext} from '../../../../Helpers/context';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';

import FormContainer from '../../../../Components/Form/FormContainer'
import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import {USER_ACTIONS} from '../../Reducers/user';
import {NODE_ACTIONS} from '../../../Content/Reducers/node';

export default function UserAdminActions() {
    const {userState, userDispatch, nodeDispatch} = useGlobalCustomContext();
    const {hideBackdropPreloader} = useBackdropPreloaderStore()

    const [user, setUser] = useState();

    const [formConfig, setFormConfig] = useState();
    const [formContent, setFormContent] = useState();

    function onClose() {
        setUser(undefined);
    }

    useEffect(() => {
        if (userState.blockPopupOpen) {
            const user = userState.blockPopupOpen;
            setUser(user);
            userDispatch({type: USER_ACTIONS.ADMIN.BLOCK_FINISHED})

            setFormConfig({
                fields: {
                    comment: {
                        type: 'textarea',
                        placeholder: 'Коментар'
                    }
                },
                form: {
                    endpoint: `/users/block/${user.id}`,
                    submitText: user?.__blocked_comment ? "Оновити" : "Заблокувати"
                }
            })

            setFormContent({
                comment: user?.__blocked_comment ? user.__blocked_comment : ''
            })
        }
    }, [userState])

    return <>
        <AdaptiveDialog open={Boolean(user?.id)} onClose={onClose} title={user?.__blocked_comment ? "Редагувати коментар" : "Блокувати"}>
            <FormContainer
                content={formContent}
                config={formConfig}
                afterSubmit={() => {
                    nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START});
                    hideBackdropPreloader()
                    onClose()
                }}
            />
        </AdaptiveDialog>
    </>
}
