import { createTheme } from '@mui/material/styles';

const base = {
    backgroundColor: '#fff',
    border: '1px solid #a2a2a2',
    borderRadius: '15px',
}

const disabled = {
    color: '#aaa',
    backgroundColor: '#f9f9f9',
    border: '1px solid #eaeaea',
}

const button = {
    base: {
        backgroundColor: '#303030',
        color: '#fff',
    },
    focused: {
        color: '#fff',
        backgroundColor: '#333333',
    },
    disabled: {
        backgroundColor: '#f0f0f0'
    },
    outlined: {
        backgroundColor: '#ffffff'
    }
}

const label = {
    color: '#111',
    backgroundColor: '#fff',
}

const errors = {
    color: 'rgb(211, 47, 47)',
}

const success = {
    color: 'rgb(63, 180, 87)',
}

const MuiButton = {
    styleOverrides: {
        root: {
        borderRadius: base.borderRadius,
        backgroundColor: button.base.backgroundColor,
        color: button.base.color,
        padding: '16px 1.5rem',
        whiteSpace: 'nowrap',

        '&:disabled': {
            backgroundColor: button.disabled.backgroundColor,
            color: disabled.color,
        },
        '&:hover': {
            color: button.focused.color,
            backgroundColor: button.focused.backgroundColor
        },
        '&:focus': {
            color: button.focused.color,
            backgroundColor: button.focused.backgroundColor
        },
        '&.MuiButton-outlined': {
            border: base.border,
            color: label.color,
            backgroundColor: button.outlined.backgroundColor
        },
        '&.MuiButton-text': {
            border: 0,
            color: label.color,
            background: 'none'
        },
        '&.MuiButton-text:hover': {
            color: label.color,
            background: 'none'
        },
        '&.MuiButton-text:focus': {
            color: label.color,
            background: 'none'
        },
        '&.MuiButton-outlinedError': {
            borderColor: errors.color,
            color: errors.color,
        },
        '&.MuiButton-containedError': {
            backgroundColor: errors.color,
        },
        '&.MuiButton-outlinedSuccess': {
            borderColor: success.color,
            color: success.color,
        },
        '&.MuiButton-containedSuccess': {
            backgroundColor: success.color,
        },
        '&.MuiButton-sizeSmall': {
            padding: '.5rem 1rem',
            minWidth: 'auto',
            borderRadius: '10px',
        },
        '.MuiBadge-badge': {
            marginTop: '-.3rem',
            marginRight: '-.3rem',
        }
      },
    },
}

const MuiToggleButtonGroup = {
    styleOverrides: {
        root: {
            border: base.border,
            backgroundColor: 'transparent',
            borderRadius: '10px',
        }
    }
}

export default createTheme({
    components: {
        MuiPopover: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                }
            }
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    borderRadius: base.borderRadius,
                    backgroundColor: base.backgroundColor,
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '.MuiDrawer-paper': {
                        borderTopLeftRadius: base.borderRadius,
                        borderTopRightRadius: base.borderRadius,
                    }
                }
            }
        },
        // MuiSelect: {
        //     styleOverrides: {
        //         root: {
        //             borderColor: base.borderLight,
        //         }
        //     }
        // },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: base.borderRadius,
                    backgroundColor: base.backgroundColor,
                    border: base.border,
                    '&.Mui-disabled': {
                        backgroundColor: disabled.backgroundColor,
                        border: disabled.border,
                    },
                    '&.Mui-error': {
                        borderColor: errors.color,
                    },
                },
                notchedOutline: {
                    border: 0,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: label.color,
                    '&.Mui-disabled': {
                        color: disabled.color,
                    },
                    '&.MuiInputLabel-shrink': {
                        color: label.color,
                        backgroundColor: label.backgroundColor,
                        paddingLeft: '2px',
                        paddingRight: '2px',
                    },
                    '&.MuiFormLabel-filled': {
                        color: label.color,
                        backgroundColor: label.backgroundColor,
                        paddingLeft: '2px',
                        paddingRight: '2px',
                    },
                    '&.MuiFormLabel-filled.Mui-disabled': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-disabled.Mui-disabled': {
                        color: disabled.color
                    },
                    '&.Mui-focused': {
                        color: label.color,
                        backgroundColor: label.backgroundColor,
                        paddingLeft: '2px',
                        paddingRight: '2px',
                    },
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    color: button.base.backgroundColor,
                },
                deleteIcon: {
                    fill: button.base.backgroundColor
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                    backdropFilter: 'blur(2px)',
                },
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: '#777',
                }
            }
        },
        MuiToggleButtonGroup: MuiToggleButtonGroup,
        MuiButton: MuiButton,
        MuiFab: MuiButton
    }
});
