import {useEffect, useState} from "react";

import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {useNavigate} from "react-router-dom";

import FormContainer from '../../../../Components/Form/FormContainer'

import {getModule} from '../../../../Helpers/module'

import {useToasterStore} from '../../../../Stores/toster';
import {useNavigationStore} from '../../../../Stores/navigation';

import {signIn} from '../../Helpers/user';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import PreloaderForm from '../../../../Components/PreloaderForm';

import {USER_ACTIONS} from '../../Reducers/user';

import './index.scss'

export default function SignUp(props) {
    const {onClose} = props;

    const [profile, setProfile] = useState();

    const {contentLoading} = useNavigationStore()
    const {showError} = useToasterStore();
    const {userDispatch} = useGlobalCustomContext();
    const navigate = useNavigate();

    useEffect(() => {
        getModule('profile').then(setProfile)
    }, [])

    const onAfterSubmit = ({content}) => new Promise(async (resolve, reject) => {
        // auto login

        userDispatch({type: USER_ACTIONS.USER_UPDATE_IN_PROGRESS});
        signIn(content.email, content.password)
            .then((user) => {
                userDispatch({type: USER_ACTIONS.USER_REFRESH_START});

                onClose();
                resolve();
            })
            .catch(errToasterConfig => {
                userDispatch({type: USER_ACTIONS.USER_REMOVE});

                showError({
                    ...errToasterConfig,
                    onCloseHandler: () => {
                        userDispatch({type: USER_ACTIONS.LOGIN_CLOSE});
                    }
                })

                onClose();
                reject();
            })                
    })

    const onGoBack = () => {
        navigate('/')
    }

    if (contentLoading || !profile) return <PreloaderForm />;

    return <div className="profileForm">
        <Typography variant='h4' className="pageTitle">
            <ChevronLeftIcon className="goBack" onClick={onGoBack} />
            <span>Реєстрація</span>
        </Typography>

        {profile.registrationDisabled && <p className="registrationDisabled">Реєстрація нових користувачів тимчасово не доступна</p>}

        {!profile.registrationDisabled && <FormContainer
            captcha={profile.captcha}
            config={profile.registrationForm}
            afterSubmit={onAfterSubmit}
        />}
    </div>
}
